import React, {Fragment} from "react";
import Breadcrumb from '../common/breadcrumb/breadcrumb';
import {
  Container,
} from "reactstrap";
import { connect } from 'react-redux';
import { isEmpty } from "lodash";
import DataTopikGuru from "./component/DataTopikGuru";
import DataTopikGeneral from "./component/DataTopikGeneral";

class RencanaTopikPembelajaran extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user:{}
    }
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Jurnal Akademik" title="Rencana Topik Pembelajaran" />
        <Container fluid={true}>
          {!isEmpty(this.state.user) ? (
            <>
              {this.state.user.group_code === "GU" ? (
                <DataTopikGuru 
                  typePage={"rencana_topik"} 
                  dataUser={this.state.user}
                />
              ) : null} 
              {this.state.user.group_code === "SI" ? (
                <DataTopikGeneral 
                  typePage={"rencana_topik"} 
                  dataUser={this.state.user}
                />
              ) : null} 
              {this.state.user.group_code === "OW" ? (
                <DataTopikGeneral 
                  typePage={"rencana_topik"} 
                  dataUser={this.state.user}
                />
              ) : null} 
              {this.state.user.group_code === "NI" ? (
                <DataTopikGuru 
                  typePage={"rencana_topik"} 
                  dataUser={this.state.user}
                />
              ) : null} 
            </>
          ) : null}
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps, null)(RencanaTopikPembelajaran);