import React from "react";
export const MENUITEMSNI = [
  {
    title: "Referensi",
    icon: <i className="icon-home"></i>,
    path: "/referensi/dashboard",
    type: "sub",
    active: false,
    children: [
      {
        title: "Dashboard",
        type: "link",
        path: "/referensi/dashboard",
        classCss: "sidebar-link dashboard",
      },
      {
        title: "Daftar Siswa",
        type: "link",
        path: "/referensi/daftar-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Guru",
        type: "link",
        path: "/referensi/daftar-guru",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Lainnya",
        type: "link",
        path: "/referensi/daftar-lainnya",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Ruangan dan Gedung",
        type: "link",
        path: "/referensi/daftar-ruangan&gedung",
        classCss: "sidebar-link",
      },
      {
        title: "Tata Tertib",
        type: "link",
        path: "/referensi/tata-tertib",
        classCss: "sidebar-link",
      },
      {
        title: "Poin Tata Tertib",
        type: "link",
        path: "/referensi/poin-tata-tertib",
        classCss: "sidebar-link",
      },
      // { title: 'Poin Tata Tertib', type: 'link', path:  '/referensi/poin-pelanggaran', classCss: "sidebar-link"}
    ],
  },
  {
    title: "Jadwal",
    icon: <i className="icon-calendar"></i>,
    path: "/jadwal/jadwal-siswa",
    type: "sub",
    active: false,
    children: [
      {
        title: "Jadwal Siswa",
        type: "link",
        path: "/jadwal/jadwal-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Jadwal Guru",
        type: "link",
        path: "/jadwal/jadwal-guru",
        classCss: "sidebar-link",
      },
    ],
  },
  {
    title: "Jurnal Akademik",
    icon: <i className="pe-7s-note2"></i>,
    path: "/jurnal-akademik/rencana-topik-pembelajaran",
    type: "sub",
    active: false,
    children: [
      {
        title: "Rencana Topik Pembelajaran",
        type: "link",
        path: "/jurnal-akademik/rencana-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      {
        title: "Realisasi Topik Pembelajaran & Absensi",
        type: "link",
        path: "/jurnal-akademik/realisasi-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      // {
      //   title: "Realisasi Program Pengayaan",
      //   type: "link",
      //   path: "/jurnal-akademik/sample-page",
      //   classCss: "sidebar-link",
      // },
      // {
      //   title: "Perizinan",
      //   type: "link",
      //   path: "/jurnal-akademik/perizinan",
      //   classCss: "sidebar-link",
      // },
    ],
  },
  // {
  //   title: "Ujian dan Remidial",
  //   icon: <i className="pe-7s-tools"></i>,
  //   path: "/ujian-&-remidial/rencana-ujian-bersama",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Rencana Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
  {
    title: "Point Siswa",
    icon: <i className="pe-7s-edit"></i>,
    path: "/point-siswa/rekap-point",
    type: "sub",
    active: false,
    children: [
      {
        title: "Daftar Pelanggaran Siswa",
        type: "link",
        path: "/point-siswa/daftar-pelanggaran-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Rekap Point Siswa",
        type: "link",
        path: "/point-siswa/rekap-point",
        classCss: "sidebar-link",
      },
    ],
  },
  // {
  //   title: "Bimbingan Konseling",
  //   icon: <i className="pe-7s-study"></i>,
  //   path: "/bimbingan-konseling/konsul-siswa",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Konsultasi Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-siswa",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Konsultasi Orang Tua",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-ortu",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Catatan Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/catatan-siswa",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
  // {
  //   title: "RPP",
  //   icon: <i className="icon-write"></i>,
  //   path: "/rpp/program-semester",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Program Semester (Promes)",
  //       type: "link",
  //       path: "/rpp/program-semester",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Pembelajaran",
  //       type: "link",
  //       path: "/rpp/program-pembelajaran",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Program Remidial",
  //       type: "link",
  //       path: "/rpp/program-remedial",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Program Pengayaan",
  //       type: "link",
  //       path: "/rpp/program-pengayaan",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
];

export const MENUITEMSGU = [
  {
    title: "Referensi",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: "/referensi/dashboard",
    type: "sub",
    active: false,
    children: [
      {
        title: "Dashboard",
        type: "link",
        path: "/referensi/dashboard",
        classCss: "sidebar-link dashboard",
      },
      {
        title: "Daftar Siswa",
        type: "link",
        path: "/referensi/daftar-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Guru",
        type: "link",
        path: "/referensi/daftar-guru",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Lainnya",
        type: "link",
        path: "/referensi/daftar-lainnya",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Ruangan dan Gedung",
        type: "link",
        path: "/referensi/daftar-ruangan&gedung",
        classCss: "sidebar-link",
      },
      {
        title: "Tata Tertib",
        type: "link",
        path: "/referensi/tata-tertib",
        classCss: "sidebar-link",
      },
      {
        title: "Poin Tata Tertib",
        type: "link",
        path: "/referensi/poin-tata-tertib",
        classCss: "sidebar-link",
      },
    ],
  },
  {
    title: "Jadwal",
    icon: <i className="icon-calendar"></i>,
    path: "/jadwal/jadwal-mengajar",
    type: "sub",
    active: false,
    children: [
      {
        title: "Jadwal Mengajar",
        type: "link",
        path: "/jadwal/jadwal-mengajar",
        classCss: "sidebar-link",
      },
    ],
  },
  {
    title: "Jurnal Akademik",
    icon: <i className="pe-7s-note2"></i>,
    path: "/jurnal-akademik/rencana-topik-pembelajaran",
    type: "sub",
    active: false,
    children: [
      {
        title: "Rencana Topik Pembelajaran",
        type: "link",
        path: "/jurnal-akademik/rencana-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      {
        title: "Realisasi Topik Pembelajaran & Absensi",
        type: "link",
        path: "/jurnal-akademik/realisasi-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      // {
      //   title: "Realisasi Program Pengayaan",
      //   type: "link",
      //   path: "/jurnal-akademik/sample-page",
      //   classCss: "sidebar-link",
      // },
      // {
      //   title: "Perizinan",
      //   type: "link",
      //   path: "/jurnal-akademik/perizinan",
      //   classCss: "sidebar-link",
      // },
    ],
  },
  // {
  //   title: "Ujian dan Remidial",
  //   icon: <i className="pe-7s-tools"></i>,
  //   path: "/ujian-&-remidial/rencana-ujian-bersama",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Rencana Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
  {
    title: "Point Siswa",
    icon: <i className="pe-7s-edit"></i>,
    path: "/point-siswa/rekap-point",
    type: "sub",
    active: false,
    children: [
      {
        title: "Daftar Pelanggaran Siswa",
        type: "link",
        path: "/point-siswa/daftar-pelanggaran-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Rekap Point Siswa",
        type: "link",
        path: "/point-siswa/rekap-point",
        classCss: "sidebar-link",
      },
    ],
  },
  // {
  //   title: "Bimbingan Konseling",
  //   icon: <i className="pe-7s-study"></i>,
  //   path: "/bimbingan-konseling/konsul-siswa",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Konsultasi Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-siswa",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Konsultasi Orang Tua",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-ortu",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Catatan Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/catatan-siswa",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
  // {
  //   title: "RPP",
  //   icon: <i className="icon-write"></i>,
  //   path: "/rpp/program-semester",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Program Semester (Promes)",
  //       type: "link",
  //       path: "/rpp/program-semester",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Pembelajaran",
  //       type: "link",
  //       path: "/rpp/program-pembelajaran",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Program Remidial",
  //       type: "link",
  //       path: "/rpp/program-remedial",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Program Pengayaan",
  //       type: "link",
  //       path: "/rpp/program-pengayaan",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
];

export const MENUITEMSSI = [
  {
    title: "Referensi",
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: "/referensi/dashboard",
    type: "sub",
    active: false,
    children: [
      {
        title: "Dashboard",
        type: "link",
        path: "/referensi/dashboard",
        classCss: "sidebar-link dashboard",
      },
      {
        title: "Daftar Siswa",
        type: "link",
        path: "/referensi/daftar-siswa",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Guru",
        type: "link",
        path: "/referensi/daftar-guru",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Lainnya",
        type: "link",
        path: "/referensi/daftar-lainnya",
        classCss: "sidebar-link",
      },
      {
        title: "Daftar Ruangan dan Gedung",
        type: "link",
        path: "/referensi/daftar-ruangan&gedung",
        classCss: "sidebar-link",
      },
      {
        title: "Tata Tertib",
        type: "link",
        path: "/referensi/tata-tertib",
        classCss: "sidebar-link",
      },
      {
        title: "Poin Tata Tertib",
        type: "link",
        path: "/referensi/poin-tata-tertib",
        classCss: "sidebar-link",
      },
    ],
  },
  {
    title: "Jadwal",
    icon: <i className="icon-calendar"></i>,
    path: "/jadwal/jadwal-siswa",
    type: "sub",
    active: false,
    children: [
      {
        title: "Jadwal Pelajaran Siswa",
        type: "link",
        path: "/jadwal/jadwal-siswa",
        classCss: "sidebar-link",
      },
    ],
  },
  {
    title: "Jurnal Akademik",
    icon: <i className="pe-7s-note2"></i>,
    path: "/jurnal-akademik/rencana-topik-pembelajaran",
    type: "sub",
    active: false,
    children: [
      {
        title: "Rencana Topik Pembelajaran",
        type: "link",
        path: "/jurnal-akademik/rencana-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      {
        title: "Realisasi Topik Pembelajaran & Absensi",
        type: "link",
        path: "/jurnal-akademik/realisasi-topik-pembelajaran",
        classCss: "sidebar-link",
      },
      // {
      //   title: "Realisasi Program Pengayaan",
      //   type: "link",
      //   path: "/jurnal-akademik/sample-page",
      //   classCss: "sidebar-link",
      // },
      // {
      //   title: "Perizinan",
      //   type: "link",
      //   path: "/jurnal-akademik/perizinan",
      //   classCss: "sidebar-link",
      // },
    ],
  },
  // {
  //   title: "Ujian dan Remidial",
  //   icon: <i className="pe-7s-tools"></i>,
  //   path: "/ujian-&-remidial/rencana-ujian-bersama",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Rencana Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ujian Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ujian-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Remidial Bersama",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-remidial-bersama",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Rencana Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/rencana-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Hasil Ulangan Harian",
  //       type: "link",
  //       path: "/ujian-&-remidial/hasil-ulangan-harian",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
  {
    title: "Point Siswa",
    icon: <i className="pe-7s-edit"></i>,
    path: "/point-siswa/rekap-point",
    type: "sub",
    active: false,
    children: [
      {
        title: "Rekap Point Siswa",
        type: "link",
        path: "/point-siswa/rekap-point",
        classCss: "sidebar-link",
      },
    ],
  },
  // {
  //   title: "Bimbingan Konseling",
  //   icon: <i className="pe-7s-study"></i>,
  //   path: "/bimbingan-konseling/konsul-siswa",
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       title: "Konsultasi Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-siswa",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Konsultasi Orang Tua",
  //       type: "link",
  //       path: "/bimbingan-konseling/konsul-ortu",
  //       classCss: "sidebar-link",
  //     },
  //     {
  //       title: "Catatan Siswa",
  //       type: "link",
  //       path: "/bimbingan-konseling/catatan-siswa",
  //       classCss: "sidebar-link",
  //     },
  //   ],
  // },
];
