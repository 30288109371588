import React, { Fragment } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  Row, 
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroupItem,
  ListGroup,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button
} from 'reactstrap';
import ProfileMini from './ProfileMini';
import { Briefcase } from "react-feather";

const apiUm = process.env.REACT_APP_UM_API;

class DataAkademikGuru extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '1',
      tahunSekarang: new Date().getFullYear(),
      dataAkademik : [],
      detailModal : false,
    }
    this.toggle = this.toggle.bind(this);
    this.getAkademik = this.getAkademik.bind(this);
    this.showAkademik = this.showAkademik.bind(this);
    this.detailModal = this.detailModal.bind(this);
  }

  componentDidMount() {
    this.setState({ uuid : this.props.uuid }, function(){
      this.getAkademik();
    })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  getAkademik() {
    let self = this;
    let requestData = '';

    requestData += '?uuid='+self.props.dataProfile.uuid;

    axios.get(apiUm + 'ja/penugasan-gu-detail' + requestData)
      .then(function (res) {
        self.setState({ dataAkademik : res.data });
      })
  }

  detailModal(status) {
    if (status) {
      this.setState({ detailModal : true })
    } else {
      this.setState({ detailModal : false })
    }
  }
  
  showAkademik() {
    let self = this;

    if(!isEmpty(this.state.dataAkademik)) {
      return (
        <Row>
          <Col sm="12" md="6" lg="6" xl="6" className="text-left">
            <h6 className="ml-4"><b>Status Siswa</b></h6>
            <ListGroup className="profile-dashboard">
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Nama Penugasan
                <span className="badge badge-primary counter digits">
                  {this.state.dataAkademik.nama_penugasan}
                </span>
              </ListGroupItem>
              {!isEmpty(this.state.dataAkademik.rombel) ? (
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  Wali Kelas Rombel
                  <span className="badge badge-primary counter digits">
                    {this.state.dataAkademik.rombel}
                  </span>
                </ListGroupItem>
              ) : null}
            </ListGroup>
            <button className="btn btn-pill btn-secondary ml-2 mt-3" onClick={this.detailModal.bind(this, true)}>
              <i className="icofont icofont-book text-white i mr-2" />
              Mata Pelajaran
            </button>
          </Col>
          <Col sm="12" md="6" lg="6" xl="6" className="pr-4">
            <Card className="gradient-info o-hidden mt-4">
              <CardBody className="b-r-4 p-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <Briefcase className="text-white i ml-2" />
                  </div>
                  <div className="media-body">
                    <div className="text-left">
                      <span className="m-0 text-white">Tahun Ajaran</span>
                      <h4 className="mb-0 counter text-white">{this.state.dataAkademik.tahun_ajaran}</h4>
                    </div>
                    <Briefcase className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  render() {
    return (
      <Card className="border b-r-4">
        <CardHeader className="text-left p-3 px-2">
          <h5>Data Akademik</h5>
        </CardHeader>
        <CardBody className="p-0">
          <div className="text-center p-3 col-12 justify-center">
            <Nav className="nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "1" ? "active" : ""}
                  onClick={() => this.toggle("1")}
                >
                  <i className="icofont icofont-building-alt"></i> Penugasan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => this.toggle("2")}
                >
                  <i className="icofont icofont-business-man"></i> Data Institusi
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {this.showAkademik()}
            </TabPane>
            <TabPane tabId="2">
              <ProfileMini 
                npsn={this.props.dataProfile.npsn}
                groupCode="NI"
              />
            </TabPane>
          </TabContent>
        </CardBody>

        <Modal isOpen={this.state.detailModal} toggle={this.detailModal.bind(this, false)}>
          <ModalHeader toggle={this.detailModal.bind(this, false)}>
            Mata Pelajaran
          </ModalHeader>
          <ModalBody>
            {!isEmpty(this.state.dataAkademik?.mapel) ? (
              <ListGroup className="profile-dashboard">
                {this.state.dataAkademik.mapel.map((value, key) => (
                  <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                    {value.indeks_mpk + " - " + value.mp}
                    <span className="badge badge-primary counter digits">
                      {value.mpk}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : ("")}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.detailModal.bind(this, false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    )
  }
}

export default DataAkademikGuru;