import { isEmpty } from "lodash";
import React from "react";
import axios from "axios";
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom'
import { login_sso, caught_sso, status_login, redirect_sso, logout_sso } from "../services/sso";
import { dontCry } from "../utils/KiritoHelper";
import Home from "../component/Index";
import * as login from "../redux/actions/SSOActions"
import * as setUser from "../redux/actions/UserActions"
import Authloader from "../component/common/loader/authloader";
import PrivacyPolicy from "../component/privacyPolicy/Privacy";

const sso = `${process.env.REACT_APP_SSO_URL}`;
const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
// const clientSecret = `${process.env.REACT_APP_CLIENT_SECRET}`;
// const caughtUri = `${process.env.REACT_APP_SSO_CAUGHT_URI}`;
const redirectUri = `${process.env.REACT_APP_SSO_REDIRECT_URI}`;
const api = `${process.env.REACT_APP_API}`;

function has_redirect(str) {
  if (!isEmpty(localStorage.getItem('redirect')))
    return true;
  return false;
}

class AuthRoute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect_status: null,
      userStatus: false,
      isSso: true,
      authStatus: true,
    }

    this.redirectSSO = this.redirectSSO.bind(this);
    this.caughtSSO = this.caughtSSO.bind(this);
    this.logoutSSO = this.logoutSSO.bind(this);
    this.callbackSSO = this.callbackSSO.bind(this);
  }
  
  componentDidMount(){
    // screen.className += "display-none";
  }

  componentWillUnmount(){
    console.log("unmount")
    // screen.className -= "display-none";
  }
  loginSSO() {
    const urlParams = new URLSearchParams(window.location.search);
    const paramRedirect = urlParams.get('redirect');
    if (!isEmpty(paramRedirect))
      localStorage.setItem('redirect', paramRedirect);

    login_sso();

    return (
      <Authloader/>
    )
  }

  redirectSSO() {
    var self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const paramCode = urlParams.get('code');

    if (paramCode == null) {
      return <Redirect to="/" />;
    }

    if (self.state.redirect_status == null) {
      redirect_sso().then((res) => {
        if (isEmpty(res)) {
          window.location.replace('/');
        } else {
          //set jwtToken
          localStorage.setItem('jwtToken', res.data.access_token);
  
          this.loginDispatch()
  
          status_login().then((res) => {
            self.setState({ redirect_status: res.success, userStatus: true });
          }).catch((err) => {
            // console.log('woi', err);
          });
        }
      });
    }

    if (self.state.redirect_status) {
      // const data = this.props.auth.user;
      // this.props.getUserDetail(data);
      this.loginDispatch()

      let redirectTo = localStorage.getItem('redirect');
      return !has_redirect() ? <Redirect to="/referensi/dashboard" /> : <Redirect to={`${redirectTo}`} />;

    }

    return (
      <Authloader/>
    )
  }

  caughtSSO() {
    var self = this;
    let obj = caught_sso();
    
    if (!obj.hasOwnProperty("message")) {//pass data user to backend
      //reencrypt
      let data = dontCry(JSON.stringify(obj, 'e'));

      axios.post(api + "auth/sso/login", { type: "caught", code: data })
        .then((response) => {
          var res = response.data;
          //set jwtToken
          localStorage.setItem('jwtToken', res.data.access_token);

          this.loginDispatch()

          if (self.state.redirect_status == null) {
            status_login().then((res) => {
              self.setState({ redirect_status: res.success });
            })
            .catch(error => {
              if (error.response?.status == 401) {
                let config = {};
                let jwtToken = localStorage.getItem('jwtToken')
                if (jwtToken != undefined) {
                  config = {
                    headers: { Authorization: "Bearer " + jwtToken },
                  };
                }
                axios.post(
                  api + "auth/sso/get-new-token",
                  {}, config
                  // config
                )
                .then((response) => {
                  var response = response.data;
                  localStorage.setItem('jwtToken', response.data.value);

                  self.setState({ redirect_status: response.success });
                });
              }
            })
          }
        })
        .catch((error) => {
          console.error("error", error);
        });

      if (this.state.redirect_status) {
        // const data = this.props.auth.user;
        // this.props.getUserDetail(data);

        this.loginDispatch()
        let redirectTo = localStorage.getItem('redirect');
        this.userDispatch(localStorage.getItem('jwtToken'));
        return !has_redirect() ? <Redirect to="/referensi/dashboard" /> : <Redirect to={`${redirectTo}`} />;
      }
    } else {
      let url = sso + "/auth?client_id=" + clientId + "&redirect_uri=" + redirectUri;
      window.location.replace(url);
    }

    return (
      <Authloader/>
    )
  }

  logoutSSO() {
    var self = this;
    let jwtToken = localStorage.getItem('jwtToken');
    logout_sso(jwtToken).then((res) => {

      window.location.replace(
        sso + "auth/logout?token=" + res.data.value + "&redirect_uri=" + redirectUri
      );
      localStorage.removeItem('jwtToken');
      if (!isEmpty(localStorage.getItem('redirect')))
        localStorage.removeItem('redirect');

    }).catch((err) => {
      // if (err.response.statusText === 'Unauthorized') {
      self.setState({ authStatus: false });
      // }
    });

    if (!self.state.authStatus)
      return <Redirect to="/" />;
    return (
      <Authloader/>
    )
  }

  loginDispatch() {
    let { dispatch } = this.props;
    let action = login.login()

    dispatch(action)
  }

  privacyRender() {
    return <PrivacyPolicy/>
  }

  userDispatch(token) {
    let { dispatch } = this.props;
    let action = setUser.getUser(token)

    dispatch(action)
  }
  
  callbackSSO() {
    var self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get('token');
    localStorage.setItem('tokenCallback', param);
    axios.post(api + "auth/sso/login", { type: "caught", code: localStorage.getItem('tokenCallback')})
      .then((response) => {
        var res = response.data;
        //set jwtToken
        localStorage.setItem('jwtToken', res.data.access_token);
        self.props.login(self.state);
        if (self.state.redirect_status == null) {
          status_login().then((res) => {
            self.setState({ redirect_status: res.success });
          }).catch((err) => {
            // if (err.response.statusText === 'Unauthorized') {
              self.setState({ authStatus: false });
            // }
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
      });

    if (!self.state.authStatus)
      return <Redirect to="/" />;

    if (self.state.authStatus && self.state.redirect_status) {
      localStorage.removeItem('tokenCallback');
      const data = this.props.auth.user;
      this.props.getUserDetail(data);
      return <Redirect to="/profile/settings" />;
    }
    return (
      <Authloader />
    )
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" render={() => (<Home />)} />
        <Route exact path="/auth" render={this.loginSSO} />
        <Route exact path="/auth/sso/caught" render={this.caughtSSO} />
        <Route exact path="/auth/sso/redirect" render={this.redirectSSO} />
        <Route exact path="/auth/sso/logout" render={this.logoutSSO} />
        <Route exact path="/auth/sso/callback" render={this.callbackSSO} />
        <Route exact path="/privasi" render={this.privacyRender} />
      </Switch>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    SSOReducer: state.SSOReducer
  }
}

export default connect(mapStateToProps)(AuthRoute);