import React from "react";
import LoaderPeraturan from "../../common/loader/LoaderPeraturan";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  ModalFooter,
  TabPane,
} from "reactstrap";
import { isEmpty } from "lodash";
import axios from "axios";
import FormTataTertib from "./form/FormTataTertib";
import DataPoinTataTertib from "./DataPoinTataTertib";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import Swal from "sweetalert2";
import { Button } from "reactstrap";

const apiJa = process.env.REACT_APP_API;

export default class DataTataTertib extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      tataTertib: null,
      poinPelanggaran: [],
      poinPrestasi: [],
      loadingPoinPelanggaran: true,
      loadingPoinPrestasi: true,
      loadingTataTertib: true,
      modalTataTertib: false,
      modalPoin: false,
      datapoinawalsiswa: null,
      user: {},
      modalAlpha: false,
      poinTatatertib: [],
      idpointatatertib: "",
      defaultAlpha: null,
      modalImport: false,
      referensiTataTertib: [],
    };

    this.getTataTertib = this.getTataTertib.bind(this);
    this.createTataTertib = this.createTataTertib.bind(this);
  }

  modalImportShow = () => {
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .get(apiJa + `tata-tertib/get-referensi-tata-tertib`, {}, config)
      .then(function (res) {
        if (res.data.data == null) {
          self.setState({
            referensiTataTertib: "Tidak ada referensi",
            modalImport: true,
          });
        } else {
          self.setState({
            referensiTataTertib: res.data.data.item.keterangan,
            modalImport: true,
          });
        }
      });
  };
  addFormReferensi = () => {
    Swal.fire({
      title: "Apakah Anda Yakin?",
      text: "Referensi akan ditambahkan ke Tata Tertib Sekolah!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Iya, Lanjutkan!",
    }).then((result) => {
      if (result.isConfirmed) {
        let self = this;
        let jwtToken = localStorage.getItem("jwtToken");
        let config = {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        };

        axios
          .get(apiJa + `tata-tertib/add-from-referensi`, {}, config)
          .then(function (res) {
            self.setState({
              modalImport: false,
            });
            self.getTataTertib();
            Swal.fire(
              "Berhasil!",
              "Referensi Berhasil Ditambahkan.",
              "success"
            );
          });
      }
    });
  };
  closeModal = () => {
    this.setState({
      modalImport: false,
    });
  };
  componentDidMount() {
    this.getTataTertib();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getTataTertib();
  }

  createTataTertib(show) {
    this.setState({ modalTataTertib: show }, function () {
      if (show === false) {
        this.getTataTertib();
      }
    });
  }

  getTataTertib() {
    let self = this;
    self.setState({ loadingTataTertib: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .get(apiJa + `tata-tertib/get-tata-tertib`, {}, config)
      .then(function (res) {
        if (res.data.code === 107) {
          Swal.fire({
            icon: "info",
            title: "Info",
            text: res.data.message,
          });
          self.setState({
            loadingTataTertib: false,
          });
        } else {
          if (!isEmpty(res.data.data)) {
            self.setState({
              tataTertib: res.data.data.item.keterangan,
              loadingTataTertib: false,
            });
          } else {
            self.setState({
              loadingTataTertib: false,
            });
          }
        }
      });
  }

  render() {
    console.log(this.state.referensiTataTertib);
    return (
      <Col sm="12">
        <Card>
          <CardBody className="px-2 py-4">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row className="p-3">
                  <Col sm="12" md="6" lg="6" xl="6">
                    <h6>
                      <b>Isi Tata Tertib : </b>
                    </h6>
                  </Col>
                  <Col sm="12" md="2" lg="6" xl="6">
                    {this.props.dataUser.group_code === "NI" ? (
                      <>
                        <button
                          className="btn btn-primary btn-pill pull-right"
                          onClick={this.createTataTertib.bind(this, true, null)}
                        >
                          <i className="icofont icofont-plus mr-2"></i>Tambah /
                          Edit Tata Tertib
                        </button>
                        {isEmpty(this.state.tataTertib) ? (
                          <>
                            <button
                              className="btn btn-primary btn-pill mr-2 pull-right"
                              onClick={this.modalImportShow}
                            >
                              <i className="icofont icofont-eye mr-2"></i>Lihat
                              Referensi
                            </button>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </Col>
                  <Col sm="12" md="6" lg="6" xl="6"></Col>
                  <Col sm="12" md="12" lg="12" xl="12">
                    <hr />
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html:
                          this.state.tataTertib ?? "Belum ada Tata tertib",
                      }}
                    ></div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>

        {/* modal create peraturan */}
        <Modal
          size="lg"
          isOpen={this.state.modalTataTertib}
          toggle={this.createTataTertib.bind(this, false, null)}
        >
          <ModalHeader toggle={this.createTataTertib.bind(this, false, null)}>
            Peraturan
          </ModalHeader>
          <ModalBody>
            {this.state.loadingTataTertib === false ? (
              <FormTataTertib
                tataTertib={this.state.tataTertib}
                closeModal={this.createTataTertib}
              />
            ) : (
              "loading"
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalImport}
          toggle={this.closeModal}
          className="modal-lg modal-dialog-scrollable"
        >
          <ModalHeader toggle={this.closeModal}>
            Referensi Tata Tertib
          </ModalHeader>
          <ModalBody>
            {!isEmpty(this.state.referensiTataTertib) ? (
              <>
                {" "}
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.state.referensiTataTertib ?? "Belum ada Referensi",
                  }}
                ></div>
              </>
          ) : (
              "null"
            )}
          </ModalBody>
          <ModalFooter>
            {!isEmpty(this.state.referensiTataTertib) ? (
              <>
                <Button color="primary" onClick={this.addFormReferensi}>
                  {" "}
                  <i className="icofont icofont-plus mr-2"></i> Tambah Ke
                  Tatatertib Sekolah
                </Button>
              </>
            ) : (
              <Button color="dark">
                {" "}
                <i className="icofont icofont-close mr-2"></i> Tidak ada
                Referensi
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </Col>
    );
  }
}
