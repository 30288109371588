import {combineReducers} from 'redux'
import Customizer from './customizer/reducer'
import SSOReducer from "./reducers/SSOReducer";
import UserReducer from "./reducers/UserReducer"
const reducers = combineReducers({
    Customizer,
    SSOReducer,
    UserReducer
});

export default reducers;