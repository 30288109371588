import React, { Fragment } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  Row, 
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroupItem,
  ListGroup
} from 'reactstrap';
import ProfileMini from './ProfileMini';
import { UserPlus } from "react-feather";

const apiUm = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class DataAkademikOrtu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '1',
      tahunSekarang: new Date().getFullYear(),
      dataAkademik: [],
    }
    this.toggle = this.toggle.bind(this);
    this.getAkademik = this.getAkademik.bind(this);
    this.showAkademik = this.showAkademik.bind(this);
  }

  componentDidMount() {
    this.setState({
      uuid : this.props.dataProfile.uuid
    }, function() {
      this.getAkademik();
    })
  }
  
  getAkademik() {
    let self = this;
    let requestData = '';

    if (!isEmpty(self.props.dataProfile.user_orangtua.anak)) {
      requestData += '?uuid='+self.props.dataProfile.user_orangtua.anak.user.uuid;
  
      axios.get(apiUm + 'ja/siswa-rombel' + requestData)
        .then(function (res) {
          self.setState({ dataAkademik : res.data });
        })
    }
  }

  showAkademik() {
    let self = this;

    if(!isEmpty(this.state.dataAkademik)) {
      return (
        <Row>
          <Col sm="12" md="6" lg="6" xl="6" className="text-left">
            <h6 className="ml-4"><b>Status Siswa</b></h6>
            <ListGroup className="profile-dashboard">
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Tahun Ajaran
                <span className="badge badge-primary counter digits">
                  {this.state.dataAkademik.tahun_ajaran}
                </span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Kelas
                <span className="badge badge-primary counter digits">
                  {this.state.dataAkademik.kelas}
                </span>
              </ListGroupItem>
              <ListGroupItem className="d-flex justify-content-between align-items-center">
                Peminatan
                <span className="badge badge-primary counter digits">
                  {this.state.dataAkademik.nama_spektrum}
                </span>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col sm="12" md="6" lg="6" xl="6" className="pr-4">
            <Card className="gradient-info o-hidden mt-4">
              <CardBody className="b-r-4 p-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <UserPlus className="text-white i ml-2" />
                  </div>
                  <div className="media-body">
                    <div className="text-left">
                      <span className="m-0 text-white">Tahun Masuk</span>
                      <h4 className="mb-0 counter text-white">{this.state.dataAkademik.tahun_masuk}</h4>
                    </div>
                    <UserPlus className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  render() {
    return (
      <Card className="border b-r-4">
        <CardHeader className="text-left p-3 px-2">
          <h5>Data Akademik</h5>
        </CardHeader>
        <CardBody className="p-0">
          <div className="text-center p-3 col-12 justify-center">
            <Nav className="nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "1" ? "active" : ""}
                  onClick={() => this.toggle("1")}
                >
                  <i className="icofont icofont-book-alt"></i> Akademik Siswa
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => this.toggle("2")}
                >
                  <i className="icofont icofont-business-man"></i> Data Institusi
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "3" ? "active" : ""}
                  onClick={() => this.toggle("3")}
                >
                  <i className="icofont icofont-student-alt"></i> Data Siswa
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              {this.showAkademik()}
            </TabPane>
            <TabPane tabId="2">
              <ProfileMini 
                npsn={this.props.dataProfile.npsn}
                groupCode="NI"
              />
            </TabPane>
            <TabPane tabId="3">
              {!isEmpty(this.props.dataProfile.user_orangtua.anak) ? (
                <ProfileMini 
                  uuid={this.props.dataProfile.user_orangtua.anak.user.uuid}
                  groupCode="SI"
                />
              ) : ("Siswa Belum aktif")}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    )
  }
}

export default DataAkademikOrtu;