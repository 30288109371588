import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col } from "reactstrap";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import DataStudent from "./component/DataStudent";

class ListStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }
  componentDidMount() {}

  static getDerivedStateFromProps(props, state) {
    if (props.UserReducer.user !== state.user) {
      return {
        user: props.UserReducer.user,
      };
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Referensi" title="Daftar Siswa" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {!isEmpty(this.state.user) ? (
                <DataStudent dataUser={this.state.user} />
              ) : null}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer,
  };
};

export default connect(mapStateToProps, null)(ListStudent);
