import React from "react";
import Select from "react-select";
import axios from "axios";
import { isEmpty } from "lodash";
import Skeleton from "react-loading-skeleton";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: (provided) => ({ ...provided, zIndex: "999999999" }),
};

const apiUm = process.env.REACT_APP_UM_API;

export default class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tahunSekarang: new Date().getFullYear(),
      data: null,
      diffOption: false,

      loading: false,
      type: null,
    };
    this.selectData = this.selectData.bind(this);
  }

  componentDidMount() {
    const loading = this.state.loading;
    const type = this.state.type;
    this.setState(
      {
        loading: this.props.loading,
        type: this.props.type,
      },
      function () {
        if (loading !== this.state.loading || type !== this.state.type)
          this.getDataFilter(this.state.loading, this.state.type);
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const loading = this.state.loading;
    const type = this.state.type;
    this.setState(
      {
        loading: nextProps.loading,
        type: nextProps.type,
      },
      function () {
        if (loading !== this.state.loading || type !== this.state.type)
          this.getDataFilter(this.state.loading, this.state.type);
      }
    );
  }
  // static getDerivedStateFromProps(props, state){
  //   if(props.loading !== state.type){
  //     return({
  //       loading: props.loading,
  //       type: props.type,
  //     })
  //   }
  //   return null
  // }
  // componentDidUpdate(props, state){
  //   if(props.loading !== state.loading){
  //     this.getDataFilter()
  //   }
  // }

  getDataFilter(loading, type) {
    if (loading === false) {
      switch (type) {
        case "tahunAjaran":
          this.getTahunAjaran();
          break;
        case "kelas":
          this.getKelas();
          break;
        case "peminatan":
          this.getPeminatan();
          break;
        case "rombel":
          this.getRombel();
          break;
        case "semester":
          this.getSemester();
          break;
        case "guru":
          this.getGuru();
          break;
        case "bidang":
          this.getBidang();
          break;
        case "program":
          this.getProgram();
          break;
        case "kompetensi":
          this.getKompetensi();
          break;
        case "penugasan":
          this.getPenugasan();
          break;
        case "namaUjian":
          this.getNamaUjian();
          break;
        case "mapelJenjang":
          this.getMapelJenjang();
          break;
        case "mapelRombel":
          this.getMapelRombel();
          break;
        default:
          // code block
          break;
      }
    }
  }

  getTahunAjaran() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ id: u + " / " + i, text: u + " / " + i });
    }
    this.setState({ data: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.props.jenjang === "2") {
      kelas = [
        { id: "I", text: "I" },
        { id: "II", text: "II" },
        { id: "III", text: "III" },
        { id: "IV", text: "IV" },
        { id: "V", text: "V" },
        { id: "VI", text: "VI" },
      ];
    } else if (this.props.jenjang === "3") {
      kelas = [
        { id: "VII", text: "VII" },
        { id: "VIII", text: "VIII" },
        { id: "IX", text: "IX" },
      ];
    } else if (this.props.jenjang === "4") {
      kelas = [
        { id: "X", text: "X" },
        { id: "XI", text: "XI" },
        { id: "XII", text: "XII" },
      ];
    } else if (this.props.jenjang === "5") {
      kelas = [
        { id: "X", text: "X" },
        { id: "XI", text: "XI" },
        { id: "XII", text: "XII" },
        { id: "XIII", text: "XIII" },
      ];
    }
    this.setState({ data: kelas });
  }

  getNamaUjian() {
    var ujian = [];
    ujian = [
      { id: "0", text: "UAS" },
      { id: "1", text: "UTS" },
    ];

    this.setState({ data: ujian });
  }

  getPeminatan() {
    let self = this;
    let requestData = "";
    requestData += "?m_struktur_jenjang_id=" + this.props.jenjang;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  getRombel() {
    if (!isEmpty(this.props.requestData)) {
      let self = this;
      var tahunAjaran = this.props.requestData.tahunAjaran;
      var kelas = this.props.requestData.kelas;
      var spektrum = this.props.requestData.spektrum;
      var npsn = this.props.requestData.npsn;

      if (
        !isEmpty(tahunAjaran) &&
        !isEmpty(kelas) &&
        !isEmpty(String(spektrum)) &&
        !isEmpty(String(npsn))
      ) {
        // let jwtToken = localStorage.getItem("jwtToken")
        let requestData = "";
        requestData += "?tahun_ajaran=" + tahunAjaran;
        requestData += "&kelas=" + kelas;
        requestData += "&spektrum=" + spektrum;
        requestData += "&npsn=" + npsn;

        // let config = {
        //   headers: {
        //     // Authorization: "Bearer " + jwtToken,
        //   },
        // }
        axios.get(apiUm + "referensi/rombel" + requestData).then((res) => {
          self.setState({ data: res.data });
        });
      }
    }
  }

  getSemester() {
    var semester = [];
    semester = [
      { id: "1", text: "1" },
      { id: "2", text: "2" },
    ];
    this.setState({ data: semester });
  }

  getPenugasan() {
    let self = this;

    axios
      .get(
        apiUm +
          "ja/select-penugasan?type=" +
          self.props.requestData.typePenugasan
      )
      .then((res) => {
        self.setState({
          data: res.data,
        });
      });
  }

  getGuru() {
    let self = this;
    let requestData = "";
    requestData += "?npsn=" + self.props.requestData.npsn;
    requestData += "&tahun_ajaran=" + self.props.requestData.tahunAjaran;

    axios.get(apiUm + "ja/select-guru" + requestData).then((res) => {
      self.setState({
        data: res.data,
      });
    });
  }

  getMapelJenjang() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang=" + self.props.jenjang;

    axios.get(apiUm + "ja/select-mapel-jenjang" + requestData).then((res) => {
      self.setState({
        data: res.data,
      });
    });
  }

  getMapelRombel() {
    let self = this;
    let requestData = "";
    requestData += "?rombel=" + self.props.requestData.rombel;
    requestData += "&semester=" + self.props.requestData.semester;

    axios.get(apiUm + "ja/select-mapel-rombel" + requestData).then((res) => {
      self.setState({
        data: res.data,
      });
    });
  }

  getBidang() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=0";
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  getProgram() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=" + this.props.requestData.bidang;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  getKompetensi() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=" + this.props.requestData.kompetensi;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  selectData(e) {
    if (e) {
      this.props.selectData(String(e.id));
    } else {
      this.props.selectData(null);
    }
  }

  render() {
    return this.props.loading === false ? (
      <>
        {!isEmpty(this.state.data) ? (
          <Select
            placeholder={
              "Pilih " + this.props.type.split(/(?=[A-Z])/).join(" ")
            }
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.id}
            options={this.state.data}
            onChange={this.selectData}
            isClearable
            styles={colourStyles}
            defaultValue={this.props.defaultSelect}
          />
        ) : (
          <p>Tidak ada data {this.props.type.split(/(?=[A-Z])/).join(" ")}</p>
        )}
      </>
    ) : (
      <Skeleton height={35} />
    );
  }
}
