import axios from "axios";
import { isEmpty } from "lodash";
import { GET_USER } from "../actionType";
import { update_token } from "./../../services/sso";

const api = process.env.REACT_APP_API

// export function get user(data) {
export const getUser = (data) => {
  let jwt = localStorage.getItem("jwtToken");
  let header = {
    headers: { Authorization: "Bearer " + jwt },
  };
  
  return (dispatch) => {
    return axios.post(api + "auth/sso/user", {}, header).then(res => {
      if (!isEmpty(res?.data.data.item))
        dispatch(setCurrentUser(res?.data.data.item));
      else
        return "error";
    })
  }
};

export function setCurrentUser(data) {
  return {
    type: GET_USER,
    payload: data
  };
}