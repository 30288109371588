import React from "react";
import Select from "react-select";
import axios from "axios";
import { isEmpty } from "lodash";
import Skeleton from "react-loading-skeleton";
import { Row, Col, Card, CardHeader, CardBody, Media } from "reactstrap";

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: (provided) => ({ ...provided, zIndex: "999999999" }),
};

const apiUm = process.env.REACT_APP_UM_API;

export default class FilterGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tahunSekarang: new Date().getFullYear(),

      dataTa: null,
      dataKelas: null,
      dataPeminatan: null,
      dataRombel: null,
      dataSemester: null,

      tA : null,
      kelas : null,
      peminatan : null,
      rombel : null,
      semester : null,
    };
  }

  componentDidMount() {
    this.getTahunAjaran();
    this.getSemester();
    this.getKelas();
    this.getRombel();
    if (this.props.jenjang === "4")
      this.getPeminatan();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getTahunAjaran();
    this.getSemester();
    this.getKelas();
    this.getRombel();
    if (this.props.jenjang === "4")
      this.getPeminatan();
  }

  getTahunAjaran() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ id: u + " / " + i, text: u + " / " + i });
    }
    this.setState({ dataTa: ta });
  }

  getKelas() {
    var kelas = [];
    if (this.props.jenjang === "2") {
      kelas = [
        { id: "I", text: "I" },
        { id: "II", text: "II" },
        { id: "III", text: "III" },
        { id: "IV", text: "IV" },
        { id: "V", text: "V" },
        { id: "VI", text: "VI" },
      ];
    } else if (this.props.jenjang === "3") {
      kelas = [
        { id: "VII", text: "VII" },
        { id: "VIII", text: "VIII" },
        { id: "IX", text: "IX" },
      ];
    } else if (this.props.jenjang === "4") {
      kelas = [
        { id: "X", text: "X" },
        { id: "XI", text: "XI" },
        { id: "XII", text: "XII" },
      ];
    } else if (this.props.jenjang === "5") {
      kelas = [
        { id: "X", text: "X" },
        { id: "XI", text: "XI" },
        { id: "XII", text: "XII" },
        { id: "XIII", text: "XIII" },
      ];
    }
    this.setState({ dataKelas: kelas });
  }

  getPeminatan() {
    let self = this;
    let requestData = "";
    requestData += "?m_struktur_jenjang_id=" + this.props.jenjang;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ dataPeminatan: res.data });
    });
  }
  
  getSemester() {
    var semester = [];
    semester = [
      { id: "1", text: "1" },
      { id: "2", text: "2" },
    ];
    this.setState({ dataSemester: semester });
  }

  getRombel() {
    let self = this;
    var tahunAjaran = this.state.tA;
    var kelas = this.state.kelas;
    var spektrum = this.state.peminatan;
    var npsn = this.props.npsn;

    let requestData = "";
    if (!isEmpty(tahunAjaran))
      requestData += "?tahun_ajaran=" + tahunAjaran;
    if (!isEmpty(kelas))
      requestData += "&kelas=" + kelas;
    if (this.props.jenjang === "4" && spektrum !== null)
      requestData += "&spektrum=" + spektrum;
    requestData += "&npsn=" + npsn;

    axios.get(apiUm + "referensi/rombel" + requestData).then((res) => {
      self.setState({ dataRombel: res.data });
    });
  }

  getBidang() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=0";
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  getProgram() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=" + this.props.requestData.bidang;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  getKompetensi() {
    let self = this;
    let requestData = "";
    requestData += "?jenjang_id=" + this.props.requestData.kompetensi;
    axios.get(apiUm + "referensi/spektrum" + requestData).then((res) => {
      self.setState({ data: res.data });
    });
  }

  render() {
    return (
      <Row>
        <Col sm="6" className="pt-2 pb-2">
          <Select
            placeholder={
              "Pilih Tahun Ajaran"
            }
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.id}
            options={this.state.dataTa}
            onChange={(e) => {
              this.setState({ tA : e?.id ?? null }, function() {
                this.getRombel();
              })
            }}
            isClearable
            styles={colourStyles}
          />
        </Col>
        <Col sm="6" className="pt-2 pb-2">
          <Select
            placeholder={
              "Pilih Kelas"
            }
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.id}
            options={this.state.dataKelas}
            onChange={(e) => {
              this.setState({ kelas : e?.id ?? null }, function() {
                this.getRombel();
              })
            }}
            isClearable
            styles={colourStyles}
          />
        </Col>
        {this.props.jenjang === "4" ? (
          <Col sm="6" className="pt-2 pb-2">
            <Select
              placeholder={
                "Pilih Peminatan"
              }
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.id}
              options={this.state.dataPeminatan}
              onChange={(e) => {
                this.setState({ peminatan : e?.id ?? null }, function() {
                  this.getRombel();
                })
              }}
              isClearable
              styles={colourStyles}
            />
          </Col>
        ) : null}
        <Col sm="6" className="pt-2 pb-2">
          <Select
            placeholder={
              "Pilih Rombel"
            }
            getOptionLabel={(option) => option.text}
            getOptionValue={(option) => option.id}
            options={this.state.dataRombel}
            onChange={(e) => {
              this.setState({ rombel : e?.id.toString() ?? null }, function() {
                if (!isEmpty(e))
                  this.props.sendDataRombel(this.state.rombel)
              })
            }}
            isClearable
            styles={colourStyles}
          />
        </Col>
        {this.props.withSemester === true ? (
          <Col sm="6" className="pt-2 pb-2">
            <Select
              placeholder={
                "Pilih Semester"
              }
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.id}
              options={this.state.dataSemester}
              onChange={(e) => {
                this.setState({ semester : e?.id.toString() ?? null }, function() {
                  if (!isEmpty(e))
                    this.props.sendDataSemester(this.state.semester)
                })
              }}
              isClearable
              styles={colourStyles}
            />
          </Col>
        ) : null}
      </Row>
    )
  }
}
