import { isEmpty } from "lodash";

export function tglId(date) {
  if (!isEmpty(date)) {
    var d = new Date(date);
    var tanggal = d.getDate();
    var bulan = d.getMonth();
    var tahun = d.getFullYear();

    if (tanggal < 10) {
      tanggal = "0" + tanggal;
    }

    if (bulan < 10) {
      if ((bulan + 1) < 10)
        bulan = "0" + (bulan + 1);
      else
        bulan = (bulan + 1);
    }

    return tanggal + " - " + bulan + " - " + tahun;
  } else {
    return null;
  }
}
export function jam(date) {
  if (!isEmpty(date)) {
    var d = new Date(date);
    var jam = d.getHours();
    var menit = d.getMinutes();
    var detik = d.getSeconds();

    if (jam < 10) {
      jam = "0" + jam;
    }

    if (menit < 10) {
      menit = "0" + (menit + 1);
    }
    if (detik < 10) {
      detik = "0" + (detik + 1);
    }

    return jam + " : " + menit + " : " + detik;
  } else {
    return null;
  }
}

export function waktu(time) {
  if (!isEmpty(time)) {
    var d = time.substring(0, 5);
    return d;
  } else {
    return null;
  }
}
