import React, { useEffect } from "react"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from "react-select"
import axios from "axios";

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: provided => ({ ...provided, zIndex: "999999999" })
};

const ModalCreate = (props) => {
  // initial data
  const [tahunAjaran, setTahunAjaran] = React.useState(null)
  const [semester, setSemester] = React.useState([
    { value: 'I', label: 'I' },
    { value: 'II', label: 'II' },
  ])
  const [metode, setMetode] = React.useState([
    { value: '0', label: 'Offline' },
    { value: '1', label: 'Online' },
  ])

  const [selectedTahunAjaran, setSelectedTahunAjaran] = React.useState(null)
  const [selectedSemester, setSelectedSemester] = React.useState(null)
  const [namaUjian, setNamaUjian] = React.useState(null)
  const [selectedMetodeUjian, setSelectedMetodeUjian] = React.useState(null)
  const [keteranganUjian, setKeteranganUjian] = React.useState(null)
  const api = process.env.REACT_APP_API
  useEffect(() => {
    getTahunAjaran()
  }, [])

  const getTahunAjaran = () => {
    let now = new Date().getFullYear()
    let ta = [];
    let u = now + 1;
    let tahun_sekarang = now + 1;

    for (let i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ "value": u + ' / ' + i, "label": u + ' / ' + i });
    }
    setTahunAjaran(ta)
  }

  const create = () => {
    let data = {
      tahun_ajaran: selectedTahunAjaran,
      semester: selectedSemester,
      nama: namaUjian,
      metode: selectedMetodeUjian,
      keterangan: keteranganUjian,
      type_ujian: 0
    }

    if(props.type === "remidial"){
      data = {
        tahun_ajaran: selectedTahunAjaran,
        semester: selectedSemester,
        nama: namaUjian,
        metode: selectedMetodeUjian,
        keterangan: keteranganUjian,
        type_ujian: 1
      }
    }

    axios.post(api + "ujian/post-ujian", data).then(function (res) {
      props.onSuccess()
    })
  }
  const renderTitleType = () => {
    switch (props.type) {
      case "exam":
        return "Ujian"
        break;
      case "remidial":
        return "Remidial"
        break;

      default:
        break;
    }

    return null
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Buat {renderTitleType()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Tahun Ajaran</Form.Label>
            <Select
              options={tahunAjaran}
              placeholder={"Pilih Tahun Ajaran"}
              styles={colourStyles}
              onChange={(e) => setSelectedTahunAjaran(e.value)}
              isClearable
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Semester</Form.Label>
            <Select
              placeholder={"Pilih Semester"}
              options={semester}
              onChange={(e) => setSelectedSemester(e.value)}
              isClearable
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Nama {renderTitleType()}</Form.Label>
            <Form.Control type="text" placeholder="Masukkan nama" onChange={(e) => setNamaUjian(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Metode {renderTitleType()}</Form.Label>
            <Select
              options={metode}
              placeholder={`Pilih Metode Ujian`}
              onChange={(e) => setSelectedMetodeUjian(e.value)}
              isClearable
            />
          </Form.Group>
          <Form.Group style={{ display: "flex", flexDirection: "column" }} controlId="formBasicEmail">
            <Form.Label>Keterangan {renderTitleType()}</Form.Label>
            <textarea onChange={(e) => setKeteranganUjian(e.target.value)}>

            </textarea>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-pill btn-primary"
          onClick={() => create()}
        >Submit</button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalCreate;