import React from 'react';
class Authloader extends React.Component {
  render(){
    return (
      <>
        <style dangerouslySetInnerHTML=
          {{
            __html: `.header-innr { display: none }`
          }}
        />
        <div className="loader-box" style={{ background: "#0b0f25" }}>
          <div className="loader"></div>
        </div>
      </>
    )
  }
}

export default Authloader;