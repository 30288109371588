import React, {Fragment} from "react";
import {Container, Button} from "reactstrap";
import {Link} from "react-router-dom";

class ComingSoon extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          {/* <!-- Maintenance start--> */}
          <div className="error-wrapper">
            <Container>
              <ul className="maintenance-icons">
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
              </ul>
              <div className="maintenance-heading">
                <h2 className="headline font-primary">Coming Soon</h2>
              </div>
              <h4 className="sub-content">
                We are preparing something for you
                <br/>
                Thank You For Patience
              </h4>
              <div>
                <Link to="/">
                  <Button color="primary-gradien" size="lg" className="text-light">
                    BACK TO HOME PAGE
                  </Button>
                </Link>
              </div>
            </Container>
          </div>
          {/* <!-- Maintenance end--> */}
        </div>
      </Fragment>
    )
  }
}

export default ComingSoon;
