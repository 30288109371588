import React, { Fragment } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  Table, 
  CardBody
} from 'reactstrap';
import axios from 'axios';
import { isEmpty } from 'lodash'; 
import FilterGeneral from '../../common/filter/FilterGeneral'; 
import Swal from "sweetalert2";

const apiUm = process.env.REACT_APP_UM_API;

export default class DataJadwalSiswa extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //for Filter Selected
      rombelSelect : null,
      semesterSelect : null,
      // for filter Loading
      loadingFilter : false,
      loadingTahunAjaran : true,
      loadingKelas : true,
      loadingPeminatan : true,
      loadingRombel : true,
      loadingSemester : true,
      // for get dataJadwal
      dataJadwal : [],
      resetFilter : true,
    }
    this.getJadwal = this.getJadwal.bind(this);
    //filter Function
    this.setProfile = this.setProfile.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran : false });
    this.setProfile();
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setProfile();
  }
  
  setProfile() {
    let data = null
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;
    else 
      data = this.props.dataUser.user_institusi;

    this.setState({ 
      dataInstitusi : data,
      resetFilter : false
    });
  }

  getJadwal() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.state.dataInstitusi.npsn;
    requestData += '&rombel='+self.state.rombelSelect;
    requestData += '&semester='+self.state.semesterSelect;
    axios.get(apiUm + 'ja/jadwal-siswa' + requestData)
      .then(function (res) {
        self.setState({ loadingFilter : false });
        if (res.data.status === true) {
          self.setState({ dataJadwal : res.data.data });
        } else {
          self.setState({ dataJadwal : [] });
          Swal.fire("Gagal !", res.data.messages, "error");
        }
      })
  }

  convertJam(data) {
    return (data.substring(0,5));
  }

  showJadwal(){
    if (!isEmpty(this.state.dataJadwal)) {
      let data = this.state.dataJadwal;
      var hari = Object.keys(data);
      return (
        hari.map((hari, idx) => (
          <Col sm="12" className="mt-4 mb-2" key={idx}>
            <div className="table-responsive table-border-radius">
              <Table
                hover
                className="table-styling table-light"
              >
                <thead>
                  <tr>
                    <th scope="col" colSpan="5">{hari}</th>
                  </tr>
                  <tr>
                    <th scope="col">Jam Pelajaran</th>
                    <th scope="col">Indeks Mapel</th>
                    <th scope="col">Mata Pelajaran</th>
                    <th scope="col">Nama Guru Mengajar</th>
                    <th scope="col">Ruang</th>
                  </tr>
                </thead>
                <tbody>
                  {data[hari].map((jadwal, key) => (
                    <tr key={key}>
                      <td>{this.convertJam(jadwal.start) + " - " + this.convertJam(jadwal.finish)}</td>
                      {jadwal.keterangan === "Pelajaran" ? (
                        !isEmpty(jadwal.pelajaran) ? (
                          <>
                            <td>
                              <ol style={{display:"block", padding:"0px 10px"}}>
                                {jadwal.pelajaran.map((mapel, index) => (
                                  <li key={index}>{mapel.indeks_mpk}</li>
                                ))}
                              </ol>
                            </td>
                            <td>
                              <ol style={{display:"block", padding:"0px 10px"}}>
                                {jadwal.pelajaran.map((mapel, index) => (
                                  <li key={index}>{mapel.mp}</li>
                                ))}
                              </ol>
                            </td>
                            <td>
                              <ol style={{display:"block", padding:"0px 10px"}}>
                                {jadwal.pelajaran.map((mapel, index) => (
                                  <li key={index}>{mapel.nama}</li>
                                ))}
                              </ol>
                            </td>
                            <td>
                              <ol style={{display:"block", padding:"0px 10px"}}>
                                {jadwal.pelajaran.map((mapel, index) => (
                                  <li key={index}>{mapel.nama_ruang}</li>
                                ))}
                              </ol>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{jadwal.indeks_mpk !== null ? jadwal.indeks_mpk : "-"}</td>
                            <td>{jadwal.mp !== null ? jadwal.mp : "-"}</td>
                            <td>{jadwal.nama !== null ? jadwal.nama : "-"}</td>
                            <td>{jadwal.nama_ruang !== null ? jadwal.nama_ruang : "-"}</td>
                          </>
                        )
                      ) : (
                        <>
                          <td>-</td>
                          <td>{jadwal.keterangan}</td>
                          <td>{jadwal.keterangan}</td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        ))
      )
    } else {
      return(
        <Col sm="12" className="mt-1 mb-2">
          <div className="w-100 bg-light text-dark justify-center text-center p-3">
            <p className="mb-0">{this.state.loadingFilter === true ? "Mohon Tunggu Sebentar" : "Select Filter terlebih dahulu"}</p>
          </div>
        </Col>
      )
    }
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loadingFilter : true })
    this.getJadwal();
    this.showJadwal();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState({resetFilter : true }, function() {
      this.setState({ resetFilter : false })
      this.getJadwal();
      this.showJadwal();
    });
  }

  render() {
    return (
      <Card>
        {!isEmpty(this.state.dataInstitusi) ? (
          <>
            <CardHeader className="p-4" style={{ overflow: "visible" }}>
              <h5 className="mb-2">Filter Table Table</h5>
              {this.state.resetFilter === false ? (
                <FilterGeneral
                  npsn= {this.props.dataUser.npsn}
                  jenjang={this.state.dataInstitusi.jenjang}
                  sendDataRombel={(e) => this.setState({ rombelSelect : e })}
                  sendDataSemester={(e) => this.setState({ semesterSelect : e })}
                  withSemester={true}
                />
              ) : null}
              <Row>
                <Col sm={6}>
                  <span className="text-dark mr-4">Select filter secara urut</span>
                </Col>
                <Col sm={6} className="text-right">
                  <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
                  {!isEmpty(this.state.semesterSelect) ? (
                    <button 
                      onClick={this.setFilter} 
                      disabled={this.state.loadingFilter === true ? true : false}
                      className="btn btn-pill btn-primary"
                    >
                        <i className="fa fa-filter mr-2"></i> {this.state.loadingFilter === true ? "Mohon Tunggu" : "Filter"}
                    </button>
                  ) : ("")}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-4">
              <Row>
                {this.showJadwal()}
              </Row>
            </CardBody>
          </>
        ) : ("loading")}
      </Card>
    );
  }
}

