import React from "react";
import { Form, Label, FormGroup, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { isEmpty } from "lodash";
import CKEditors from "react-ckeditor-component";
import DataTable from "react-data-table-component";

const apiJa = process.env.REACT_APP_API;

export default class FormReferensiPoinTataTertib extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refPoinTatatertib: null,
      column: null,
      rowSelected: null,
      importRef: null,
    };
    this.getReferensiPoinTatatertib = this.getReferensiPoinTatatertib.bind(this);
    this.createPoinTatatertib = this.createPoinTatatertib.bind(this);
    this.getColumn = this.getColumn.bind(this);
    this.poinSelect = this.poinSelect.bind(this);
  }

  componentDidMount() {
    this.getReferensiPoinTatatertib();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getReferensiPoinTatatertib();
  }

  getReferensiPoinTatatertib() {
    let self = this;
    self.setState({ loadPoinPelanggaran: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let requestData = {
      jenis_pelanggaran: this.props.type,
    };

    axios
      .post(apiJa + `tata-tertib/get-referensi-poin-tata-tertib`, requestData, config)
      .then(function (res) {
        if (!isEmpty(res?.data.data)) {
          let data = res.data.data.items;
          let rowSelected = data => data.have == true;
          
          data.forEach((row, index) => {
            row.num = index + 1;
          });

          self.setState({ 
            refPoinTatatertib: data,
            rowSelected : rowSelected,
          }, function() {
            this.getColumn();
          });
        } else {
          this.getColumn();
        }
      });
  }

  poinSelect(e) {
    console.log(e.selectedRows);
    let rowRef = [];
    if (!isEmpty(e.selectedRows)) {
      e.selectedRows.forEach((row, index) => {
        rowRef.push(row.id);
      });
    }
    this.setState({ importRef: rowRef})
  }
  
  getColumn() {
    let self = this;
    var column = [
      {
        name: "#",
        selector: "num",
        sortable: false,
      },
    ];

    let colPelanggaran = [
      {
        name: "Jenis Pelanggaran",
        selector: "jenis_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.jenis_pelanggaran}
          </div>
        ),
      },
      {
        name: "Sangsi",
        selector: "sangsi",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.sangsi}
          </div>
        ),
      },
    ];

    let colGeneral = [
      {
        name: "Kode",
        selector: "kode_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.kode_pelanggaran}
          </div>
        ),
      },
      {
        name: "Nama " + this.props.type,
        selector: "nama_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.nama_pelanggaran}
          </div>
        ),
      },
      {
        name: "Poin",
        selector: "poin",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.poin}
          </div>
        ),
      },
    ];

    // set Column
    for (var index = 0; index < colGeneral.length; index++) {
      column.push(colGeneral[index]);
    }
    
    if (this.props.type === "Pelanggaran") {
      for (var index = 0; index < colPelanggaran.length; index++) {
        column.push(colPelanggaran[index]);
      }
    }

    this.setState({ column: column });
  }

  createPoinTatatertib(e) {
    e.preventDefault();
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let requestData = {
      id: this.state.importRef,
    };

    axios
      .post(
        apiJa +
          `tata-tertib/import-poin-tata-tertib`,
        requestData,
        config
      )
      .then((res) => {
        SweetAlert.fire({
          title: "Berhasil",
          text: "Berhasil Menambahkan Poin Pelanggaran",
          icon: "success",
        });
        self.props.closeModal(false);
      })
      .catch((err) => {
        if (err.response) {
          SweetAlert.fire({
            title: "Error",
            text: "Terjadi Kesalahan",
            icon: "error",
          });
        }
      });
  }

  render() {
    return (
      !isEmpty(this.state.column) ? (
        <>
          <DataTable
            title="Referensi Poin Tata tertib"
            columns={this.state.column}
            data={this.state.refPoinTatatertib}
            selectableRows
            onSelectedRowsChange={this.poinSelect}
            selectableRowSelected={this.state.rowSelected}
            // clearSelectedRows={toggledClearRows}
          />
          <button
            className="btn btn-primary pull-right mt-2"
            onClick={this.createPoinTatatertib}
          >
            Import
          </button>
        </>
      ) : (
        "loading"
      )
    )
  }
}
