import React, { Fragment, useEffect, useState } from "react";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import SometingWrong from "./component/common/page/SometingWrong";
import Footer from "./component/common/footer/footer";
// import ThemeCustomize from './component/common/theme-customizer/themeCustomize'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ConfigDB from "./data/customizer/config";
import { isEmpty } from "lodash";
import axios from "axios";

import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import * as getUser from "./redux/actions/UserActions";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./router/route";
import { update_token } from "./services/sso";
import { Form } from "reactstrap";
import FormPembayaran from "./component/formPembayaran/formPembayaran";

const api = `${process.env.REACT_APP_API}`;
const apiJa = process.env.REACT_APP_API;
const apiScoin = "http://scoin.sm/api/v2/";

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      `Bearer ` + localStorage.getItem("jwtToken");
    return config;
  },
  (error) => {}
);

axios.interceptors.response.use(
  (successRes) => {
    return successRes;
  },
  (error) => {
    // if (error.response?.status === 405) {
    return update_token(error);
    // }
  }
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      akses: false,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  componentDidUpdate(props) {
    if (props.UserReducer.user !== this.state.user) {
      if (this.state.user.group_code == "SI") {
        this.checkAktivasi();
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.UserReducer.user !== state.user) {
      return {
        user: props.UserReducer.user,
      };
    }
  }

  getUser() {
    let { dispatch } = this.props;
    let action = getUser.getUser();
    dispatch(action);
  }

  checkAktivasi() {
    console.log("status siswa ", this.state.user.user_siswa.status);
    let self = this;
    if (this.state.user.group_code == "SI") {
      if (this.state.user.user_siswa.status == "1") {
        self.setState({
          akses: true,
        });
      } else {
        self.setState({
          akses: false,
        });
      }
    } else if (this.state.user.group_code == "OW") {
      if (this.state.user.user_orangtua.anak.status == "1") {
        self.setState({
          akses: true,
        });
      } else {
        self.setState({
          akses: false,
        });
      }
    }
  }
  paymentDone(params = null) {
    let self = this;
    self.setState({
      akses: params,
    });
  }

  render() {
    return (
      <Fragment>
        <Loader />
        {!isEmpty(this.state.user) ? (
          <>
            {/* {this.state.user.group_code == "SI" ||
            this.state.user.group_code == "OW" ? (
              this.state.akses == false ? (
                <>
                  <FormPembayaran user={this.state.user} />
                </>
              ) : null
            ) : null} */}

            <div className="page-wrapper">
              <div className="page-body-wrapper">
                <Header
                  dataUser={this.state.user}
                  groupCode={this.state.user?.group_code}
                />
                <Sidebar
                  userData={this.state.user}
                  groupCode={this.state.user?.group_code}
                />
                <Rightsidebar />

                <div className="page-body">
                  <TransitionGroup>
                    {routes.map(({ path, Component }) => {
                      return (
                        <Route key={path} exact path={path}>
                          {(props) => (
                            <CSSTransition
                              in={props.match != null}
                              timeout={500}
                              // classNames={anim}
                              unmountOnExit
                            >
                              <div>
                                <Component {...props} />
                              </div>
                            </CSSTransition>
                          )}
                        </Route>
                      );
                    })}
                  </TransitionGroup>
                </div>
                <Footer />
                {/* <ThemeCustomize/> */}
              </div>
            </div>
          </>
        ) : (
          "loading..."
        )}
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer,
  };
};

export default connect(mapStateToProps)(App);
