import React from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import DataRekapPoinSiswa from "./component/DataRekapPoinSiswa";
import { isEmpty } from "lodash";
import { connect } from "react-redux";

class RekapPontSiswa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      user: {},
    };
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  componentDidMount() {
    //
  }
  static getDerivedStateFromProps(props, state) {
    if (props.UserReducer.user !== state.user) {
      return {
        user: props.UserReducer.user,
      };
    }
  }

  render() {
    return (
      <Col sm="12">
        <Breadcrumb parent="Rekap Point Siswa" title="Rekap Point" />
        {!isEmpty(this.state.user) ? (
          <Card>
            <CardBody className="px-2 py-4">
              <Nav className="border-tab-primary" tabs>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={this.state.activeTab === "1" ? "active" : ""}
                    onClick={() => this.toggle("1")}
                  >
                    <i className="icofont icofont-law-book" />
                    Rekap Poin Pelanggaran
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#javascript"
                    className={this.state.activeTab === "2" ? "active" : ""}
                    onClick={() => this.toggle("2")}
                  >
                    <i className="icofont icofont-medal" />
                    Rekap Poin Reward
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.activeTab === "1" ? (
                    <DataRekapPoinSiswa
                      dataUser={this.state.user}
                      type="Pelanggaran"
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.activeTab === "2" ? (
                    <DataRekapPoinSiswa
                      dataUser={this.state.user}
                      type="Reward"
                    />
                  ) : null}
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        ) : null}
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer,
  };
};

export default connect(mapStateToProps, null)(RekapPontSiswa);
