import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb/breadcrumb' 
import {Container,Row,Col,Card,CardHeader,CardBody} from 'reactstrap';


const  Sample = (props) => {
    return (
      <Fragment>
        <div className="page-wrapper">
          {/* <!-- Maintenance start--> */}
          <div className="error-wrapper maintenance-bg">
            <Container>
              <ul className="maintenance-icons">
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
              </ul>
              <div className="maintenance-heading">
                <h2 className="headline">MAINTENANCE</h2>
              </div>
              <h4 className="sub-content">
                Our Site is Currently under maintenance We will be back Shortly
                <br />
                Thank You For Patience
              </h4>
            </Container>
          </div>
          {/* <!-- Maintenance end--> */}
        </div>
      </Fragment>
    );
}

export default Sample;