import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import { 
  Container, 
  Row, 
  Col, 
} from 'reactstrap';
import { isEmpty } from 'lodash'; 
import { connect } from 'react-redux';
import DataJadwalMengajar from './component/DataJadwalMengajar';

class JadwalMengajar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user : {},
    }
  }

  componentDidMount() {
    //
  }
  
  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Jadwal Pelajaran" title="Jadwal Mengajar" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {!isEmpty(this.state.user) ? (
                <DataJadwalMengajar 
                  dataUser={this.state.user}
                />
              ) : ("")}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps, null)(JadwalMengajar);

