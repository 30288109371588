import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row } from "reactstrap";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import ListPoinTatatertib from "./component/ListPoinTataTertib";

class PoinTatatertib extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    //
  }

  static getDerivedStateFromProps(props, state) {
    if (props.UserReducer.user !== state.user) {
      return {
        user: props.UserReducer.user,
      };
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Referensi" title="Poin Tata Tertib" />
        <Container fluid={true}>
          <Row>
            {!isEmpty(this.state.user) ? (
              <ListPoinTatatertib dataUser={this.state.user} />
            ) : (
              ""
            )}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer,
  };
};

export default connect(mapStateToProps, null)(PoinTatatertib);
