import React, { Fragment } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Media,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import axios from 'axios';
import SMDataTable from '../../common/SMDataTable/SMDataTable';
import { isEmpty } from 'lodash';
import Filter from '../../common/filter/Filter';
import { tglId } from "./../../../services/timeDate";

const api = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class DataTeacher extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //for Filter Selected
      tahunAjaranSelect: null,
      penugasanSelect: null,
      mapelSelect: null,
      // for filter Loading
      loadingTahunAjaran: false,
      loadingPenugasan: false,
      loadingMapel: false,
      // for get data
      dataGuru: [],
      detailModal: false,
      dataDetail: [],
      dataProfile: {},
      dataInstitusi: {},

    }
    this.getDataGuru = this.getDataGuru.bind(this)
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterPenugasan = this.filterPenugasan.bind(this);
    this.filterMapel = this.filterMapel.bind(this);
    this.loadingFilter = this.loadingFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.detailModal = this.detailModal.bind(this);
    this.setProfile = this.setProfile.bind(this);
  }
  componentDidMount() {
    this.setProfile();
    this.getDataGuru()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getDataGuru()
  }

  getDataGuru(){
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.props.dataUser.npsn;
    requestData += '&tahun_ajaran='+(isEmpty(self.state.tahunAjaranSelect) ? '' : self.state.tahunAjaranSelect);
    requestData += '&penugasan='+(isEmpty(self.state.penugasanSelect) ? '' : self.state.penugasanSelect);
    requestData += '&mapel='+(isEmpty(self.state.mapelSelect) ? '' : self.state.mapelSelect);

    axios.get(api + `ja/penugasan-pengampu`+requestData)
    .then(function (res) {
      let data = res.data;
      if (!isEmpty(data)) {
        data.forEach((row, index) => { row.num = index + 1; });
      } 
      self.setState({ dataGuru: data })
    })
  }
  
  setProfile() {
    let data = null
    data = this.props.dataUser.user_institusi;
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;

    this.setState({ dataInstitusi : data });
  }


  filterTahunAjaran(e) {
    if (!isEmpty(e) && e !== this.state.tahunAjaranSelect) {
      this.setState({ tahunAjaranSelect: e });
    } else {
      this.setState({ tahunAjaranSelect: null });
    }
  }

  filterPenugasan(e) {
    if (!isEmpty(e) && e !== this.state.penugasanSelect) {
      this.setState({ penugasanSelect: e })
    } else {
      this.setState({ penugasanSelect: null });
    }
  }

  filterMapel(e) {
    if (!isEmpty(e) && e !== this.state.mapelSelect) {
      this.setState({ mapelSelect: e })
    } else {
      this.setState({ mapelSelect: null });
    }
  }
  
  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran: status[0],
      loadingPenugasan: status[1],
      loadingMapel: status[2],
    })
  }

  setFilter(e) {
    e.preventDefault();
    this.getDataGuru();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.loadingFilter([true, true, true])
    this.setState({
      tahunAjaranSelect: null,
      penugasanSelect: null,
      mapelSelect: null,
    }, function () {
      this.getDataGuru();
      this.loadingFilter([false, false, false])
    })
  }

  detailModal(status, id) {
    if (status === true) {
      this.setState({ detailModal : true} , function() {
        this.getDetail(id);
      })
    } else {
      this.setState({ detailModal : false, dataDetail: [] })
    }
  }

  getDetail(id) {
    let self = this;
    let requestData = '';
    requestData += '?id='+id;

    axios.get(api + `ja/penugasan-gu-detail`+requestData)
      .then(function (res) {(
        self.setState({ dataDetail: res.data })
      )})
  }

  render() {
    return (
      <Card>
        <CardHeader className="p-4" style={{ overflow: "visible" }}>
          <h5 className="mb-2">Filter Table Table</h5>
          {!isEmpty(this.state.dataInstitusi) ? (
          <Row>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="tahunAjaran"
                loading={this.state.loadingTahunAjaran}
                selectData={this.filterTahunAjaran}
                jenjang={this.state.dataInstitusi.jenjang}
              />
            </Col>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="penugasan"
                loading={this.state.loadingPenugasan}
                selectData={this.filterPenugasan}
                jenjang={this.state.dataInstitusi.jenjang}
                requestData={{
                  typePenugasan: true,
                }}
              />
            </Col>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="mapelJenjang"
                loading={this.state.loadingMapel}
                selectData={this.filterMapel}
                jenjang={this.state.dataInstitusi.jenjang}
              />
            </Col>
          </Row>
          ) : null}
          <Row>
            <Col sm={6}>
            </Col>
            <Col sm={6} className="text-right">
              <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
              <button onClick={this.setFilter} className="btn btn-pill btn-primary"><i className="fa fa-filter mr-2"></i> Filter</button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-2">
          <SMDataTable
            title="Table Guru"
            data={this.state.dataGuru}
            columns={[
              {
                name: '#',
                selector: 'num',
                sortable: false,
                width: '60px',
              },
              {
                name: 'Tahun Ajaran',
                selector: 'tahun_ajaran',
                sortable: false,
                width: '180px',
              },
              {
                name: 'Kode Pendaftaran',
                selector: 'kode_pendaftaran',
                sortable: false,
                width: '200px',
              },
              {
                name: 'NIP',
                selector: 'nip',
                sortable: false,
                width: '200px',
              },
              {
                name: 'Nama',
                selector: 'nama',
                sortable: true,
                width: '200px',
                cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
              },
              {
                name: 'Jenis Kelamin',
                selector: 'jenis_kelamin',
                sortable: true,
                cell: row => 
                  <div data-tag="allowRowEvents">
                    <div style={{ fontWeight: "bold" }}>
                      {row.jenis_kelamin !== null 
                        ? row.jenis_kelamin == 0 ? "Perempuan" : "Laki - laki"
                        : "-"
                      }
                    </div>
                  </div>
              },
              {
                name: 'Tempat Lahir',
                selector: 'tempat_lahir',
                sortable: true,
              },
              {
                name: 'Tanggal Lahir',
                selector: 'tanggal_lahir',
                sortable: true,
                width: '160px',
                cell: row => tglId(row.tanggal_lahir)
              },
              {
                name: 'Foto Profil',
                selector: 'foto_profil',
                sortable: true,
                cell: row => 
                  <div className="py-2" data-tag="allowRowEvents">
                    {isEmpty(row.foto_profil) 
                      ? <p>-</p>
                      : <div>
                          <Media
                            body
                            alt=""
                            style={{
                              height: "60px",
                              width: "60px",
                              backgroundPosition: 'center',
                              objectFit: 'cover',
                              borderRadius: '100px',
                            }}
                            src={`${urlSosmed}GU/photo/${row.foto_profil}`}
                            data-intro="This is Profile image"
                          />
                        </div>
                    }
                  </div>
              },
              {
                name: 'Nama Penugasan',
                selector: 'nama_penugasan',
                sortable: false,
                right: false,
              },
              {
                name: 'Pengampu',
                selector: 'pengampu',
                sortable: false,
                right: false,
                cell: row => 
                  <div data-tag="allowRowEvents">
                    <button onClick={this.detailModal.bind(this, true, row.id_penugasan)} className="btn btn-sm btn-info"><i className="fa fa-eye"></i></button>
                  </div>
              },
            ]}
          />
        </CardBody>
        <Modal isOpen={this.state.detailModal} toggle={this.detailModal.bind(this, false)}>
          <ModalHeader toggle={this.detailModal.bind(this, false)}>
            Mata Pelajaran
          </ModalHeader>
          <ModalBody>
            {!isEmpty(this.state.dataDetail?.mapel) ? (
              <ListGroup className="profile-dashboard">
                {this.state.dataDetail.mapel.map((value, key) => (
                  <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                    {value.indeks_mpk + " - " + value.mp}
                    <span className="badge badge-primary counter digits">
                      {value.mpk}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ) : ("")}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.detailModal.bind(this, false)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    );
  }
}

export default DataTeacher;