import React, { Fragment,  } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  CardBody, 
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

export default class LoaderPeraturan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
  }

  render() {
    return (
      <>
        <Col sm="6">
          <Card>
            <CardHeader>
              <Row>
                <Col sm="12">
                  <Skeleton height={35} />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Skeleton height={35} />
              <Skeleton height={35} />
              <Skeleton height={35} />
              <Skeleton height={35} />
              <Skeleton height={35} />
            </CardBody>
          </Card>
        </Col>
        <Col sm="6">
          <Card>
            <CardHeader>
              <Row>
                <Col sm="12">
                  <Skeleton height={35} />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Skeleton height={35} />
              <Skeleton height={35} />
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}
