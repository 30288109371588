import React, { Fragment } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  Table,
  CardBody
} from 'reactstrap';
import axios from 'axios';
import { isEmpty } from 'lodash'; 
import Filter from '../../common/filter/Filter'; 
import Swal from "sweetalert2";

const apiUm = process.env.REACT_APP_UM_API;

export default class DataJadwalGuru extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //for Filter Selected
      tahunAjaranSelect : null,
      semesterSelect : null,
      guruSelect : null,
      dataInstitusi : null,
      // for filter Loading
      loadingFilter : false,
      loadingTahunAjaran : true,
      loadingSemester : true,
      loadingGuru : true,
      // for get dataJadwal
      dataJadwal : [],
    }
    this.getJadwal = this.getJadwal.bind(this);
    //filter Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.filterGuru = this.filterGuru.bind(this);
    this.loadingFilter = this.loadingFilter.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran : false });
    this.setProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setProfile();
  }

  setProfile() {
    let data = null
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;
    else 
      data = this.props.dataUser.user_institusi;

    this.setState({ dataInstitusi : data });
  }

  getJadwal() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.state.dataInstitusi.npsn;
    requestData += '&tahun_ajaran='+self.state.tahunAjaranSelect;
    requestData += '&semester='+self.state.semesterSelect;
    requestData += '&guru='+self.state.guruSelect;
    axios.get(apiUm + 'ja/jadwal-guru' + requestData)
      .then(function (res) {
        self.setState({ loadingFilter : false });
        if(res.data.status === true) {
          self.setState({ dataJadwal : res.data });
        } else {
          self.setState({ dataJadwal : [] });
          Swal.fire("Gagal !", res.data.messages, "error");
        }
      })
  }

  convertJam(data) {
    return (data.substring(0,5));
  }

  showJadwal(){
    if (!isEmpty(this.state.dataJadwal)) {
      let data = this.state.dataJadwal;
      let hari = ['Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];
      let kuota = this.state.dataJadwal.dataWaktu;
      let guru = this.state.dataJadwal.data;
      return (
        <div className="table-responsive table-border-radius">
          <Table className="table-border-vertical table">
            <thead>
              <tr>
                <th scope="col" rowSpan="2">Nama Guru</th>
                {hari.map((hari, index) => (
                  <th key={index} colSpan={kuota[index].length}>{hari}</th>
                ))}
              </tr>
              <tr>
                {hari.map((hari, index) => (
                  kuota[index].map((kuota, row) => (
                    <th key={row}>{kuota.jam_ke === 0 ? "-" : kuota.jam_ke}</th>
                  ))
                ))}
              </tr>
            </thead>
            <tbody>
              {guru.map((guru, index) => (
                <tr key={index}>
                  <th>{guru.nama_guru}</th>
                  {guru.waktu.map((jadwal, row) => (
                    !isEmpty(jadwal.rombel) ? (
                      <th key={row}>
                        <ol type="A" style={{display:"block", padding:"0px 10px"}}>
                          <li>{jadwal.rombel}</li>
                          <li>{jadwal.indeks_mpk} - {jadwal.mp}</li>
                          <li>{jadwal.tempat}</li>
                        </ol>
                      </th>
                    ) : (
                      <th key={row}>
                        -
                      </th>
                    )
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )
    } else {
      return(
        <Col sm="12" className="mt-1 mb-2">
          <div className="w-100 bg-light text-dark justify-center text-center p-3">
            <p className="mb-0">{this.state.loadingFilter === true ? "Mohon Tunggu Sebentar" : "Select Filter terlebih dahulu"}</p>
          </div>
        </Col>
      )
    }
  }

  filterTahunAjaran(e) {
    if(!isEmpty(e)) {
      if(e !== this.state.tahunAjaranSelect) {
        this.setState({ 
          tahunAjaranSelect : e,
          semesterSelect : null,
          guruSelect : null,
        }, function(){
          this.loadingFilter([false, false, true]);
        });
      }
    } else {
      this.setState({ 
        tahunAjaranSelect : null,
        semesterSelect : null,
        guruSelect : null,
      }, function() {
        this.loadingFilter([false, true, true]);
      });
    }
  }

  filterSemester(e) {
    if(!isEmpty(e)) {
      if(e !== this.state.semesterSelect) {
        this.setState({ 
          semesterSelect : e,
          guruSelect : null,
        }, function(){
          this.loadingFilter([false, false, false]);
        });
      }
    } else {
      this.setState({ 
        semesterSelect : null,
        guruSelect : null,
      }, function(){
        this.loadingFilter([false, false, true]);
      });
    }
  }

  filterGuru(e) {
    if(!isEmpty(e)) {
      if(e !== this.state.semesterSelect) {
        this.setState({ 
          guruSelect : e,
        });
      }
    } else {
      this.setState({ 
        guruSelect : null,
      });
    }
  }

  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran : status[0],
      loadingSemester : status[1],
      loadingGuru : status[2],
    })
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loadingFilter : true });
    this.getJadwal();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.loadingFilter([true,true,true])
    this.setState({
      tahunAjaranSelect : null,
      semesterSelect : null,
      guruSelect : null,
    }, function(){
      this.setState({loadingTahunAjaran: false})
    })
  }

  render() {
    return (
			<Card>
        {!isEmpty(this.state.dataInstitusi) ? (
          <>
            <CardHeader className="p-4" style={{ overflow: "visible" }}>
              <h5 className="mb-2">Filter Table Table</h5>
              <Row>
                <Col sm="6" className="pt-2 pb-2">
                  <Filter
                    type="tahunAjaran"
                    loading={this.state.loadingTahunAjaran}
                    selectData={this.filterTahunAjaran}
                    jenjang={this.state.dataInstitusi.jenjang}
                  />
                </Col>
                <Col sm="6" className="pt-2 pb-2">
                  <Filter
                    type="semester"
                    loading={this.state.loadingSemester}
                    selectData={this.filterSemester}
                    jenjang={this.state.dataInstitusi.jenjang}
                  />
                </Col>
                <Col sm="6" className="pt-2 pb-2">
                  <Filter
                    type="guru"
                    loading={this.state.loadingGuru}
                    selectData={this.filterGuru}
                    jenjang={this.state.dataInstitusi.jenjang}
                    requestData={{
                      tahunAjaran : this.state.tahunAjaranSelect,
                      npsn : this.state.dataInstitusi.npsn
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <span className="text-dark mr-4">Select filter secara urut</span>
                </Col>
                <Col sm={6} className="text-right">
                  <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
                  {this.state.loadingGuru === false ? (
                    <button 
                      onClick={this.setFilter} 
                      disabled={this.state.loadingFilter === true ? true : false}
                      className="btn btn-pill btn-primary"
                    >
                        <i className="fa fa-filter mr-2"></i> {this.state.loadingFilter === true ? "Mohon Tunggu" : "Filter"}
                    </button>
                  ) : ("")}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-4">
              <Row>
                {this.showJadwal()}
              </Row>
            </CardBody>
          </>
        ) : ("loading")}
			</Card>
    );
  }
}

