import React, { useEffect } from "react"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from "react-select"
import axios from "axios";

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: provided => ({ ...provided, zIndex: "999999999" })
};

const ModalPreview = (props) => {
  // initial data
  const api = process.env.REACT_APP_API
  useEffect(() => {
  }, [])
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>Modal Preview</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-pill btn-primary"
        >Submit</button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalPreview;