import React from "react"

class KonsulatasiSiswa extends React.Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    render(){
        return(
            <>
            </>
        )
    }
}

export default KonsulatasiSiswa