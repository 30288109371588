import React from "react"
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SMDataTable from "../common/SMDataTable/SMDataTable";
import axios from "axios";
import ModalAddPeserta from "./Components/ModalAddPeserta";
import { connect } from "react-redux"
import { Link } from "react-router-dom";
const api = process.env.REACT_APP_API

class LihatPesertaUjian extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      data: [],
      modalAddShow: false,

    }

    this.getPeserta = this.getPeserta.bind(this)
    this.modalAddOnHide = this.modalAddOnHide.bind(this)
  }
  componentDidMount(){
    // console.log("tes", this.props)
    this.getPeserta()
  }
  getPeserta() {
    let self = this
    let ujianId = this.props.location?.state.ujian_id
    axios.post(api + "ujian-peserta/get-peserta", {
      ujian_id: ujianId
    }).then(function (res) {
      self.setState({
        data: res.data.data.items
      })
    })
  }
  modalAddOnHide() {
    let self = this
    self.setState({
      modalAddShow: !this.state.modalAddShow
    })
  }
  onSuccessAdd(){
    let self = this
    self.setState({
      modalAddShow: !this.state.modalAddShow
    })
    self.getPeserta()
  }
  render(){
    return(
      <>
      <ModalAddPeserta
        type="peserta"
        ujianId={this.props.location.state?.ujian_id}
        jenjang={this.props.UserReducer.user.jenjang}
        dataUser={this.props.UserReducer.user}
        show={this.state.modalAddShow}
        onHide={this.modalAddOnHide}
        onSuccess={this.onSuccessAdd}
      />
      <Breadcrumb parent="Ujian dan Remidial" title="Daftar Peserta Ujian Bersama" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5 className="mb-2">Filter Table Table Peserta Ujian Bersama</h5>
                  <span>
                    lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </span>
                </CardHeader>
                <CardBody clasName="card-body btn-showcase">
                  <button onClick={() => { this.setState({ modalAddShow: !this.state.modalAddShow }) }} className="btn btn-pill btn-primary">Tambah Peserta Baru</button>
                  <SMDataTable
                    title="Tabel Rencana Ujian Bersama"
                    data={this.state.data}
                    columns={[
                      {
                        name: "Kelas",
                        selector: "kelas",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Peminatan",
                        selector: "spektrum",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Rombel",
                        selector: "rombel",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Aksi",
                        selector: "id",
                        sortable: true,
                        center: true,
                        cell: row =>
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                              <Link to={{pathname: "/ujian-&-remidial/rencana-ujian-bersama/lihat-jadwal", state: {jadwal_id: row.id}}}>
                                <button className="btn btn-pill btn-primary">Lihat Peserta Ujian </button>
                              </Link>
                            </div>
                          </div>
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps)(LihatPesertaUjian)