import React, { Fragment } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  Row, 
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroupItem,
  ListGroup
} from 'reactstrap';
import Filter from '../../common/filter/Filter';
import Skeleton from 'react-loading-skeleton';
import { Database, ShoppingBag, MessageCircle, User } from "react-feather";

const apiUm = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class DataAkademikInstitusi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataPenugasanGuru: [],
      dataPenugasanLainnya: [],
      dataSiswa: [],
      dataSarpras: [],
      dataTa: [],
      dataKls: [],
      activeTab: '1',

      loadingTaPenugasanGuru: true,
      loadingTaPenugasanLainnya: true,
      loadingTaSiswa: true,
      loadingKlsSiswa: true,

      tahunSekarang: new Date().getFullYear(),
      selectTaPenugasanGuru: null,
      selectTaPenugasanLainnya: null,
      selectTaSiswa: null,
      selectKlsSiswa: "X",
    }
    this.toggle = this.toggle.bind(this);

    this.selectTahunIni = this.selectTahunIni.bind(this);
    this.getPenugasanGuru = this.getPenugasanGuru.bind(this);
    this.getPenugasanLainnya = this.getPenugasanLainnya.bind(this);
    this.getSiswa = this.getSiswa.bind(this);
    this.getSarpras = this.getSarpras.bind(this);

    this.filterTaPenugasanGuru = this.filterTaPenugasanGuru.bind(this);
    this.filterTaPenugasanLainnya = this.filterTaPenugasanLainnya.bind(this);
    this.filterTaSiswa = this.filterTaSiswa.bind(this);
    this.filterKlsSiswa = this.filterKlsSiswa.bind(this);
  }

  componentDidMount() {
    this.selectTahunIni();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, function() {
        switch (tab) {
          case '1' :
            this.getPenugasanGuru();
            break;
          case '2' :
            this.getPenugasanLainnya();
            break;
          case '3' :
            this.getSiswa();
            break;
          case '4' :
            this.getSarpras();
            break;
          default:
            break;
        }
      })
    }
  }

  selectTahunIni() {
    var tahunDepan = this.state.tahunSekarang + 1;
    var ta = this.state.tahunSekarang + ' / ' + tahunDepan;

    this.setState({
      selectTaPenugasanGuru: ta,
      selectTaPenugasanLainnya: ta,
      selectTaSiswa: ta,

      loadingTaPenugasanGuru: false,
      loadingTaPenugasanLainnya: false,
      loadingTaSiswa: false,
      loadingKlsSiswa: false,
    }, function () {
      this.getPenugasanGuru();
      this.getPenugasanLainnya();
      this.getSiswa();
      this.getSarpras();
    });
  }

  getPenugasanGuru() {
    let self = this;
    let requestData = '';
    requestData += '?npsn=' + self.props.dataProfile.npsn;
    requestData += '&tahun_ajaran=' + self.state.selectTaPenugasanGuru;
    axios.get(apiUm + 'ja/count-penugasan-gu' + requestData)
      .then(function (res) {
        if (res.data.status === true) {
          self.setState({ dataPenugasanGuru: res.data });
        } else {
          self.setState({ dataPenugasanGuru: [] });
        }
      })
  }

  getPenugasanLainnya() {
    let self = this;
    let requestData = '';
    requestData += '?npsn=' + self.props.dataProfile.npsn;
    requestData += '&tahun_ajaran=' + self.state.selectTaPenugasanLainnya;
    axios.get(apiUm + 'ja/count-penugasan-la' + requestData)
      .then(function (res) {
        if (res.data.status === true) {
          self.setState({ dataPenugasanLainnya: res.data });
        } else {
          self.setState({ dataPenugasanLainnya: [] });
        }
      })
  }

  getSiswa() {
    let self = this;
    let requestData = '';
    requestData += '?npsn=' + self.props.dataProfile.npsn;
    requestData += '&tahun_ajaran=' + self.state.selectTaSiswa;
    requestData += '&kelas=' + self.state.selectKlsSiswa;
    axios.get(apiUm + 'ja/count-rombel-si' + requestData)
      .then(function (res) {
        if (res.data.status === true) {
          self.setState({ dataSiswa: res.data });
        } else {
          self.setState({ dataSiswa: [] });
        }
      })
  }

  getSarpras() {
    let self = this;
    let requestData = '';
    requestData += '?npsn=' + self.props.dataProfile.npsn;
    requestData += '&jenjang=' + self.props.dataDetail.jenjang;
    axios.get(apiUm + 'ja/count-sarpras' + requestData)
      .then(function (res) {
        if (res.data.status === true) {
          self.setState({ dataSarpras: res.data });
        } else {
          self.setState({ dataSarpras: [] });
        }
      })
  }

  filterTaPenugasanGuru(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.selectTaPenugasanGuru) {
        this.setState({
          selectTaPenugasanGuru: e,
        }, function () {
          this.getPenugasanGuru();
        });
      }
    } else {
      this.setState({
        selectTaPenugasanGuru: null,
        dataPenugasanGuru: []
      });
    }
  }

  filterTaPenugasanLainnya(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.selectTaPenugasanLainnya) {
        this.setState({
          selectTaPenugasanLainnya: e,
        }, function () {
          this.getPenugasanLainnya();
        });
      }
    } else {
      this.setState({
        selectTaPenugasanLainnya: null,
        dataPenugasanLainnya: []
      });
    }
  }

  filterTaSiswa(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.selectTaSiswa) {
        this.setState({
          selectTaSiswa: e,
          selectKlsSiswa: null,
          dataSiswa: [],
          loadingKlsSiswa: false,
        });
      }
    } else {
      this.setState({
        selectTaSiswa: null,
        selectKlsSiswa: null,
        dataSiswa: [],
        loadingKlsSiswa: true
      });
    }
  }

  filterKlsSiswa(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.selectKlsSiswa) {
        this.setState({
          selectKlsSiswa: e,
          loadingKlsSiswa: false,
          dataSiswa: []
        }, function () {
          this.getSiswa();
        });
      }
    } else {
      this.setState({
        selectKlsSiswa: null,
        dataSiswa: []
      });
    }
  }

  showGuru() {
    let data = this.state.dataPenugasanGuru.penugasan;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataGuru = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataGuru.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {this.state.dataPenugasanGuru.penugasan.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                {value.nama_penugasan}
                <span className="badge badge-primary counter digits">
                  {value.total}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataGuru}</Row>) 
  }

  showLainnya() {
    let data = this.state.dataPenugasanLainnya.penugasan;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataLainnya = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataLainnya.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {this.state.dataPenugasanLainnya.penugasan.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                {value.nama_penugasan}
                <span className="badge badge-primary counter digits">
                  {value.total}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataLainnya}</Row>) 
  }

  showSiswa() {
    let data = this.state.dataSiswa.rombel;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataSiswa = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataSiswa.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {this.state.dataSiswa.rombel.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                {value.nama_rombel}
                <span className="badge badge-primary counter digits">
                  {value.total}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataSiswa}</Row>) 
  }

  showRuang() {
    let data = this.state.dataSarpras.sarpras;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataRuang = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataRuang.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {this.state.dataSarpras.sarpras.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center">
                {value.nama_ruang}
                <span className="badge badge-primary counter digits">
                  {value.total}
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataRuang}</Row>) 
  }

  render() {
    return (
      <Card className="border b-r-4">
        <CardHeader className="text-left p-3 px-2">
          <h5>Data Akademik</h5>
        </CardHeader>
        <CardBody className="p-0">
          <div className="text-center p-3 col-12 justify-center">
            <Nav className="nav-pills nav-primary">
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "1" ? "active" : ""}
                  onClick={() => this.toggle("1")}
                >
                  <i className="icofont icofont-building-alt"></i> Guru
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => this.toggle("2")}
                >
                  <i className="icofont icofont-business-man"></i> Lainnya
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "3" ? "active" : ""}
                  onClick={() => this.toggle("3")}
                >
                  <i className="icofont icofont-group-students"></i> Siswa
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={this.state.activeTab === "4" ? "active" : ""}
                  onClick={() => this.toggle("4")}
                >
                  <i className="icofont icofont-building-alt"></i> Ruang
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row className="px-4">
                <Col sm="12" xl="6" className="my-2 text-left">
                  <div className="form-group">
                    <label>Tahun Ajaran</label>
                    <Filter
                      type="tahunAjaran"
                      loading={this.state.loadingTaPenugasanGuru}
                      selectData={this.filterTaPenugasanGuru}
                      defaultSelect={{ 'id': this.state.selectTaPenugasanGuru, 'text': this.state.selectTaPenugasanGuru }}
                    />
                  </div>
                </Col>
                <Col sm="12" xl="6">
                  {!isEmpty(this.state.selectTaPenugasanGuru) ? (
                    <Card className="gradient-info o-hidden">
                      <CardBody className="b-r-4 p-4">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <User className="text-white i ml-2" />
                          </div>
                          <div className="media-body">
                            <div className="text-left">
                              <span className="m-0 text-white">Total Guru</span>
                              <h4 className="mb-0 counter text-white">{this.state.dataPenugasanGuru.total}</h4>
                            </div>
                            <User className="icon-bg" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
              {!isEmpty(this.state.dataPenugasanGuru.penugasan) ? this.showGuru() : null}
            </TabPane>
            <TabPane tabId="2">
              <Row className="px-4">
                <Col sm="12" xl="6" className="my-2 text-left">
                  <div className="form-group">
                    <label>Tahun Ajaran</label>
                    <Filter
                      type="tahunAjaran"
                      loading={this.state.loadingTaPenugasanLainnya}
                      selectData={this.filterTaPenugasanLainnya}
                      defaultSelect={{ 'id': this.state.selectTaPenugasanLainnya, 'text': this.state.selectTaPenugasanLainnya }}
                    />
                  </div>
                </Col>
                <Col sm="12" xl="6">
                  {!isEmpty(this.state.selectTaPenugasanLainnya) ? (
                    <Card className="gradient-warning o-hidden">
                      <CardBody className="b-r-4 p-4">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <User className="text-white i ml-2" />
                          </div>
                          <div className="media-body">
                            <div className="text-left">
                              <span className="m-0 text-white">Total Lainnya</span>
                              <h4 className="mb-0 counter text-white">{this.state.dataPenugasanLainnya.total}</h4>
                            </div>
                            <User className="icon-bg" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
              {!isEmpty(this.state.dataPenugasanLainnya.penugasan) ? this.showLainnya() : null}
            </TabPane>
            <TabPane tabId="3">
              <Row className="px-4">
                <Col sm="12" xl="6" className="my-2 text-left">
                  <div className="form-group">
                    <label>Tahun Ajaran</label>
                    <Filter
                      type="tahunAjaran"
                      loading={this.state.loadingTaSiswa}
                      selectData={this.filterTaSiswa}
                      defaultSelect={{ 'id': this.state.selectTaSiswa, 'text': this.state.selectTaSiswa }}
                    />
                  </div>
                  <div className="form-group">
                    <label>Kelas</label>
                    <Filter
                      type="kelas"
                      loading={this.state.loadingKlsSiswa}
                      selectData={this.filterKlsSiswa}
                      jenjang={"4"}
                      defaultSelect={{ 'id': this.state.selectKlsSiswa, 'text': this.state.selectKlsSiswa }}
                    />
                  </div>
                </Col>
                <Col sm="12" xl="6">
                  {!isEmpty(this.state.selectTaSiswa) && !isEmpty(this.state.selectKlsSiswa) ? (
                    <Card className="gradient-secondary o-hidden mt-4">
                      <CardBody className="b-r-4 p-4">
                        <div className="media static-top-widget">
                          <div className="align-self-center text-center">
                            <User className="text-white i ml-2" />
                          </div>
                          <div className="media-body">
                            <div className="text-left">
                              <span className="m-0 text-white">Total Siswa</span>
                              <h4 className="mb-0 counter text-white">{this.state.dataSiswa.total}</h4>
                            </div>
                            <User className="icon-bg" />
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
              {!isEmpty(this.state.dataSiswa.rombel) ? this.showSiswa() : null}
            </TabPane>
            <TabPane tabId="4">
              {!isEmpty(this.state.dataSarpras.sarpras) ? this.showRuang() : null}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    )
  }
}

export default DataAkademikInstitusi;