import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter,} from 'react-router-dom'
import "./index.scss";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store/index'
import AuthRoute from './router/authRoute'
import MainApp from './router/mainRoute'
import axios from "axios"
require('dotenv').config()

axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ` + localStorage.getItem('jwtToken');
  return config;
}, (error) => {
  console.log('req error');
  return Promise.reject(error);
});

const Root = (props) => {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter>
          <AuthRoute />
          <MainApp />
        </BrowserRouter>
      </Provider>
    </Fragment>
  )
}
ReactDOM.render(<Root />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
