import React, { Component } from "react";
import axios from "axios";
import Masonry from "react-masonry-css";
import {
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Media,
  Col
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { tglId, waktu } from "./../../../services/timeDate";
import SweetAlert from "sweetalert2";

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
};

export default class SMGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: {
        data: [],
        current_page: 1,
        from: 1,
        last_page: 50,
        per_page: 10,
        to: 1,
        total: 1,
      },
      gridMode: 3,
      query: '',
      first_page: 1,
      current_page: 1,
      offset: 4,
    };
    this.detailJurnal = this.detailJurnal.bind(this);
  }

  resetPage(qry){
    this.setState({ current_page : 1 }, function(){
      this.fetchEntities(qry);
    });
  }

  gridMode(num){
    this.setState({ gridMode : num })
  }

  fetchEntities(qry = false) {
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: { Authorization: "Bearer " + jwtToken },
    };

    let fetchUrl = `${self.props.url}?page=${self.state.current_page}`;
    if (qry) {
      fetchUrl = `${fetchUrl}`;
      self.setState({ query: qry });
    }
    axios.post(fetchUrl, qry, config)
      .then(function (res) {
        if (res.data.code === 107) 
          SweetAlert.fire({ title: "Error", text: res.data.message, icon: "error" });

        if (!isEmpty(res)) 
          self.setState({ entities: res.data.data });
      })
      .catch(function (err) {
        
      })
  }

  changePage(pageNumber) {
    this.setState({ current_page: pageNumber }, () => {
      this.fetchEntities(this.state.query);
    });
  }

  pagesNumbers() {
    if (!this.state.entities.to) {
      return [];
    }
    let from = this.state.entities.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + this.state.offset * 2;
    if (to >= this.state.entities.last_page) {
      to = this.state.entities.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  pageList() {
    // console.log(this.pagesNumbers());
    return this.pagesNumbers().map((page) => {
      return (
        <PaginationItem 
          key={page}
          active={page === this.state.entities.current_page ? true : false}
          onClick={() => page === this.state.entities.current_page ? 
            (null) : (this.changePage(page)) 
          }
        >
          <PaginationLink>{page}</PaginationLink>
        </PaginationItem>
      );
    });
  }

  detailJurnal(id) {
    this.props.detailJurnal(id, this.props.typePage, true);
  }

  render() {
    return (
      !isEmpty(this.state.entities?.data) ? (
        <>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-gallery row grid gallery-with-description masonary-desc"
            columnClassName={`grid-item col-xl-${this.state.gridMode} col-sm-6`}
          >
            {this.state.entities.data.map((value, key) => (
              <li style={{ listStyle: "none" }} key={key}>
                <Card onClick={this.detailJurnal.bind(this, value.id)} className="border" style={{ cursor : "pointer" }}>
                  <div className="job-search">
                    <CardBody className="p-4">
                      {this.props.groupCode === "GU" ? (
                        <h4>{value.rombel.nama_rombel}</h4>
                      ) : (
                        <h4>{value.nama}</h4>
                      )}
                      <b><p className="mt-2 text-primary">{value.mapel + ' - ' + value.indeks_mapel}</p></b>
                      {this.props.typePage === "rencana_topik" ? (
                        <p className="mt-3">
                          {!isEmpty(value.rencana_topik) ? (
                            value.rencana_topik.length > 119 ? (
                              value.rencana_topik.slice(0, 120) + "...."
                            ) : (
                              value.rencana_topik
                            )
                          ) : (
                            "Rencana Belum terisi"
                          )}
                        </p>
                      ) : (
                        <p className="mt-3">
                          {!isEmpty(value.realisasi_topik) ? (
                            value.realisasi_topik.length > 119 ? (
                              value.realisasi_topik.slice(0, 120) + "...."
                            ) : (
                              value.realisasi_topik
                            )
                          ) : (
                            "Realisasi Belum terisi"
                          )}
                        </p>
                      )}
                      <hr/>
                      <Media>
                        <Media body>
                          <h6 className="f-w-600 pull-right">
                            <span className="pull-right text-dark mb-2 ml-2">
                              <b>{waktu(value.jam_awal) +" - "+ waktu(value.jam_akhir)} di {value.ruang}</b>
                            </span>
                            <span className="badge badge-primary pull-right">
                              (Jam ke {value.jam_ke}) {tglId(value.tanggal)}
                            </span>
                          </h6>
                        </Media>
                      </Media>
                    </CardBody>
                  </div>
                </Card>
              </li>
            ))}
          </Masonry>
          <div>
            {this.state.entities.data && this.state.entities.data.length > 0 && (
              <Pagination>
                <ul className="pagination pagination-primary">
                  <PaginationItem 
                    disabled={1 === this.state.entities.current_page ? true : false}
                    onClick={() =>
                      1 === this.state.entities.current_page ? (
                        null
                      ) : (this.changePage(this.state.entities.current_page - 1))
                    }
                  >
                    <PaginationLink>Previous</PaginationLink>
                  </PaginationItem>
                  {this.pageList()}
                  <PaginationItem 
                    disabled={
                      this.state.entities.last_page === this.state.entities.current_page ? true : false
                    }
                    onClick={() =>
                      this.state.entities.last_page === this.state.entities.current_page ? (
                        null
                      ) : (this.changePage(this.state.entities.current_page + 1))
                    }
                  >
                    <PaginationLink>Next</PaginationLink>
                  </PaginationItem>
                </ul>
              </Pagination>
            )}
          </div>
        </>
      ) : (
        <Col sm="12" className="text-center">
          <p>Belum ada data sama sekali</p>
        </Col>
      )
    );
  }
}