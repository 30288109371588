import React, { Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Media,
  ModalHeader,
} from "reactstrap";
import axios from "axios";
import { isEmpty } from "lodash";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import Filter from "../../common/filter/Filter";
import { tglId } from "../../../services/timeDate";
import { Modal } from "reactstrap";
import { ModalBody } from "reactstrap";
import { Button } from "reactstrap";
import { ModalFooter } from "reactstrap";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import FilterGeneral from "../../common/filter/FilterGeneral";

const apiJa = process.env.REACT_APP_API;
const api = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class DataDaftarPelanggaranSiswa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //for Filter Selected
      rombelSelect: null,
      semesterSelect: null,
      tahunAjaranSelect: null,
      kelasSelect: null,
      peminatanSelect: null,
      dataProfile: {},
      dataInstitusi: {},
      // for filter Loading
      loadingTahunAjaran: true,
      loadingKelas: true,
      loadingPeminatan: true,
      loadingRombel: true,
      loadingSemester: true,
      // for get data
      dataSiswa: [],
      siswaDetail: "",
      column: [],
      newDate: new Date(),
      modalInput: false,
      tahun: "",
      poinTatatertib: [],
      poin_tatatertib_id: "",
      kronologi: "",
      jenis_pelanggaran: "",
      waktu_pelanggaran: "",

      resetFilter: false,
    };

    this.getDataSiswa = this.getDataSiswa.bind(this);

    //filters Function
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.setProfile = this.setProfile.bind(this);
  }

  handleModal = (kode_pendaftaran, show) => {
    if (show === true) {
      axios
        .get(
          `https://umapi.schoolmedia.id/api/publik/v1/ja/profile-user?kode_pendaftaran=${kode_pendaftaran}&group_code=SI`
        )
        .then((res) => {
          this.setState({
            siswaDetail: res?.data ?? null,
            modalInput: true,
          });
        });
    } else {
      this.setState({
        modalInput: false,
      });
    }
  };

  createRekap = () => {
    let self = this;
    let requestData = {
      kode_pendaftaran: this.state.siswaDetail.kode_pendaftaran,
      kronologi_keterangan: this.state.kronologi,
      poin_tatatertib_id: this.state.poin_tatatertib_id,
      jenis_pelanggaran: this.state.jenis_pelanggaran,
      waktu_pelanggaran: this.state.waktu_pelanggaran,
    };

    if (
      isEmpty(this.state.kronologi) ||
      isEmpty(this.state.waktu_pelanggaran) ||
      isEmpty(this.state.poin_tatatertib_id) ||
      isEmpty(this.state.jenis_pelanggaran)
    ) {
      Swal.fire(
        "Gagal!",
        "Data gagal ditambahkan, Isi data dengan lengkap.",
        "error"
      );
    } else {
      let jwtToken = localStorage.getItem("jwtToken");
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      };
      axios
        .post(apiJa + `poin-siswa/tambah-rekap-poin-siswa`, requestData, config)
        .then(function (res) {
          Swal.fire("Berhasil!", "Data berhasil ditambahkan.", "success");
          self.setState({
            modalInput: false,
          });
        });
    }
  };

  changeJenisPelanggaran = (event) => {
    let self = this;
    self.setState({ loadPoinPelanggaran: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let requestData = {
      jenis_pelanggaran: event.target.value,
    };

    axios
      .post(apiJa + `tata-tertib/get-poin-tata-tertib`, requestData, config)
      .then(function (res) {
        self.setState({
          poinTatatertib: res.data.data.items,
          jenis_pelanggaran: event.target.value,
        });
      });
  };
  handleClose = () => {
    this.setState({
      modalInput: false,
    });
  };
  componentDidMount() {
    this.setState({ loadingTahunAjaran: false });
    this.setProfile();
    this.getDataSiswa();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setProfile();
    this.getDataSiswa();
  }

  setProfile() {
    let data = null;
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;
    else data = this.props.dataUser.user_institusi;

    this.setState({ dataInstitusi: data });
  }

  getDataSiswa() {
    let self = this;
    let requestData = "";

    requestData += "?npsn=" + self.props.dataUser.npsn;
    if (!isEmpty(self.state.semesterSelect))
      requestData += "&semester=" + self.state.semesterSelect;
    if (!isEmpty(self.state.rombelSelect))
      requestData += "&rombel=" + self.state.rombelSelect;
      
    axios.get(api + `ja/rombel-siswa` + requestData).then(function (res) {
      let data = res.data;
      if (!isEmpty(data)) {
        data.forEach((row, index) => {
          row.num = index + 1;
        });
      }
      self.setState({ dataSiswa: res.data }, function () {
        this.getColumn();
      });
    });
  }

  setFilter(e) {
    e.preventDefault();
    this.getDataSiswa();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState({resetFilter : true }, function() {
      this.setState({ resetFilter : false })
      this.getDataSiswa();
    })
  }
  getColumn() {
    var column = [
      {
        name: "#",
        selector: "num",
        sortable: false,
        width: "60px",
      },

      {
        name: "NISN",
        selector: "nisn",
        sortable: false,
        width: "100px",
      },
      {
        name: "Nama Lengkap",
        selector: "nama",
        width: "250px",
        sortable: true,
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ fontWeight: "bold" }}>{row.nama}</div>
          </div>
        ),
      },
      {
        name: "Jenis Kelamin",
        selector: "jenis_kelamin",
        width: "100px",
        sortable: true,
        cell: (row) => (
          <div data-tag="allowRowEvents">
            <div style={{ fontWeight: "bold" }}>
              {row.jenis_kelamin !== null
                ? row.jenis_kelamin == 0
                  ? "P"
                  : "L"
                : "-"}
            </div>
          </div>
        ),
      },

      {
        name: "Tempat Lahir",
        selector: "tempat_lahir",
        width: "150px",
        sortable: true,
      },
      {
        name: "Tanggal Lahir",
        selector: "tanggal_lahir",
        sortable: true,
        width: "120px",
        cell: (row) => tglId(row.tanggal_lahir),
      },

      {
        name: "Kelas",
        selector: "kelas",
        width: "100px",
        sortable: true,
      },
      {
        name: "Rombel",
        selector: "nama_rombel",
        sortable: true,
      },
    ];

    let colGuru = [
      {
        name: "Input",
        selector: "input",
        sortable: true,
        cell: (row) => (
          <div>
            <div data-tag="allowRowEvents">
              <button
                className="btn btn-pill btn-primary btn-sm"
                onClick={() => this.handleModal(row.kode_pendaftaran, true)}
              >
                + Poin
              </button>
            </div>
          </div>
        ),
      },
    ];

    if (this.props.dataUser.group_code === "GU") {
      column.push(colGuru[0]);
    }

    // [
    //   {
    //     name: "Input",
    //     selector: "input",
    //     sortable: true,
    //     cell: (row) => (
    //       <div>
    //         {this.props.dataUser.group_code == "GU" ? (
    //           <div data-tag="allowRowEvents">
    //             <button className="btn btn-primary btn-sm">
    //               Input Pelanggaran
    //             </button>
    //           </div>
    //         ) : null}
    //       </div>
    //     ),
    //   },
    // ];

    this.setState({ column: column });
  }
  PoinPelanggaranId = (e) => {
    this.setState({
      poin_tatatertib_id: e.target.value,
    });
  };
  waktuPelanggaran = (e) => {
    this.setState({
      waktu_pelanggaran: e.target.value,
    });
  };
  kronologi = (e) => {
    this.setState({
      kronologi: e.target.value,
    });
  };
  render() {
    return (
      <Card>
        <CardHeader className="p-4" style={{ overflow: "visible" }}>
          <h5 className="mb-2">Filter Table Table</h5>
          {!isEmpty(this.state.dataInstitusi) && this.state.resetFilter === false ? (
            <FilterGeneral
              npsn= {this.props.dataUser.npsn}
              jenjang={this.state.dataInstitusi.jenjang}
              sendDataRombel={(e) => this.setState({ rombelSelect : e })}
              sendDataSemester={(e) => this.setState({ semesterSelect : e })}
              withSemester={true}
            />
          ) : null}
          <Row>
            <Col sm={6} className="text-left">
              <span>Select filter secara urut</span>
            </Col>
            <Col sm={6} className="text-right">
              <button
                onClick={this.setResetFilter}
                className="btn btn-pill btn-warning mr-2"
              >
                <i className="fa fa-refresh mr-2"></i> Reset
              </button>
              {this.state.semesterSelect !== null ? (
                <button
                  onClick={this.setFilter}
                  className="btn btn-pill btn-primary"
                >
                  <i className="fa fa-filter mr-2"></i> Filter
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-2">
          <SMDataTable
            title="Table Siswa"
            data={this.state.dataSiswa}
            columns={this.state.column}
          />
        </CardBody>
        <div>
          <Modal
            isOpen={this.state.modalInput}
            toggle={this.handleModal.bind(this, null, false)}
            className="modal-lg"
          >
            <ModalHeader toggle={this.handleClose}>
              Input Pelanggaran
            </ModalHeader>
            <ModalBody>
              {!isEmpty(this.state.siswaDetail.kode_pendaftaran) ? (
                <div>
                  <div className="row">
                    <div className="col-sm-6">
                      <label htmlFor="">NISN</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.nisn}
                      />
                      <br />
                      <label htmlFor="">Tempat Lahir</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.tempat_lahir}
                      />
                      <br />
                      {!isEmpty(this.state.siswaDetail.no_handphone) ? (
                        <div>
                          <label htmlFor="">No Handphone</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value={this.state.siswaDetail.no_handphone}
                          />
                        </div>
                      ) : (
                        <div>
                          <label htmlFor="">No Handphone</label>
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            value="-"
                          />
                        </div>
                      )}
                      <br />
                      <label htmlFor="">Waktu pelanggaran</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        onChange={this.waktuPelanggaran}
                      />
                      {/* <DatePicker
                        className="form-control digits"
                        showPopperArrow={false}
                        placeholderText="Set waktu Pelanggaran"
                        selected={this.state.waktu_pelanggaran}
                        showTimeSelect
                        timeFormat="HH:mm"
                        dateFormat="DD/MM/YYYY HH:mm:ss"
                        dateFormat="Pp"
                        onChange={this.waktuPelanggaran}
                      /> */}
                      <br />
                      <label htmlFor="">Jenis Pelanggaran</label>
                      <select
                        name=""
                        onChange={this.changeJenisPelanggaran}
                        className="form-control"
                        id=""
                      >
                        <option value="">Pilih Jenis Pelanggaran</option>
                        <option value="Ringan">Ringan</option>
                        <option value="Sedang">Sedang</option>
                        <option value="Berat">Berat</option>
                        <option value="Reward">Reward</option>
                      </select>
                      <br />
                      <label htmlFor="">Nama Pelanggaran</label>
                      <select
                        onChange={this.PoinPelanggaranId}
                        name=""
                        className="form-control"
                        id=""
                        required
                      >
                        {!isEmpty(this.state.poinTatatertib) ? (
                          <>
                            <option value="">Pilih Pelanggaran</option>
                            {this.state.poinTatatertib.map((val) => {
                              return (
                                <option value={val.id}>
                                  {val.m_poin_tatatertib.nama_pelanggaran}
                                </option>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <option value="">Kosong</option>
                          </>
                        )}
                      </select>
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">Nama Siswa</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.nama}
                      />
                      <br />
                      <label htmlFor="">Tanggal Lahir</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.tanggal_lahir}
                      />
                      <br />
                      <label htmlFor="">Kelas</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.rombel.kelas}
                      />
                      <br />
                      <label htmlFor="">Rombel</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.state.siswaDetail.rombel.nama_rombel}
                      />
                      <br />
                      <label htmlFor="">Guru Pengisi</label>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        value={this.props.dataUser.username}
                      />
                      <br />

                      <label htmlFor="">Kronologi Kejadian</label>
                      <textarea
                        onChange={this.kronologi}
                        style={({ minHeight: "100px" }, { maxHeight: "120px" })}
                        name=""
                        className="form-control"
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </div>
              ) : (
                "loading"
              )}
            </ModalBody>
            <ModalFooter>
              {/* <Button color="primary" onClick={this.handleClose}>
                Do Something
              </Button>{" "} */}
              <Button color="success" onClick={this.createRekap}>
                Simpan
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </Card>
    );
  }
}

export default DataDaftarPelanggaranSiswa;
