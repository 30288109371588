import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, } from 'reactstrap';
import { init_sso } from './../services/sso';
import isEmpty from "lodash/isEmpty";

class Home extends React.Component{
  constructor(props){
    super(props)
  }

  componentDidMount() {
    console.log(this.state?.alert);
    if(!isEmpty(this.state?.alert)) {
      console.log("ok")
    }
    if (!isEmpty(localStorage.getItem('redirect'))) 
      localStorage.removeItem('redirect');

    init_sso();
    
  }

  render(){
    return(
      <>
        <style dangerouslySetInnerHTML={{__html: `
            body, html {
              height: 100%;
              background: url(${require("../assets/images/background/landing_page_bg.png")});
              background-size: cover;
              background-repeat: no-repeat;
              background-color: #09719d;
              height: 100% !important;
              background-position: center;
            }
          `}}
        />
        <Container>
          <Row className="mt-4 mb-4 py-2">
            <Col sm="6">
              <img src={require("../assets/images/logo/logo.png")} alt="img"></img>
            </Col>
            <Col sm="6" className="text-right">
              <ul className="list-inline">
                <li class="list-inline-item mx-2"><Link to="/" className="text-white font-weight-bold">Tentang Schoolmedia</Link></li>
                <li class="list-inline-item mx-2"><Link to="/" className="text-white font-weight-bold">Promo</Link></li>
              </ul>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="6" className="mt-4 text-white">
              <h1 className="font-weight-bold">Jurnal Akademik</h1>
              <p className="mt-5 font-weight-normal" style={{fontSize: "1rem"}}>
                Aplikasi ini merupakan aplikasi pencatat jurnal akademik sekolah. Lebih dari sekedar
                pencatat digital, aplikasi ini juga akan menjadi kontrol dari kehadiran guru dan siswa,
                juga berfungsi sebagai pengkonfirmasi keterlaksanaan RPP (rencana pelaksanaan pembelajaran)
                yang sudah divalidasi oleh wakil kepala sekolah bidang kurikulum.
              </p>
              <Link to="/auth" className="btn btn-success mt-4 py-2"><h5 className="m-0 font-weight-bold">LOGIN</h5></Link>
              <Link to="/" className="btn btn-info ml-2 mt-4 py-2"><h5 className="m-0 font-weight-bold">PELAJARI</h5></Link>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default Home;