import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import { Container, Row, Col } from 'reactstrap';
import SMDataTable from '../common/SMDataTable/SMDataTable';

export default class Perizinan extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      npsnSelect : "19221947",
      jenjangSelect : "4",
      dataPerizinan : []
    }

  }

  componentDidMount() {
    //
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Jurnal Akademik" title="Perizinan" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <SMDataTable
                title="Table Perizinan"
                data={this.state.dataPerizinan}
                columns={[
                  {
                    name: 'Nama Anak',
                    selector: 'nama_anak',
                    sortable: true,
                    cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
                  },
                  {
                    name: 'Nama Orang Tua',
                    selector: 'nama_orang_tua',
                    sortable: true,
                    cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
                  },
                  {
                    name: 'Jenis Orang Tua',
                    selector: 'jenis_orang_tua',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Tahun Ajaran',
                    selector: 'tahun_ajaran',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Kelas',
                    selector: 'kelas',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Rombel',
                    selector: 'rombel',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Semester',
                    selector: 'semester',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Hari, Tanggal',
                    selector: 'tanggal',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Nama Guru',
                    selector: 'nama_guru',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Penugasan Guru',
                    selector: 'penugasan_guru',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Mata Pelajaran',
                    selector: 'mata_pelajaran',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'File',
                    selector: 'file',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Pesan',
                    selector: 'pesan',
                    sortable: true,
                    right: true,
                  },
                  {
                    name: 'Balasan',
                    selector: 'balasan',
                    sortable: true,
                    right: true,
                  },
                ]}
              />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

