import React from "react";
import DataTable from "react-data-table-component";

class SMDataTable extends React.Component {
  render() {
    // custom feature (add number column)
    let data = this.props.data;
    let columns = this.props.columns;
    let no_to_push = { name: "No", selector: "list_number", sortable: "true" };

    if (this.props.number) {
      columns.unshift(no_to_push);
      data.forEach((val, index) => {
        val.list_number = index + 1;
      });
    }

    return (
      <DataTable
        responsive
        fixedHeader
        columns={columns}
        data={data}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    );
  }
}

const customStyles = {
  table: {
    style: {
      lineHeight: "20px",
      borderWidth: "0px",
    },
  },
  header: {
    style: {
      display: "none",
    },
  },
  rows: {
    style: {
      borderWidth: "0px",
    },
  },
  headRow: {
    style: {
      borderWidth: "0px",
    },
  },
  headCells: {
    style: {
      lineHeight: "20px",
      borderWidth: "0px",
    },
  },
  cells: {
    style: {
      borderTopWidth: "0px",
      borderLeftWidth: "0px",
      borderRightWidth: "0px",
      // bottom
      borderBottomStyle: "solid",
      borderBottomWidth: "0.5px",
      borderBottomColor: "#eeeeee",

      lineHeight: "20px",
      paddingTop: "4px",
      paddingBottom: "4px",
      width: "120px",
      "&:not(:last-of-type)": {
        borderBottomWidth: "0px",
      },
    },
  },
};

const conditionalRowStyles = [
  {
    when: (row) => row,
    style: {
      "&:hover": {
        backgroundColor: "RGBA(16,68,156,0.13)",
        color: "#10449C",
        borderRadius: "50px",
      },
    },
  },
];

export default SMDataTable;
