import React, { Fragment } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardHeader, 
  Table,
  CardBody
} from 'reactstrap';
import axios from 'axios';
import { isEmpty } from 'lodash'; 
import Filter from '../../common/filter/Filter'; 
import Swal from "sweetalert2";

const apiUm = process.env.REACT_APP_UM_API;

export default class DataJadwalMengajar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //for Filter Selected
      tahunAjaranSelect : null,
      semesterSelect : null,
      dataInstitusi : null,
      // for filter Loading
      loadingFilter : false,
      loadingTahunAjaran : true,
      loadingSemester : true,
      // for get dataJadwal
      dataJadwal : [],
    }
    this.getJadwal = this.getJadwal.bind(this);
    //filter Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.loadingFilter = this.loadingFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran : false });
    this.setProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setProfile();
  }

  setProfile() {
    let data = null;
    data = this.props.dataUser.institusi;

    this.setState({ dataInstitusi : data });
  }

  getJadwal() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.state.dataInstitusi.npsn;
    requestData += '&tahun_ajaran='+self.state.tahunAjaranSelect;
    requestData += '&semester='+self.state.semesterSelect;
    requestData += '&uuid='+self.props.dataUser.uuid;
    axios.get(apiUm + 'ja/jadwal-guru' + requestData)
      .then(function (res) {
        self.setState({ loadingFilter : false });
        if(res.data.status === true) {
          self.setState({ dataJadwal : res.data });
        } else {
          self.setState({ dataJadwal : [] });
          Swal.fire("Gagal !", res.data.messages, "error");
        }
      })
  }

  convertJam(data) {
    return (data.substring(0,5));
  }

  showJadwal(){
    if (!isEmpty(this.state.dataJadwal)) {
      let hari = ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];
      let guru = this.state.dataJadwal.data[0];
      return (
        <div className="table-responsive table-border-radius">
          <Table className="table-border-vertical table">
            <thead>
              <tr>
                <th>Hari</th>
                <th>Jam</th>
                <th>Mata Pelajaran</th>
                <th>Rombel</th>
                <th>Ruangan</th>
              </tr>
            </thead>
            <tbody>
              {guru.waktu.map((jadwal, row) => (
                !isEmpty(jadwal.rombel) ? (
                  <tr className="border border-bottom border-light" key={row}>
                    <td>{hari[jadwal.m_hari_id]}</td>
                    <td>{this.convertJam(jadwal.start) + " - " + this.convertJam(jadwal.finish)}</td>
                    <td>{jadwal.indeks_mpk} - {jadwal.mp}</td>
                    <td>{jadwal.rombel}</td>
                    <td>{jadwal.tempat}</td>
                  </tr>
                ) : null
              ))}
            </tbody>
          </Table>
        </div>
      )
    } else {
      return(
        <Col sm="12" className="mt-1 mb-2">
          <div className="w-100 bg-light text-dark justify-center text-center p-3">
            <p className="mb-0">{this.state.loadingFilter === true ? "Mohon Tunggu Sebentar" : "Select Filter terlebih dahulu"}</p>
          </div>
        </Col>
      )
    }
  }

  filterTahunAjaran(e) {
    if(!isEmpty(e)) {
      if(e !== this.state.tahunAjaranSelect) {
        this.setState({ 
          tahunAjaranSelect : e,
          semesterSelect : null,
          guruSelect : null,
					loadingSemester: true,
        }, function(){
          this.loadingFilter([false, false]);
        });
      }
    } else {
      this.setState({ 
        tahunAjaranSelect : null,
        semesterSelect : null,
        guruSelect : null,
      }, function() {
        this.loadingFilter([false, true]);
      });
    }
  }

  filterSemester(e) {
    if(!isEmpty(e)) {
      if(e !== this.state.semesterSelect) {
        this.setState({ 
          semesterSelect : e,
          guruSelect : null,
        });
      }
    } else {
      this.setState({ 
        semesterSelect : null,
        guruSelect : null,
      });
    }
  }

  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran : status[0],
      loadingSemester : status[1],
    })
  }

  setFilter(e) {
    e.preventDefault();
    this.setState({ loadingFilter : true });
    this.getJadwal();
    this.showJadwal();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.loadingFilter([true,true,true])
    this.setState({
      tahunAjaranSelect : null,
      semesterSelect : null,
      guruSelect : null,
    }, function(){
      this.setState({loadingTahunAjaran: false})
    })
  }

  render() {
    return (
			<Card>
        {!isEmpty(this.state.dataInstitusi) ? (
          <>
          
            <CardHeader className="p-4" style={{ overflow: "visible" }}>
              <h5 className="mb-2">Filter Table Table</h5>
              <Row>
                <Col sm="6" className="pt-2 pb-2">
                  <Filter
                    type="tahunAjaran"
                    loading={this.state.loadingTahunAjaran}
                    selectData={this.filterTahunAjaran}
                    jenjang={this.state.dataInstitusi.jenjang}
                  />
                </Col>
                <Col sm="6" className="pt-2 pb-2">
                  <Filter
                    type="semester"
                    loading={this.state.loadingSemester}
                    selectData={this.filterSemester}
                    jenjang={this.state.dataInstitusi.jenjang}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <span className="text-dark mr-4">Select filter secara urut</span>
                </Col>
                <Col sm={6} className="text-right">
                  <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
                  {this.state.loadingSemester === false ? (
                    <button 
                      onClick={this.setFilter} 
                      disabled={this.state.loadingFilter === true ? true : false}
                      className="btn btn-pill btn-primary"
                    >
                        <i className="fa fa-filter mr-2"></i> {this.state.loadingFilter === true ? "Mohon Tunggu" : "Filter"}
                    </button>
                  ) : ("")}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-4">
              <Row>
                {this.showJadwal()}
              </Row>
            </CardBody>
          </>
        ) : ("loading")}
			</Card>
    );
  }
}

