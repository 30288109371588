import React from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import Filter from "../common/filter/Filter";
import { isEmpty } from "lodash";
import SMDataTable from "../common/SMDataTable/SMDataTable";
import ModalCreate from "./components/ModalCreate";

class CatatanSiswa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCatatan: [
        { nama: "Akhmad Khalif", poin: 12 },
        { nama: "siapa ya", poin: 13 },
      ],

      jenjangSelect: "4",
      // For Filter Selected
      tahunAjaranSelect: "2020 / 2021",
      kelasSelect: null,
      rombelSelect: null,
      semesterSelect: null,
      // For Filter Loading
      loadingTahunAjaran: true,
      loadingKelas: true,
      loadingRombel: true,
      loadingSemester: true,

      modalCreate: false
    };
    // Filters Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    // Loading Filters
    this.loadingFilter = this.loadingFilter.bind(this);

    this.showModal = this.showModal.bind(this)
    this.onSuccessAdd = this.onSuccessAdd.bind(this)
  }
  componentDidMount() {
    this.setState({
      loadingTahunAjaran: false,
    });
  }

  filterTahunAjaran(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.tahunAjaranSelect) {
        this.setState(
          {
            tahunAjaranSelect: e,
            semesterSelect: null,
            namaUjianSelect: null,
          },
          function () {
            this.loadingFilter([false, false, false]);
          }
        );
      }
    } else {
      this.setState(
        {
          tahunAjaranSelect: null,
          semesterSelect: null,
          namaUjianSelect: null,
        },
        function () {
          this.loadingFilter([false, true, true]);
        }
      );
    }
  }

  filterKelas(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.kelasSelect) {
        this.setState(
          {
            kelasSelect: e,
            rombelSelect: null,
            semesterSelect: null,
            mapelSelect: null,
          },
          function () {
            this.loadingFilter([false, false, false, false, true, true]);
          }
        );
      }
    } else {
      this.setState(
        {
          kelasSelect: null,
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
        },
        function () {
          this.loadingFilter([false, false, true, true, true]);
        }
      );
    }
  }

  filterRombel(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.rombelSelect) {
        this.setState(
          {
            rombelSelect: e,
            semesterSelect: null,
            mapelSelect: null,
          },
          function () {
            this.loadingFilter([false, false, false, false, false]);
          }
        );
      }
    } else {
      this.setState(
        {
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
          loadingSemester: true,
        },
        function () {
          this.loadingFilter([false, false, false, false, true]);
        }
      );
    }
  }

  filterSemester(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.semesterSelect) {
        this.setState({
          semesterSelect: e,
          mapelSelect: null,
        });
      }
    } else {
      this.setState({
        semesterSelect: null,
        mapelSelect: null,
      });
    }
  }

  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran: status[0],
      loadingKelas: status[1],
      loadingRombel: status[2],
      loadingSemester: status[3],
    });
  }

    setFilter(e) {
        e.preventDefault();
    }

    setResetFilter(e) {
        e.preventDefault();
        this.loadingFilter([true, true, true, true, true]);
        this.setState({
            tahunAjaranSelect: null,
            kelasSelect: null,
            rombelSelect: null,
            semesterSelect: null,
        }, function () {
            this.setState({ loadingTahunAjaran: false });
        });
    }
    showModal(){
      this.setState({
        modalCreate: !this.state.modalCreate
      })
    }
    onSuccessAdd(){
      console.log("succcess")
      this.setState({
        modalCreate: !this.state.modalCreate
      })
    }
  render() {
    return (
      <>
        <ModalCreate
          type="exam"
          show={this.state.modalCreate}
          onHide={this.showModal}
          onSuccess={this.onSuccessAdd}
        />
        <Breadcrumb parent="Bimbingan Konseling" title="Catatan Siswa" />
        <Container>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="tahunAjaran"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="peminatan"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="kelas"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="rombel"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <button onClick={this.showModal} className="btn btn-pill btn-warning mr-2"><i className="fa fa-plus mr-2"></i> Buat Catatan</button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody clasName="card-body btn-showcase">
                  <SMDataTable
                    title="Tabel Rekap Catatan"
                    data={this.state.dataCatatan}
                    number={true}
                    columns={[
                      {
                        name: "Nama Siswa",
                        selector: "nama",
                      },
                      {
                        name: "Tahun Ajaran",
                        selector: "tahun_ajaran",
                      },
                      {
                        name: "Kelas",
                        selector: "kelas",
                      },
                      {
                        name: "Peminatan",
                        selector: "peminatan",
                      },
                      {
                        name: "Rombel",
                        selector: "rombel",
                      },
                      {
                        name: "Poin",
                        selector: "poin",
                        sortable: true,
                      },
                      {
                        name: "Status Poin",
                        selector: "status_poin",
                      },
                      {
                        name: "Hasil Poin",
                        selector: "hasil poin",
                      },
                      {
                        name: "Keterangan",
                        selector: "keterangan",
                      },
                      {
                        name: "Aksi",
                        selector: "aksi",
                        cell: row =>
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                              anjay
                            </div>
                          </div>
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CatatanSiswa;
