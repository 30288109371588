import React from "react";
import LoaderPeraturan from "../../common/loader/LoaderPeraturan";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { isEmpty } from "lodash";
import SweetAlert from "sweetalert2";
import axios from "axios";
import FormPoinTataTertib from "./form/FormPoinTataTertib";
import FormReferensiPoinTataTertib from "./form/FormReferensiPoinTataTertib";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import { Star, Briefcase, Trash } from "react-feather";

const apiJa = process.env.REACT_APP_API;

export default class DataPoinTataTertib extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poinTatatertib: [],
      detailPoinTatatertib: null,
      loadingPoinTatatertib: true,
      modalEdit: false,
      modalCreate: false,
      totalPointaTertib: 0,
      column: null,
      user: {},
    };

    this.getPoinTatatertib = this.getPoinTatatertib.bind(this);
    this.createPoinTatatertib = this.createPoinTatatertib.bind(this);
    this.editPoinTatatertib = this.editPoinTatatertib.bind(this);
    this.deletePoinTatatertib = this.deletePoinTatatertib.bind(this);
    this.getColumn = this.getColumn.bind(this);
  }

  componentDidMount() {
    this.getPoinTatatertib();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getPoinTatatertib();
  }

  getPoinTatatertib() {
    let self = this;
    self.setState({ loadPoinPelanggaran: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let requestData = {
      jenis_pelanggaran: this.props.type,
    };

    axios
      .post(apiJa + `tata-tertib/get-poin-tata-tertib`, requestData, config)
      .then(function (res) {
        if (!isEmpty(res?.data.data)) {
          let data = res.data.data.items;
          let total = data.length;
          data.forEach((row, index) => {
            row.num = index + 1;
          });
          self.setState(
            {
              poinTatatertib: data,
              totalPointaTertib: total,
              loadingPoinTatatertib: false,
            },
            function () {
              this.getColumn();
            }
          );
        } else {
          self.setState(
            {
              loadingPoinTatatertib: false,
              totalPointaTertib: 0,
            },
            function () {
              this.getColumn();
            }
          );
        }
      });
  }

  createPoinTatatertib(show) {
    let self = this;
    if (self.props.dataUser.group_code === "NI") {
      self.setState({ modalCreate: show }, function () {
        if (show === false) self.getPoinTatatertib();
      });
    }
  }

  editPoinTatatertib(show, id) {
    let self = this;
    if (self.props.dataUser.group_code === "NI") {
      if (id !== null) {
        let jwtToken = localStorage.getItem("jwtToken");
        let config = {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        };
        axios
          .post(
            apiJa + `tata-tertib/detail-poin-tata-tertib`,
            { id: id },
            config
          )
          .then(function (res) {
            if (!isEmpty(res?.data.data)) {
              self.setState({
                detailPoinTatatertib: res.data.data.item,
              });
            } else {
              self.setState({
                detailPoinTatatertib: null,
              });
            }
          });
      }

      self.setState({ modalEdit: show }, function () {
        if (show === false) {
          self.setState({ detailPoinTatatertib: null });
          self.getPoinTatatertib();
        }
      });
    }
  }

  deletePoinTatatertib(id) {
    let self = this;
    SweetAlert.fire({
      title: "Apa anda yakin akan hapus poin ini?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Hapus",
      denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let jwtToken = localStorage.getItem("jwtToken");
        let config = {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        };
        axios
          .post(
            apiJa + `tata-tertib/delete-poin-tata-tertib`,
            { id: id },
            config
          )
          .then(function (res) {
            if (res.data.code === 107) {
              SweetAlert.fire(res.data.message, "", "error");
            } else {
              SweetAlert.fire(res.data.message, "", "success");
            }
            self.getPoinTatatertib();
          });
      } else if (result.isDenied) {
        SweetAlert.fire("Batal dihapus", "", "info");
      }
    });
  }

  getColumn() {
    let self = this;
    var column = [
      {
        name: "#",
        selector: "num",
        sortable: false,
      },
    ];

    let colPelanggaran = [
      {
        name: "Jenis Pelanggaran",
        selector: "jenis_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div onClick={this.editPoinTatatertib.bind(this, true, row.id)}>
            {row.m_poin_tatatertib.jenis_pelanggaran}
          </div>
        ),
      },
      {
        name: "Sangsi",
        selector: "sangsi",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div onClick={this.editPoinTatatertib.bind(this, true, row.id)}>
            {row.sangsi}
          </div>
        ),
      },
    ];

    let colGeneral = [
      {
        name: "Kode",
        selector: "kode_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div onClick={this.editPoinTatatertib.bind(this, true, row.id)}>
            {row.m_poin_tatatertib.kode_pelanggaran}
          </div>
        ),
      },
      {
        name: "Nama " + this.props.type,
        selector: "nama_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div onClick={this.editPoinTatatertib.bind(this, true, row.id)}>
            {row.m_poin_tatatertib.nama_pelanggaran}
          </div>
        ),
      },
      {
        name: "Poin",
        selector: "poin",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div onClick={this.editPoinTatatertib.bind(this, true, row.id)}>
            {row.poin}
          </div>
        ),
      },
    ];

    let colInstitusi = [
      {
        name: "Hapus",
        selector: null,
        sortable: false,
        cell: (row) => (
          <button
            className="btn btn-danger btn-sm btn-pill pull-right"
            onClick={this.deletePoinTatatertib.bind(this, row.id)}
          >
            <i className="icofont icofont-trash"></i>
          </button>
        ),
      },
    ];

    // set Column

    for (var index = 0; index < colGeneral.length; index++) {
      column.push(colGeneral[index]);
    }

    if (this.props.type === "Pelanggaran") {
      for (var index = 0; index < colPelanggaran.length; index++) {
        column.push(colPelanggaran[index]);
      }
    }

    if (this.props.dataUser.group_code === "NI") {
      column.push(colInstitusi[0]);
    }
    this.setState({ column: column });
  }

  render() {
    return (
      <Col sm="12">
        <Row className="p-3">
          <Col sm="12" md="4" lg="4" xl="4">
            <Card className="gradient-primary o-hidden">
              <CardBody className="b-r-4 p-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    {this.props.type === "Pelanggaran" ? (
                      <Briefcase className="text-white i ml-2" />
                    ) : (
                      <Star className="text-white i ml-2" />
                    )}
                  </div>
                  <div className="media-body">
                    <div className="text-left">
                      <span className="m-0 text-white">
                        Total Poin {this.props.type}{" "}
                      </span>
                      <h4 className="mb-0 counter text-white">
                        {this.state.totalPointaTertib}
                      </h4>
                    </div>
                    {this.props.type === "Pelanggaran" ? (
                      <Briefcase className="icon-bg" />
                    ) : (
                      <Star className="icon-bg" />
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="2" lg="2" xl="2"></Col>
          <Col sm="12" md="6" lg="6" xl="6">
            {this.props.dataUser.group_code === "NI" ? (
              <button
                className="btn btn-primary btn-pill pull-right"
                onClick={this.createPoinTatatertib.bind(this, true)}
              >
                <i className="icofont icofont-plus mr-2"></i>Tambah Poin{" "}
                {this.props.type}
              </button>
            ) : null}
          </Col>
          <Col sm="12" md="12" lg="12" xl="12">
            <hr />
            {!isEmpty(this.state.column) ? (
              <SMDataTable
                title={"Table Poin " + this.props.type}
                data={this.state.poinTatatertib}
                columns={this.state.column}
              />
            ) : (
              "loading"
            )}
          </Col>
        </Row>

        {/* modal create peraturan */}
        <Modal
          isOpen={this.state.modalCreate}
          toggle={this.createPoinTatatertib.bind(this, false)}
          size="xl"
        >
          <ModalBody>
            {this.state.modalCreate === true ? (
              <FormReferensiPoinTataTertib
                type={this.props.type}
                dataUser={this.props.dataUser}
                closeModal={this.createPoinTatatertib}
              />
            ) : null}
          </ModalBody>
        </Modal>

        {/* modal edit peraturan */}
        <Modal
          isOpen={this.state.modalEdit}
          toggle={this.editPoinTatatertib.bind(this, false, null)}
        >
          <ModalHeader toggle={this.editPoinTatatertib.bind(this, false, null)}>
            Peraturan
          </ModalHeader>
          <ModalBody>
            {this.state.loadingPoinTatatertib === false ? (
              <FormPoinTataTertib
                type={this.props.type}
                detailPoinTatatertib={this.state.detailPoinTatatertib}
                closeModal={this.editPoinTatatertib}
              />
            ) : null}
          </ModalBody>
        </Modal>
      </Col>
    );
  }
}
