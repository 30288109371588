import React, { Fragment } from "react";
import { 
    Container, 
    Row, 
    Col,
  } from 'reactstrap';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <div className="container-sm">
                    <div className="text-center">
                        <h3><b>Kebijakan Privasi e-SMATAJAYA dan Jurnal Akademik</b></h3>
                    </div>

                    <p><b>Pendahuluan</b></p>
                    <p className="ml-3">
                        Kebijakan Privasi ini menjelaskan bagaimana e-SMATAJAYA dan Jurnal Akademik (selanjutnya disebut sebagai "Aplikasi") mengumpulkan, menggunakan, memproses, dan melindungi informasi pribadi yang diberikan oleh pengguna aplikasi dan website kami. Kami berkomitmen untuk melindungi privasi pengguna dan memberikan lingkungan yang aman untuk semua pengguna.
                    </p>
                    <p><b>Informasi yang Dikumpulkan</b></p>
                    <p className="ml-3">
                        Kami mengumpulkan informasi pribadi saat Anda mendaftar, menggunakan, atau mengakses layanan kami. Informasi ini mungkin termasuk, tetapi tidak terbatas pada, nama, alamat email, nomor telepon, informasi akademik, dan data terkait lainnya.
                    </p>
                    <p><b>Penggunaan Informasi</b></p>
                    <p className="ml-3">
                        Informasi yang dikumpulkan digunakan untuk:
                        <ul className="list-outsite ml-3" style={{ 'listStyle' : 'disc' }}>
                            <li>Memfasilitasi perancangan rencana pembelajaran oleh guru.</li>
                            <li>Memungkinkan realisasi pembelajaran yang efektif.</li>
                            <li>Meringankan tugas Bimbingan Konseling (BK) dalam hal kedisiplinan siswa.</li>
                            <li>Memungkinkan orang tua untuk memantau perkembangan anak di sekolah.</li>
                        </ul>
                    </p>
                    <p><b>Berbagi Informasi</b></p>
                    <p className="ml-3">
                        Kami tidak menjual, menukar, atau menyewakan informasi pribadi pengguna kepada pihak ketiga tanpa persetujuan Anda, kecuali dalam kasus yang diharuskan oleh hukum.
                    </p>
                    <p><b>Keamanan Data</b></p>
                    <p className="ml-3">
                        Kami mengambil langkah-langkah keamanan yang sesuai untuk melindungi terhadap akses tidak sah atau pengungkapan informasi pribadi pengguna.
                    </p>
                    <p><b>Perubahan pada Kebijakan Privasi</b></p>
                    <p className="ml-3">
                        Kebijakan Privasi ini dapat diubah dari waktu ke waktu. Kami akan memberitahu pengguna tentang perubahan yang signifikan melalui aplikasi atau website kami.
                    </p>
                    <p><b>Kontak</b></p>
                    <p className="ml-3">
                    Untuk pertanyaan atau kekhawatiran mengenai Kebijakan Privasi ini, silakan <a href="https://schoolmedia.co.id/kontak">hubungi kami.</a>
                    </p>
                </div>
            </>
        )
    }
}

export default Privacy;

