import React from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { isEmpty } from "lodash";
import Select from "react-select";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import axios from "axios";
import { Briefcase } from "react-feather";
import { tglId, jam } from "../../../services/timeDate";
import Swal from "sweetalert2";

const apiJa = process.env.REACT_APP_API;
const kelas = [
  { value: "X", label: "X", name: "kelas" },
  { value: "XI", label: "XI", name: "kelas" },
  { value: "XII", label: "XII", name: "kelas" },
];

class DataRekapPoinSiswa extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      rekapPoinPelanggaran: [],
      column: [],
      modalDetail: false,
      detailRekap: [],
    };

    this.getRekap = this.getRekap.bind(this);
  }
  getDetail = (id) => {
    let self = this;
    self.setState({ loadPoinPelanggaran: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .get(apiJa + `poin-siswa/detail-rekap-poin-siswa/${id}`, {}, config)
      .then(function (res) {
        console.log(res.data.data.item);
        if (res.data.data == "") {
          self.setState({
            detailRekap: "tidak ada data",
            modalDetail: true,
          });
        } else {
          self.setState({
            detailRekap: res.data.data.item,
            modalDetail: true,
          });
        }
      });
  };

  closeModal = () => {
    let self = this;
    self.setState({
      modalDetail: false,
    });
  };
  getColumn() {
    var column = [
      // {
      //   name: "#",
      //   selector: "num",
      //   sortable: false,
      //   width: "60px",
      // },
      {
        name: "Nama",
        selector: "user_siswa.nama",
        sortable: false,
        width: "120px",
        style: { cursor: "pointer" },
        cell: (row) => <div>{row.user_siswa.nama}</div>,
      },
      {
        name: "Kelas",
        selector: "user_siswa.history_rombel.rombel.kelas",
        sortable: false,
        width: "60px",
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>{row.user_siswa.history_rombel.rombel.kelas ?? "-"}</div>
        ),
      },
      {
        name: "Rombel",
        selector: "user_siswa.history_rombel.rombel.nama_rombel",
        sortable: false,
        width: "120px",
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>{row.user_siswa.history_rombel.rombel.nama_rombel ?? "-"}</div>
        ),
      },
      {
        name: "Kode",
        width: "80px",
        selector: "poin_pelanggaran.m_poin_tatatertib.kode_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div
            data-toggle="tooltip"
            data-placement="top"
            title={row.poin_tatatertib.m_poin_tatatertib.nama_pelanggaran}
          >
            {row.poin_tatatertib.m_poin_tatatertib.kode_pelanggaran}
          </div>
        ),
      },
      // {
      //   name: "Nama Aktifitas",
      //   selector: "poin_tatatertib.m_poin_tatatertib.nama_pelanggaran",
      //   sortable: false,
      //   style: { cursor: "pointer" },
      //   cell: (row) => (
      //     <div>{row.poin_tatatertib.m_poin_tatatertib.nama_pelanggaran}</div>
      //   ),
      // },
      {
        name: "Jenis",
        width: "90px",
        selector: "jenis_pelanggaran",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>{row.poin_tatatertib.m_poin_tatatertib.jenis_pelanggaran}</div>
        ),
      },
      {
        name: "Sangsi",
        width: "110px",
        selector: "sangsi",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => <div>{row.poin_tatatertib.sangsi}</div>,
      },
      // {
      //   name: "Kronologi",
      //   selector: "kronologi",
      //   sortable: false,
      //   style: { cursor: "pointer" },
      //   cell: (row) => <div>{row.kronologi_keterangan}</div>,
      // },
      {
        name: "Diinput Oleh",
        selector: null,
        sortable: false,
        width: "150px",
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            {row.user_guru.nama} <br /> {tglId(row.created_at)}
          </div>
        ),
      },

      {
        name: "Poin",
        selector: "keterangan_poin",
        width: "80px",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => <div>{row.keterangan_poin}</div>,
      },
      {
        name: "Jumlah",
        selector: "jumlah",
        width: "100px",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => <div>{row.jumlah_poin}</div>,
      },
      {
        name: "Detail",
        selector: "detail",
        width: "100px",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            <button
              className="btn btn-pill btn-info btn-sm "
              onClick={() => this.getDetail(row.id)}
            >
              <i className="fa fa-eye"></i>
            </button>
          </div>
        ),
      },
    ];
    let colDelete = [
      {
        name: "Hapus",
        selector: "hapus",
        sortable: false,
        style: { cursor: "pointer" },
        cell: (row) => (
          <div>
            <button
              className="btn btn-pill btn-danger btn-sm "
              onClick={() => this.deleteRekap(row.id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
      },
    ];

    if (this.props.dataUser.group_code === "GU") {
      column.push(colDelete[0]);
    }

    this.setState({ column: column });
  }

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  componentDidMount() {
    this.getRekap(this.props.type);
  }

  getRekap(type) {
    let self = this;
    self.setState({ loadPoinPelanggaran: true });
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .get(apiJa + `poin-siswa/get-rekap-poin-siswa?type=` + type, {}, config)
      .then(function (res) {
        if (res.data.code === 107) {
          Swal.fire({
            icon: "info",
            title: "Info",
            text: res.data.message,
          });
        } else {
          self.setState({
            rekapPoinPelanggaran: res.data.data.items,
          });
          self.getColumn();
        }
      });
  }

  deleteRekap(id) {
    let self = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Data berhasil dihapus.", "success");
        let self = this;
        let jwtToken = localStorage.getItem("jwtToken");
        let config = {
          headers: {
            Authorization: "Bearer " + jwtToken,
          },
        };

        axios
          .post(apiJa + `poin-siswa/delete-rekap-poin-siswa/${id}`, config)
          .then((res) => {
            self.getRekap(this.props.type);
          });
      }
    });
  }

  render() {
    return (
      <>
        <Row className="p-3 ">
          <Col sm="12" md="6" lg="6" xl="6">
            <h6>
              <b>Rekap Poin {this.props.type}: </b>
            </h6>
          </Col>

          <Col sm="12" md="12" lg="12" xl="12">
            <hr />
            <SMDataTable
              title="Table Poin Siswa"
              data={this.state.rekapPoinPelanggaran}
              columns={this.state.column}
            />
          </Col>
        </Row>

        <Modal
          isOpen={this.state.modalDetail}
          toggle={this.closeModal}
          className="modal-lg modal-dialog-scrollable"
        >
          <ModalHeader toggle={this.closeModal}>
            Detail {this.props.type}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <b>Nama {this.props.type}</b>
              </div>
              <div className="col-sm-6">
                {!isEmpty(this.state.detailRekap)
                  ? this.state.detailRekap.poin_tatatertib.m_poin_tatatertib
                      .nama_pelanggaran
                  : "-"}
              </div>
              <div className="col-sm-12">
                <hr />
              </div>
              <div className="col-sm-6">
                <b>Kronologi</b>
              </div>

              <div className="col-sm-6">
                {!isEmpty(this.state.detailRekap)
                  ? this.state.detailRekap.kronologi_keterangan
                  : "-"}
              </div>
              <div className="col-sm-12">
                <hr />
              </div>
              <div className="col-sm-6">
                <b>Waktu {this.props.type}</b>
              </div>

              <div className="col-sm-6">
                {!isEmpty(this.state.detailRekap)
                  ? tglId(this.state.detailRekap.waktu_pelanggaran) +
                    " | " +
                    jam(this.state.detailRekap.waktu_pelanggaran)
                  : "-"}
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }
}

export default DataRekapPoinSiswa;
