import React, {Fragment} from "react";
import {Container, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {LogOut} from 'react-feather'

class SometingWrong extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="page-wrapper">
          {/* <!-- Someting Wrong start--> */}
          <div className="error-wrapper maintenance-bg">
            <Container>
              <ul className="maintenance-icons">
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
                <li>
                  <i className="fa fa-cog"></i>
                </li>
              </ul>
              <div className="maintenance-heading">
                <h6 className="headline font-primary">Terjadi Kesalahan</h6>
              </div>
              <h4 className="sub-content">
                Telah terjadi kesalahan, mohon untuk login kembali.
                <br/>
                Kemungkinan dikarenakan beberapa Faktor Sebagai Berikut.
                <hr />
                <ul>
                  <li>
                    <p>
                      <b>- Institusi Belum Login </b> 
                      Institusi belum aktif diaplikasi ini, Silahkan Logout terlebih dahulu lalu Hubungi operator sekolah tersebut agar mengaktifkan User JA Institusi.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>- Siswa Belum Login </b> 
                      Jika anda login user Orang Tua, terdapat hal yang tidak bisa diterima di sistem ketika orang tua login akan tetapi siswa belum login sama sekali.
                      Silahkan Logout terlebih dahulu dan tunggu sampai user Siswa Anda diaktifkan oleh siswa.
                    </p>
                  </li>
                </ul>
              </h4>
              <div>
                {/* <Link to="/">
                  <Button color="primary-gradien" size="lg" className="text-light">
                    BACK TO HOME PAGE
                  </Button>
                </Link> */}
                <Link   to="/auth/sso/logout">
                  <Button color="primary-gradien" size="lg" className="text-light">
                    <LogOut/> LOGOUT
                  </Button>
                </Link>
              </div>
            </Container>
          </div>
          {/* <!-- Someting Wrong end--> */}
        </div>
      </Fragment>
    )
  }
}

export default SometingWrong;
