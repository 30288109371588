import React, { Fragment, useEffect, useState } from 'react';
import { Switch, Route, } from 'react-router-dom'
import "../index.scss";
import { connect } from 'react-redux'
import App from '../App';
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route'
import ConfigDB from '../data/customizer/config'
import { isEmpty } from "lodash"
import NotLogin from "../component/common/page/NotLogin";
require('dotenv').config()

const MainApp = (props) => {
  const [anim, setAnim] = useState("");
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation
  const abortController = new AbortController();

  useEffect(() => {
    setAnim(animation)
    const layout = localStorage.getItem('layout_version')
    document.body.classList.add(layout);
    const color = localStorage.getItem('color')
    document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    }

    // eslint-disable-next-line
  }, []);


  return (
    <Fragment>
      {!isEmpty(localStorage.getItem('jwtToken')) ?
        <Fragment>
          <Switch>
            <App>
              
            </App>
          </Switch>
        </Fragment>
      :
        // <NotLogin />
        null
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    SSOReducer: state.SSOReducer
  }
}

export default connect(mapStateToProps)(MainApp)
