import React, { Fragment } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import formatRupiah from "../../utils/formatRupiah";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Media,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroupItem,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import DataAkademikInstitusi from "./DataAkademikInstitusi";
import DataAkademikGuru from "./DataAkademikGuru";
import DataAkademikSiswa from "./DataAkademikSiswa";
import DataAkademikOrtu from "./DataAkademikOrtu";
import { Briefcase } from "react-feather";
import { DollarSign } from "react-feather";
import Swal from "sweetalert2";
import { ModalFooter } from "reactstrap";

const apiUm = process.env.REACT_APP_UM_API;
const apiJa = process.env.REACT_APP_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class ProfileCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      dataProfile: [],
      dataDetail: [],
      dataExtra: [],
      dataAddress: [],
      datapoinawalsiswa: null,
      biayaAkses: null,
      modal: false,
      setBiayaAkses: null,
    };
    this.toggle = this.toggle.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.fetchProfileAddress = this.fetchProfileAddress.bind(this);
  }

  componentDidMount() {
    this.fetchProfileAddress();
    this.getPoin();
    this.getBiayaAkses();
  }
  setModal = () => {
    let self = this;
    self.setState({
      modal: !this.state.modal,
    });
  };
  changeBiayaAkses = (e) => {
    if (e.target.value < 0) {
      document.getElementById("changeBa").value = 0;
      Swal.fire("Gagal!", "Biaya Akses Tidak Boleh Kurang Dari 0", "error");
    } else {
      this.setState({
        setBiayaAkses: e.target.value,
      });
    }
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    switch (tab) {
      case "1":
        break;
      case "2":
        break;
      default:
        break;
    }
  }

  fetchProfileAddress() {
    var extra = [];
    var address = [];
    if (this.props.dataUser.group_code === "NI") {
      extra = [
        { index: "npsn", text: "NPSN" },
        { index: "jenis_institusi", text: "Jenis Institusi" },
        { index: "website", text: "Website" },
      ];
    } else if (this.props.dataUser.group_code === "GU") {
      extra = [
        { index: "jenis_kelamin", text: "Jenis Kelamin" },
        { index: "tempat_lahir", text: "Tempat Lahir" },
        { index: "tanggal_lahir", text: "Tanggal Lahir" },
      ];
    } else if (this.props.dataUser.group_code === "SI") {
      extra = [
        { index: "jenis_kelamin", text: "Jenis Kelamin" },
        { index: "tempat_lahir", text: "Tempat Lahir" },
        { index: "tanggal_lahir", text: "Tanggal Lahir" },
        { index: "nisn", text: "NISN" },
        { index: "alumni", text: "Alumni" },
      ];
    } else if (this.props.dataUser.group_code === "OW") {
      extra = [
        { index: "jenis_kelamin", text: "Jenis Kelamin" },
        { index: "tempat_lahir", text: "Tempat Lahir" },
        { index: "tanggal_lahir", text: "Tanggal Lahir" },
        { index: "alumni", text: "Alumni" },
        { index: "pekerjaan", text: "Pekerjaan" },
      ];
    }

    address = [
      { index: "negara", text: "Negara" },
      { index: "provinsi", text: "Provinsi" },
      { index: "kabupaten", text: "Kabupaten / Kota" },
      { index: "kecamatan", text: "Kecamatan" },
      { index: "desa", text: "Desa / Kelurahan" },
      { index: "kode_pos", text: "Kode Pos" },
      { index: "rt", text: "RT" },
      { index: "rw", text: "RW" },
    ];

    this.setState(
      {
        dataExtra: extra,
        dataAddress: address,
      },
      function () {
        this.getProfile();
      }
    );
  }

  getProfile() {
    let data = null;
    if (this.props.dataUser.group_code === "NI")
      data = this.props.dataUser.user_institusi;
    if (this.props.dataUser.group_code === "GU")
      data = this.props.dataUser.user_guru;
    if (this.props.dataUser.group_code === "SI")
      data = this.props.dataUser.user_siswa;
    if (this.props.dataUser.group_code === "OW")
      data = this.props.dataUser.user_orangtua;

    this.setState({
      dataProfile: this.props.dataUser,
      dataDetail: data,
    });
  }

  cek(data) {
    if (!isEmpty(data)) {
      return data;
    } else {
      return "-";
    }
  }
  getPoin() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios
      .get(apiJa + `tata-tertib/get-poin-tata-tertib-institusi`, {}, config)
      .then((res) => {
        this.setState({
          datapoinawalsiswa: res.data.data?.item.poin_awal ?? "-",
        });
      });
  }

  getBiayaAkses() {
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.get(apiJa + `biaya/get-biaya-akses`, {}, config).then((res) => {
      this.setState({
        biayaAkses: res.data.data,
      });
    });
  }

  setBiayaAkses = () => {
    // console.log(this.state.biayaAkses.item.biaya);

    if (this.state.biayaAkses == null && this.state.setBiayaAkses == null) {
      Swal.fire("Gagal!", "Biaya Akses Harus Terisi", "error");
    } else {
      if (isEmpty(this.state.setBiayaAkses)) {
        var biayaAkses = this.state.biayaAkses.item.biaya;
      } else {
        var biayaAkses = this.state.setBiayaAkses;
      }
      let requestData = {
        biaya: biayaAkses,
      };
      let jwtToken = localStorage.getItem("jwtToken");
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      };
      axios
        .post(apiJa + "biaya/set-biaya-akses", requestData, config)
        .then((res) => {
          this.setState({
            modal: false,
          });
          this.getBiayaAkses();
          Swal.fire("Berhasil!", "Berhasil Set Biaya Akses", "success");
        });
    }
  };

  render() {
    return !isEmpty(this.state.dataProfile) &&
      !isEmpty(this.state.dataDetail) ? (
      <Card className="card hovercard text-center border">
        <div
          className="card-header"
          style={
            !isEmpty(this.state.dataDetail.foto_sampul)
              ? {
                  backgroundImage: `url(${
                    urlSosmed + this.props.dataUser.group_code
                  }/cover/${this.state.dataDetail.foto_sampul})`,
                  height: "200px",
                  backgroundPosition: "center",
                  objectFit: "cover",
                }
              : null
          }
        ></div>
        <div className="user-image">
          <div className="avatar">
            <Media
              body
              alt=""
              style={{
                height: "125px",
                width: "125px",
                backgroundPosition: "center",
                objectFit: "cover",
                borderWidth: "3px",
                borderStyle: "solid",
                borderColor: "#ffffff",
              }}
              src={
                this.state.dataDetail.foto_profil
                  ? `${urlSosmed + this.props.dataUser.group_code}/photo/${
                      this.state.dataDetail.foto_profil
                    }`
                  : require("../../../assets/images/user/10.jpg")
              }
              data-intro="This is Profile image"
            />
          </div>
        </div>
        <div className="info">
          <Row>
            <Col sm="6" lg="4" className="order-sm-1 order-xl-0">
              <Row>
                <Col md="6">
                  <div className="ttl-info text-left">
                    <h6>
                      <i className="fa fa-envelope"></i> Email
                    </h6>
                    <span>{this.cek(this.state.dataProfile.email)}</span>
                  </div>
                </Col>
                <Col md="6">
                  <div className="ttl-info text-left ttl-sm-mb-0">
                    <h6>
                      <i className="fa fa-calendar"></i>   Motto
                    </h6>
                    <span>{this.cek(this.state.dataDetail.motto)}</span>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm="12" lg="4" className="order-sm-0 order-xl-1">
              <div className="user-designation">
                <div className="title">
                  {this.cek(this.state.dataDetail.nama)}
                </div>
                <div className="desc mt-2">
                  {this.cek(this.state.dataProfile.username)} (
                  {this.props.dataUser.group_code})
                </div>
                {this.props.dataUser.group_code === "NI" ? (
                  <div className="desc mt-2">
                    {this.cek(this.state.dataDetail.m_struktur_jenjang_id)}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col sm="6" lg="4" className="order-sm-2 order-xl-2">
              <Row>
                <Col md="6">
                  <div className="ttl-info text-left ttl-xs-mt">
                    <h6>
                      <i className="fa fa-phone"></i>   No Telp
                    </h6>
                    <span>{this.cek(this.state.dataDetail.no_handphone)}</span>
                  </div>
                </Col>
                <Col md="6">
                  <div className="ttl-info text-left ttl-sm-mb-0">
                    <h6>
                      <i className="fa fa-location-arrow"></i>
                         Alamat
                    </h6>
                    <span>{this.cek(this.state.dataDetail.alamat)}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col sm="12" md="12" lg="12" xl="4">
              <Card className="border b-r-4">
                <CardHeader className="text-left p-3 px-2">
                  <h5>Data Sekolah</h5>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="text-center p-3 col-12 justify-center">
                    <Nav className="nav-pills nav-primary">
                      <NavItem>
                        <NavLink
                          className={
                            this.state.activeTab === "1"
                              ? "active mt-2"
                              : "mt-2"
                          }
                          onClick={() => this.toggle("1")}
                        >
                          <i className="icofont icofont-bag-alt"></i> Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.activeTab === "2"
                              ? "active mt-2"
                              : "mt-2"
                          }
                          onClick={() => this.toggle("2")}
                        >
                          <i className="icofont icofont-location-pin"></i>{" "}
                          Alamat
                        </NavLink>
                      </NavItem>
                      {this.props.dataUser.group_code === "NI" ? (
                        <>
                          <button
                            className={
                              this.state.biayaAkses == null
                                ? "btn btn-primary btn-pill ml-2 mt-2"
                                : "btn btn-warning btn-pill ml-2 mt-2"
                            }
                            onClick={() => this.setModal()}
                            style={{ float: "right" }}
                          >
                            <i className="fa fa-gear"></i>
                            Set Biaya Akses
                          </button>
                        </>
                      ) : null}
                    </Nav>

                    {/* modal set biaya akses */}
                    <Modal
                      isOpen={this.state.modal}
                      toggle={() => this.setModal()}
                      size="m"
                    >
                      <ModalHeader toggle={() => this.setModal()}>
                        Set Biaya Akses
                      </ModalHeader>
                      <ModalBody>
                        {this.state.biayaAkses != null ? (
                          <>
                            <label htmlFor="">Biaya Akses</label>
                            <input
                              type="number"
                              defaultValue={this.state.biayaAkses.item.biaya}
                              id="changeBa"
                              onChange={(e) => this.changeBiayaAkses(e)}
                              placeholder="Set Biaya Akses"
                              className="form-control mt-2"
                            />
                          </>
                        ) : (
                          <>
                            <label htmlFor="">Biaya Akses</label>
                            <input
                              type="number"
                              onChange={(e) => this.changeBiayaAkses(e)}
                              id="changeBa"
                              placeholder="Set Biaya Akses"
                              className="form-control mt-2"
                            />
                          </>
                        )}
                      </ModalBody>
                      <ModalFooter>
                        <button
                          onClick={() => this.setBiayaAkses()}
                          className="btn btn-primary"
                        >
                          Simpan
                        </button>
                      </ModalFooter>
                    </Modal>
                  </div>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <ListGroup className="profile-dashboard">
                        {!isEmpty(this.state.dataExtra)
                          ? this.state.dataExtra.map((value, key) => (
                              <ListGroupItem
                                key={key}
                                className="d-flex justify-content-between align-items-center text-dark"
                              >
                                {value.text}
                                <span className="badge badge-primary counter digits">
                                  {this.state.dataProfile[value.index] ??
                                    this.state.dataDetail[value.index]}
                                  {/* {this.state.dataProfile[value.index] !== undefined ? this.state.dataProfile[value.index] : '-' } */}
                                </span>
                              </ListGroupItem>
                            ))
                          : null}
                      </ListGroup>

                      <ListGroup>
                        <div className="container mt-3">
                          <Card className="gradient-primary o-hidden">
                            <CardBody className="b-r-4 p-4">
                              <div className="media static-top-widget">
                                <div className="align-self-center text-center">
                                  <Briefcase className="text-white i ml-2" />

                                  {/* <Star className="text-white i ml-2" /> */}
                                </div>
                                <div className="media-body">
                                  <div className="text-left">
                                    <span className="m-0 text-white">
                                      Poin Awal Siswa
                                    </span>
                                    <h4 className="mb-0 counter text-white">
                                      {this.state.datapoinawalsiswa !== null ? (
                                        <div>
                                          {this.state.datapoinawalsiswa}
                                        </div>
                                      ) : (
                                        "-"
                                      )}
                                    </h4>
                                  </div>

                                  <Briefcase className="icon-bg" />

                                  {/* <Star className="icon-bg" /> */}
                                </div>
                              </div>
                            </CardBody>
                          </Card>

                          {/* <button
                            className="btn btn-primary pull-left"
                            onClick={this.aturPoin}
                          >
                            <i className="fa fa-gear"></i>
                            Atur Poin
                          </button> */}
                          {/* <button className="btn btn-warning pull-right">
                            <i className="fa fa-gear"></i>
                            Reset Poin
                          </button> */}
                          <br />
                        </div>
                      </ListGroup>
                      {this.props.dataUser.group_code === "NI" ? (
                        <>
                          <ListGroup>
                            <div className="container">
                              <Card className="gradient-secondary o-hidden">
                                <CardBody className="b-r-4 p-4">
                                  <div className="media static-top-widget">
                                    <div className="align-self-center text-center">
                                      <DollarSign className="text-white i ml-2" />

                                      {/* <Star className="text-white i ml-2" /> */}
                                    </div>
                                    <div className="media-body">
                                      <div className="text-left">
                                        <span className="m-0 text-white">
                                          Biaya Akses Aplikasi
                                        </span>
                                        <h4 className="mb-0 counter text-white">
                                          {this.state.biayaAkses !== null ? (
                                            <div>
                                              Rp.{" "}
                                              {formatRupiah(
                                                this.state.biayaAkses.item.biaya
                                              )}
                                            </div>
                                          ) : (
                                            "-"
                                          )}
                                        </h4>
                                      </div>

                                      <DollarSign className="icon-bg" />

                                      {/* <Star className="icon-bg" /> */}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>

                              <br />
                            </div>
                          </ListGroup>
                        </>
                      ) : null}
                    </TabPane>
                    <TabPane tabId="2">
                      <ListGroup className="profile-dashboard">
                        {!isEmpty(this.state.dataAddress)
                          ? this.state.dataAddress.map((value, key) => (
                              <ListGroupItem
                                key={key}
                                className="d-flex justify-content-between align-items-center"
                              >
                                {value.text}
                                <span className="badge badge-primary counter digits">
                                  {this.state.dataDetail[value.index] !==
                                  undefined
                                    ? this.state.dataDetail[value.index]
                                    : "-"}
                                </span>
                              </ListGroupItem>
                            ))
                          : null}
                      </ListGroup>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            {!isEmpty(this.state.dataDetail) &&
            !isEmpty(this.state.dataProfile) ? (
              <>
                {this.props.dataUser.group_code === "NI" ? (
                  <Col sm="12" md="12" lg="12" xl="8">
                    <DataAkademikInstitusi
                      dataDetail={this.state.dataDetail}
                      dataProfile={this.state.dataProfile}
                    />
                  </Col>
                ) : null}
                {this.props.dataUser.group_code === "GU" ? (
                  <Col sm="12" md="12" lg="12" xl="8">
                    <DataAkademikGuru
                      dataDetail={this.state.dataDetail}
                      dataProfile={this.state.dataProfile}
                    />
                  </Col>
                ) : null}
                {this.props.dataUser.group_code === "SI" ? (
                  <Col sm="12" md="12" lg="12" xl="8">
                    <DataAkademikSiswa
                      dataDetail={this.state.dataDetail}
                      dataProfile={this.state.dataProfile}
                    />
                  </Col>
                ) : null}
                {this.props.dataUser.group_code === "OW" ? (
                  <Col sm="12" md="12" lg="12" xl="8">
                    <DataAkademikOrtu
                      dataDetail={this.state.dataDetail}
                      dataProfile={this.state.dataProfile}
                    />
                  </Col>
                ) : null}
              </>
            ) : null}
          </Row>
        </div>
      </Card>
    ) : null;
  }
}

export default ProfileCard;
