import React from "react";
import axios from "axios";
import formatRupiah from "../utils/formatRupiah";
import "./formPembayaran.css";
import { isEmpty } from "lodash-es";
import SweetAlert from "sweetalert2";
import imgEmpty from "./undraw_no_data_re_kwbl.svg";

const apiJa = process.env.REACT_APP_API;
const apiScoin = "http://scoin.sm/api/v2/";

class FormPembayaran extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: false,
      data: [],
      pecahan: [],
      jmlh100: 0,
      jmlh1000: 0,
      jmlh10000: 0,
      jmlh100000: 0,
      totalBayar: 0,
      qtyR: 0,
      qtyRi: 0,
      qtyP: 0,
      qtyRa: 0,
      token: "",
      message: null,
    };
  }
  componentDidMount() {
    this.getData();
  }

  getData() {
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };
    axios.get(apiJa + "mapel/check-all", config).then(function (res) {
      if (!isEmpty(res.data.data)) {
        self.setState(
          {
            data: res.data.data,
            pecahan: res.data.data.pecahan_royalti_user,
            message: res.data.message,
          },
          function () {
            // this.getToken();
            // console.log("ini message", this.state.message);
          }
        );
      } else {
        self.setState({
          message: res.data.message,
        });
      }
    });
  }
  totalAll() {
    var totalAll =
      this.state.jmlh100 +
      this.state.jmlh1000 +
      this.state.jmlh10000 +
      this.state.jmlh100000;
    this.setState({
      totalBayar: totalAll,
    });
  }

  changeR = (e) => {
    var total = e.target.value * 100;
    if (e.target.value > this.state.pecahan["100"]) {
      document.getElementById("qtyR").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Pecahan anda tidak cukup !",
      });
      this.setState(
        {
          jmlh100: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value < 0) {
      document.getElementById("qtyR").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Jumlah Dibayarkan Tidak Boleh Kurang Dari 0 !",
      });
      this.setState(
        {
          jmlh100: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value == 0) {
      this.setState(
        {
          jmlh100: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else {
      this.setState(
        {
          jmlh100: total,
          qtyR: e.target.value,
        },
        function () {
          this.totalAll();
        }
      );
    }
  };

  changeRi = (e) => {
    var total = e.target.value * 1000;
    if (e.target.value > this.state.pecahan["1000"]) {
      document.getElementById("qtyRi").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Pecahan anda Tidak Cukup!",
      });
      this.setState(
        {
          jmlh1000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value < 0) {
      document.getElementById("qtyRi").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Jumlah Dibayarkan Tidak Boleh Kurang Dari 0 !",
      });
      this.setState(
        {
          jmlh1000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value == 0) {
      this.setState(
        {
          jmlh1000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else {
      this.setState(
        {
          jmlh1000: total,
          qtyRi: e.target.value,
        },
        function () {
          this.totalAll();
        }
      );
    }
  };

  changeP = (e) => {
    var total = e.target.value * 10000;
    if (e.target.value > this.state.pecahan["10000"]) {
      document.getElementById("qtyP").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Pecahan anda tidak cukup !",
      });
      this.setState(
        {
          jmlh10000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value < 0) {
      document.getElementById("qtyP").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Jumlah Dibayarkan Tidak Boleh Kurang Dari 0 !",
      });

      this.setState(
        {
          jmlh10000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value == 0) {
      this.setState(
        {
          jmlh10000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else {
      this.setState(
        {
          jmlh10000: total,
          qtyP: e.target.value,
        },
        function () {
          this.totalAll();
        }
      );
    }
  };

  changeRa = (e) => {
    var total = e.target.value * 100000;
    if (e.target.value > this.state.pecahan["100000"]) {
      document.getElementById("qtyRa").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Pecahan Anda Tidak Cukup!",
      });
      this.setState(
        {
          jmlh100000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value < 0) {
      document.getElementById("qtyRa").value = 0;
      SweetAlert.fire({
        icon: "error",
        title: "Oops...",
        text: "Jumlah Dibayarkan Tidak Boleh Kurang Dari 0 !",
      });
      this.setState(
        {
          jmlh100000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else if (e.target.value == 0) {
      this.setState(
        {
          jmlh100000: 0,
        },
        function () {
          this.totalAll();
        }
      );
    } else {
      this.setState(
        {
          jmlh100000: total,
          qtyRa: e.target.value,
        },
        function () {
          this.totalAll();
        }
      );
    }
  };
  clickBayar = () => {
    SweetAlert.fire({
      title: "Apakah anda yakin?",
      text: "Anda akan melakukan pembayaran!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, Lanjutkan!",
    }).then((result) => {
      if (result.isConfirmed) {
        // SweetAlert.fire("Deleted!", "Your file has been deleted.", "success");
        if (this.state.totalBayar != this.state.data.total) {
          SweetAlert.fire({
            icon: "error",
            title: "Oops...",
            text: "Total yang dibayarkan tidak sesuai !",
          });
        } else {
          let self = this;
          let requestData = {
            type: "bayar",
            konten_id: this.state.data.konten_id,
            total_pembayaran: this.state.data.total,
            transaksi_id: this.state.data.transaksi_id,
            pecahan: {
              100: this.state.qtyR,
              1000: this.state.qtyRi,
              10000: this.state.qtyP,
              100000: this.state.qtyRa,
            },
          };
          let jwtToken = localStorage.getItem("jwtToken");
          let config = {
            headers: {
              Authorization: "Bearer " + jwtToken,
            },
          };
          axios
            .post(apiJa + "mapel/bayar-akses", requestData, config)
            .then(function (res) {
              SweetAlert.fire(
                "Berhasil!",
                "Berhasil Melakukan Pembayaran",
                "success"
              ).then(function () {
                window.location.reload();
              });
            });
        }
      }
    });
  };

  render() {
    console.log("ini message", this.state.message);
    const style = {
      width: "100%",
      height: "100vh",
      backgroundColor: "rgba(0,0,0,0.8)",
      zIndex: "100",
      position: "fixed",
    };
    const none = {
      display: "none",
    };

    const headerPayment = {
      textAlign: "center",
      fontWeight: "bold",
      backgroundColor: "#10449C",
      height: "60px",
      borderRadius: "10px",
      top: "-20px",
      left: "30px",
      position: "relative",
    };
    const pembayaran = {
      color: "white",
      padding: "5px",
    };
    const contentBox = {
      textAlign: "left",
    };

    return (
      <>
        <div className="payment-form" style={style}>
          <center>
            <div className="box-payment">
              <div style={headerPayment} className="box-header">
                <div style={pembayaran}>
                  <h4 style={{ marginTop: "10px", fontWeight: "bold" }}>
                    PEMBAYARAN
                  </h4>
                </div>
              </div>
              <div>
                {!isEmpty(this.state.data) ? (
                  <>
                    <div className="container"></div>
                    <div className="row box-content " syle={contentBox}>
                      <div className="col-sm-7">
                        <div className="row">
                          <div className="col">
                            <h6>Pecahan</h6>
                          </div>
                          <div className="col">
                            <h6>Dimiliki</h6>
                          </div>
                          <div className="col">
                            <h6>Qty</h6>
                          </div>
                          <div className="col">
                            <h6>Total</h6>
                          </div>
                        </div>
                        <hr />
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              100
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue={this.state.pecahan["100"]}
                              readOnly
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue="0"
                              className="form-control"
                              id="qtyR"
                              min="0"
                              onChange={(e) => this.changeR(e)}
                            />
                          </div>
                          <div className="col">
                            <h6 className="mt-2">
                              Rp. {formatRupiah(this.state.jmlh100)}
                            </h6>
                          </div>
                        </div>

                        <hr />
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              1k
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue={this.state.pecahan["1000"]}
                              readOnly
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue="0"
                              className="form-control"
                              id="qtyRi"
                              min="0"
                              onChange={(e) => this.changeRi(e)}
                            />
                          </div>
                          <div className="col">
                            <h6 className="mt-2">
                              Rp. {formatRupiah(this.state.jmlh1000)}
                            </h6>
                          </div>
                        </div>
                        <hr />
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              10k
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue={this.state.pecahan["10000"]}
                              readOnly
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue="0"
                              id="qtyP"
                              min="0"
                              onChange={(e) => this.changeP(e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <h6 className="mt-2">
                              Rp. {formatRupiah(this.state.jmlh10000)}
                            </h6>
                          </div>
                        </div>
                        <hr />
                        <div className="row" style={{ marginTop: "30px" }}>
                          <div className="col">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              100k
                            </h6>
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue={this.state.pecahan["100000"]}
                              readOnly
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <input
                              type="number"
                              defaultValue="0"
                              min="0"
                              id="qtyRa"
                              onChange={(e) => this.changeRa(e)}
                              className="form-control"
                            />
                          </div>
                          <div className="col">
                            <h6 className="mt-2">
                              Rp. {formatRupiah(this.state.jmlh100000)}
                            </h6>
                          </div>
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-9">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Total
                            </h6>
                          </div>
                          <div className="col-sm-3">
                            <h6
                              style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                              }}
                            >
                              Rp. {formatRupiah(this.state.totalBayar)}
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-5">
                        <div className="ml-2">
                          <h5 style={{ fontWeight: "bold" }}>
                            Detail Pembayaran
                          </h5>
                          <div style={{ marginTop: "40px" }}>
                            <h6 style={{ marginTop: "40px" }}>
                              <div className="row">
                                <div className="col">Biaya Akses :</div>
                                <div
                                  className="col float-right"
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  Rp.{" "}
                                  {this.state.data.biaya_total < 1
                                    ? " 0"
                                    : formatRupiah(this.state.data.biaya_total)}
                                </div>
                              </div>
                            </h6>

                            <h6 style={{ marginTop: "40px" }}>
                              <div className="row">
                                <div className="col">Komisi :</div>
                                <div
                                  className="col float-right"
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  Rp.{" "}
                                  {this.state.data.komisi < 1
                                    ? " 0"
                                    : formatRupiah(this.state.data.komisi)}
                                </div>
                              </div>
                            </h6>

                            <h6 style={{ marginTop: "40px" }}>
                              <div className="row">
                                <div className="col">Biaya Admin :</div>
                                <div
                                  className="col float-right"
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  Rp.{" "}
                                  {formatRupiah(this.state.data.biaya_admin)}
                                </div>
                              </div>
                            </h6>

                            <h6 style={{ marginTop: "40px" }}>
                              <div className="row">
                                <div className="col">Total Bayar :</div>
                                <div
                                  className="col float-right"
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "right",
                                  }}
                                >
                                  Rp. {formatRupiah(this.state.data.total)}
                                </div>
                              </div>
                            </h6>

                            <button
                              className="btn btn-danger form-control"
                              style={{ marginTop: "40px" }}
                              onClick={() => this.clickBayar()}
                            >
                              Bayar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="box-empty">
                      <center>
                        <img className="img-empty" src={imgEmpty} alt="" />
                        <h5 className="mt-4">
                          {this.state.message ==
                          "Anda belum memiliki rekening scoin" ? (
                            <>
                              Anda Belum Punya Rekening,{" "}
                              <a href="http://scoin.sm">
                                Klik Disini Untuk Membuat Rekening
                              </a>
                            </>
                          ) : this.state.message == "Jurnal Kosong" ? (
                            <>
                              <label className="oops">Oops!</label> Jurnal
                              Kosong
                            </>
                          ) : (
                            <>
                              <label className="oops">Oops!</label> User Guru
                              Tidak Punya Rekening
                            </>
                          )}
                        </h5>
                      </center>
                    </div>
                  </>
                )}
              </div>
            </div>
          </center>
        </div>
      </>
    );
  }
}

export default FormPembayaran;
