import React, {Fragment} from 'react';
import axios from "axios";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import SMDataTable from "../../common/SMDataTable/SMDataTable";

const api = process.env.REACT_APP_UM_API;

class DataSarpras extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
      dataRuang: [],
      dataBangunan: [],
      dataLahan: []
    }
    this.toggle = this.toggle.bind(this);
    this.getDataLahan = this.getDataLahan.bind(this);
    this.getDataBangunan = this.getDataBangunan.bind(this);
    this.getDataRuang = this.getDataRuang.bind(this);
  }

  componentDidMount() {
    this.getDataLahan()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getDataLahan();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
    switch (tab) {
      case '1' :
        this.getDataLahan()
        break
      case '2' :
        this.getDataBangunan()
        break
      case '3' :
        this.getDataRuang()
        break;
      default:
        this.getDataLahan()
        break
    }
  }

  getDataLahan() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.props.npsn;
    requestData += '&paging=false';

    axios.get(api + 'sarpras/sarpras-lahan' + requestData)
      .then(function (res) {
        self.setState({
          dataLahan: res.data
        })
      })
  }

  getDataBangunan() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.props.npsn;
    requestData += '&paging=false';

    axios.get(api + 'sarpras/sarpras-bangunan' + requestData)
      .then(function (res) {
        self.setState({
          dataBangunan: res.data
        })
      })
  }

  getDataRuang() {
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.props.npsn;
    requestData += '&paging=false';

    axios.get(api + 'sarpras' + requestData)
      .then(function (res) {
        self.setState({
          dataRuang: res.data
        })
      })
  }

  render() {
    return (
      <Card>
        <CardBody className="px-2 py-4">
          <Nav className="border-tab-primary" tabs>
            <NavItem>
              <NavLink href="#javascript" className={(this.state.activeTab === '1' ? 'active' : '')}
                        onClick={() => this.toggle('1')}>
                <i className="icofont icofont-ui-home"/> Lahan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#javascript" className={(this.state.activeTab === '2' ? 'active' : '')}
                        onClick={() => this.toggle('2')}>
                <i className="icofont icofont-building-alt"/> Bangunan
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#javascript" className={(this.state.activeTab === '3' ? 'active' : '')}
                        onClick={() => this.toggle('3')}>
                <i className="icofont icofont-bank-alt"/> Ruang
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <SMDataTable
              title="Table Lahan"
              data={this.state.dataLahan}
              columns={[
                {
                  name: 'Nama',
                  selector: 'name',
                  sortable: true,
                  cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
                },
                {
                  name: 'Luas Terbuka',
                  selector: 'luas_terbuka',
                  center: true
                },
                {
                  name: 'Luas Terbangun',
                  selector: 'luas_terbangun',
                  center: true
                },
                {
                  name: 'Kesesuaian',
                  selector: 'kesesuaian',
                  center: true
                },
                {
                  name: 'Keterangan',
                  selector: 'keterangan'
                }
              ]} />
            </TabPane>
            <TabPane tabId="2">
              <SMDataTable
                title="Table Bangunan"
                data={this.state.dataBangunan}
                columns={[
                  {
                    name: 'Nama',
                    selector: 'name',
                    sortable: true,
                    cell: (row) => (<div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>)
                  },
                  {
                    name: 'Luas Lantai 1',
                    selector: 'luas_lantai1',
                    center: true
                  },,
                  {
                    name: 'Luas Lantai 2',
                    selector: 'luas_lantai2',
                    center: true
                  },,
                  {
                    name: 'Luas Lantai 3',
                    selector: 'luas_lantai3',
                    center: true
                  },,
                  {
                    name: 'Luas Lantai 4',
                    selector: 'luas_lantai4',
                    center: true
                  },,
                  {
                    name: 'Luas Lantai 5',
                    selector: 'luas_lantai5',
                    center: true
                  },
                  {
                    name: 'Kesesuaian',
                    selector: 'kesesuaian',
                    center: true
                  },
                  {
                    name: 'Keterangan',
                    selector: 'keterangan'
                  }
                ]} />
            </TabPane>
            <TabPane tabId="3">
              <SMDataTable
                title="Table Ruang"
                data={this.state.dataRuang}
                columns={[
                  {
                    name: 'Nama',
                    selector: 'nama',
                    sortable: true,
                    cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
                  },
                  {
                    name: 'Lebar',
                    selector: 'lebar',
                    center: true
                  },
                  {
                    name: 'Panjang',
                    selector: 'panjang',
                    center: true
                  },
                  {
                    name: 'Kondisi Pencahayaan',
                    selector: 'kondisi_pencahayaan',
                    center: true
                  },
                  {
                    name: 'Kondisi Udara',
                    selector: 'kondisi_udara',
                    center: true
                  },
                  {
                    name: 'Kondisi Bangunan',
                    selector: 'kondisi_bangunan',
                    center: true
                  },
                  {
                    name: 'Kesesuaian Ruang',
                    selector: 'kesesuaian_ruang',
                    center: true
                  },
                  {
                    name: 'Kesesuaian Jenis Barang',
                    selector: 'kesesuaian_jenis_barang',
                    center: true
                  },
                  {
                    name: 'Kesesuaian Rasio',
                    selector: 'kesesuaian_rasio',
                    center: true
                  },
                  {
                    name: 'Jenis Ruang',
                    selector: 'jenis_ruang',
                    center: true
                  },
                  {
                    name: 'Indeks Ruang',
                    selector: 'indeks_jenis_ruang',
                    center: true
                  }
                ]} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    );
  }
}

export default DataSarpras;
