import React, {Fragment} from "react";
import Breadcrumb from '../common/breadcrumb/breadcrumb';
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { connect } from 'react-redux';
import { isEmpty } from "lodash";
import DataTopikGuru from "./component/DataTopikGuru";
import DataTopikGeneral from "./component/DataTopikGeneral";

class RealisasiTopikPembelajaran extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : {}
    }
  }

  componentDidMount() {

  }
  
  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Jurnal Akademik" title="Realisasi Topik Pembelajaran" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {!isEmpty(this.state.user) ? (
                <>
                  {this.state.user.group_code === "GU" ? (
                    <DataTopikGuru 
                      typePage={"realisasi_topik"} 
                      dataUser={this.state.user}
                    />
                  ) : null} 
                  {this.state.user.group_code === "SI" ? (
                    <DataTopikGeneral 
                      typePage={"realisasi_topik"} 
                      dataUser={this.state.user}
                    />
                  ) : null} 
                  {this.state.user.group_code === "OW" ? (
                    <DataTopikGeneral 
                      typePage={"realisasi_topik"} 
                      dataUser={this.state.user}
                    />
                  ) : null} 
                  {this.state.user.group_code === "NI" ? (
                    <DataTopikGuru 
                      typePage={"realisasi_topik"} 
                      dataUser={this.state.user}
                    />
                  ) : null} 
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps, null)(RealisasiTopikPembelajaran);