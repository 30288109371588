import React from "react";
import { Form, Label, FormGroup, Input } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2";
import { isEmpty } from "lodash";
import CKEditors from "react-ckeditor-component";

const apiJa = process.env.REACT_APP_API;

export default class FormPoinTataTertib extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      poin: 0,
      sangsi: null,
      loading: true,
      id: null,
    };

    this.update = this.update.bind(this);
    this.inputSangsi = this.inputSangsi.bind(this);
    this.inputPoin = this.inputPoin.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData(this.props.detailPoinTatatertib);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData(nextProps.detailPoinTatatertib);
  }

  fetchData(data) {
    if (!isEmpty(data)) {
      var poinPelanggaran = data;
      this.setState({
        poin: poinPelanggaran.poin,
        sangsi: poinPelanggaran.sangsi ?? null,
        id: poinPelanggaran.id,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  inputPoin(e) {
    this.setState({ poin: e.target.value });
  }

  inputSangsi(e) {
    this.setState({ sangsi: e.target.value });
  }

  update(e) {
    e.preventDefault();
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    };

    let requestData = {
      id: this.state.id,
      sangsi: this.state.sangsi,
      poin: this.state.poin,
    };

    axios
      .post(
        apiJa +
          `tata-tertib/update-poin-tata-tertib`,
        requestData,
        config
      )
      .then((res) => {
        SweetAlert.fire({
          title: "Berhasil",
          text: "Berhasil Menambahkan Poin Pelanggaran",
          icon: "success",
        });
        self.props.closeModal(false);
      })
      .catch((err) => {
        if (err.response) {
          SweetAlert.fire({
            title: "Error",
            text: "Terjadi Kesalahan",
            icon: "error",
          });
        }
      });
  }

  render() {
    return this.state.loading === false ? (
      <Form onSubmit={this.update}>
        <FormGroup>
          <Label>Sangsi</Label>
          <textarea
            required
            className="form-control"
            defaultValue={this.state.sangsi}
            placeholder={"Sangsi"}
            onChange={this.inputSangsi}
          ></textarea>
        </FormGroup>
        <FormGroup>
          <Label>Poin</Label>
          <input
            required
            type="number"
            className="form-control"
            value={this.state.poin}
            placeholder="Poin"
            onChange={this.inputPoin}
          />
        </FormGroup>
        <hr />
        <Input
          className={"btn btn-warning"}
          type="submit"
          value={"Edit"}
        />
      </Form>
    ) : (
      "loading"
    );
  }
}
