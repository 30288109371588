import React, {Fragment} from 'react';
import Link from 'react-dom';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ListGroup
} from 'reactstrap';
import axios from 'axios';
import { isEmpty } from 'lodash'; 
import Filter from '../../common/filter/Filter'; 
import FormJurnal from './form/FormJurnal'; 
import FormTopik from './form/FormTopik'; 
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import { tglId, waktu } from "./../../../services/timeDate";
import { Grid, List, Calendar } from "react-feather";
import SMGrid from "./../../common/SMGrid/SMGrid";
import SMCalendar from "./../../common/SMCalendar/SMCalendar";
import SweetAlert from "sweetalert2";

const apiJa = process.env.REACT_APP_API;
const absenLabel = ['Belum diabsen', 'Hadir', 'Sakit', 'Izin', 'Alpha'];

export default class DataTopikGuru extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countCard: 9,
      activeTab: '1',
      id : null,
      typeDetail : null,
      topik : null,
      //for Filter Selected
      semesterSelect : null,
      tahunAjaranSelect : null,
      kelasSelect : null,
      // for filter Loading
      loadingTahunAjaran : false,
      loadingKelas : false,
      loadingSemester : false,
      dataTopik : [],
      detailTopikModal : false,
      // grid mode      
      dataInstitusi : null,
      showGridMode : true,
    }
    this.toggle = this.toggle.bind(this)
    //filter Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.getTopik = this.getTopik.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.showTopik = this.showTopik.bind(this);
    this.detailTopikModal = this.detailTopikModal.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran : false })
    this.setProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setProfile();;
  }
  
  setProfile() {
    let data = null
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;
    else 
      data = this.props.dataUser.user_institusi;

    this.setState({ dataInstitusi : data }, function() {
      this.getTopik(true);
    });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab)
    this.setState({
      activeTab: tab,
      dataTopik: []
    }, function() {
      switch (tab) {
        case '1' :
          this.getTopik(true);
          break;
        case '2' :
          this.getTopik(false);
          break;
        case '3' :
          this.getTopik(false);
          break;
      }
    })
  }

  filterTahunAjaran(e) {
    if (!isEmpty(e) && e !== this.state.tahunAjaranSelect) {
      this.setState({
        tahunAjaranSelect: e,
      });
    } else {
      this.setState({
        tahunAjaranSelect: null,
      });
    }
  }

  filterKelas(e) {
    if (!isEmpty(e) && e !== this.state.kelasSelect) {
      this.setState({
        kelasSelect: e,
      });
    } else {
      this.setState({
        kelasSelect: null,
      });
    }
  }

  filterSemester(e) {
    if (!isEmpty(e) && e !== this.state.semesterSelect) {
      this.setState({
        semesterSelect: e,
      });
    } else {
      this.setState({
        semesterSelect: null,
      });
    }
  }

  setFilter(e) {
    e.preventDefault();
    let paginate = (this.state.activeTab === '1' ? true : false); 
    this.getTopik(paginate);
  }

  setResetFilter(e) {
    e.preventDefault();
    let paginate = (this.state.activeTab === '1' ? true : false); 
    this.setState({
      tahunAjaranSelect : null,
      kelasSelect : null,
      semesterSelect : null,
    }, function(){
      this.getTopik(paginate);
    });
  }

  getTopik(paginate) {
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken");
    let config = {
      headers: { Authorization: "Bearer " + jwtToken },
    };
    
    (paginate === true ? this.setState({ showGridMode : true }) : this.setState({ showGridMode : false }));

    let requestData = [];

    requestData = {
      tahun_ajaran : (!isEmpty(self.state.tahunAjaranSelect) ? self.state.tahunAjaranSelect : ""),
      kelas : (!isEmpty(self.state.kelasSelect) ? self.state.kelasSelect : ""),
      semester : (!isEmpty(self.state.semesterSelect) ? self.state.semesterSelect : ""),
      group_code : this.props.dataUser.group_code,
      type_page : this.props.typePage,
      paginate : paginate,
    }

    if (paginate) {
      self.dt.fetchEntities(requestData);
    } else {
      axios.post(apiJa + 'jurnal-akademik/get-topik', requestData, config)
        .then(function (res) {
          if (res.data.code === 107) 
            SweetAlert.fire({ title: "Error", text: res.data.message, icon: "error" });

          if (!isEmpty(res.data?.data?.items)) {
            let data = res.data.data.items;
            data.forEach((topik, index) => { topik.num = index + 1; });
            self.setState({ dataTopik : data });
          } 
        })
    }
  }

  detailTopikModal(id, status, show) {
    let self = this;
    if (show === true) {
      let jwtToken = localStorage.getItem("jwtToken");
      let config = {
        headers: { Authorization: "Bearer " + jwtToken },
      };
      let type = null;
      type = [status + " AS topik"];

      let requestData = {
        id : id,
        group_code : this.props.dataUser.group_code,
        uuid : this.props.dataUser.group_code === "OW" ? this.props.dataUser.userOrangtua?.anak?.uuid : this.props.dataUser.uuid,
      };

      axios.post(apiJa + 'jurnal-akademik/detail-topik', requestData, config)
        .then(function (res) {
          self.setState({ 
            id : id,
            typeDetail : type,
            topik : res.data.data.item,
            detailTopikModal : true
          })
        })
    } else {
      self.setState({ 
        detailTopikModal : false,
        typeDetail : null,
        id : null,
        typeDetail : null
     })
    }
  }

  showTopik() {
    let self = this;
    return( 
      <>
      <Row>
        <Col sm="12" md="12" lg="10" xl="10">
          <Nav className="border-tab-primary" tabs>
            <NavItem>
              <NavLink className={(this.state.activeTab === '1' ? 'active' : '')} onClick={() => this.toggle('1')}>
                <Grid />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={(this.state.activeTab === '2' ? 'active' : '')} onClick={() => this.toggle('2')}>
                <List />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={(this.state.activeTab === '3' ? 'active' : '')} onClick={() => this.toggle('3')}>
                <Calendar/>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col sm="12" md="12" lg="2" xl="2">
          {this.state.showGridMode === true ? (
            <>
              <span className="pull-right mr-4">Mode Grid</span>
              <div className="grid-options d-inline-block flex pull-right mt-3 mr-3">
                <ListGroup as="ul text-right">
                  <li>
                    <a
                      className="product-2-layout-view"
                      onClick={() => this.dt.gridMode(6)}
                    
                    >
                      <span className="line-grid line-grid-1 bg-primary"></span>
                      <span className="line-grid line-grid-2 bg-primary"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="product-3-layout-view"
                      onClick={() => this.dt.gridMode(4)}
                    
                    >
                      <span className="line-grid line-grid-3 bg-primary"></span>
                      <span className="line-grid line-grid-4 bg-primary"></span>
                      <span className="line-grid line-grid-5 bg-primary"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="product-4-layout-view"
                      onClick={() => this.dt.gridMode(3)}
                    
                    >
                      <span className="line-grid line-grid-6 bg-primary"></span>
                      <span className="line-grid line-grid-7 bg-primary"></span>
                      <span className="line-grid line-grid-8 bg-primary"></span>
                      <span className="line-grid line-grid-9 bg-primary"></span>
                    </a>
                  </li>
                </ListGroup>
              </div>
            </>
          ) : null} 
        </Col>
      </Row>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <SMGrid
              ref={(dt) => {
                this.dt = dt;
              }}
              typePage={this.props.typePage}
              groupCode={this.props.dataUser.group_code}
              detailJurnal={this.detailTopikModal}
              url={apiJa + 'jurnal-akademik/get-topik'}
            />
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <div className="table-responsive">
                  <SMDataTable
                    title="Table Realisasi Topik"
                    data={self.state.dataTopik}
                    columns={[
                      {
                        name: '#',
                        selector: 'num',
                        sortable: true,
                        width: '60px',
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)}>{row.num}</div>
                      },
                      {
                        name: 'Tanggal',
                        selector: 'tanggal',
                        sortable: true,
                        width: '160px',
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >{tglId(row.tanggal)}</div>
                      },
                      {
                        name: 'Hari',
                        selector: 'hari',
                        sortable: false,
                        width: '100px',
                        style: { cursor: "pointer" },
                        cell: row => 
                          <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >
                            {row.hari}
                          </div>
                      },
                      {
                        name: 'Jam Ke',
                        selector: 'jam_ke',
                        sortable: true,
                        width: '60px',
                        style: { cursor: "pointer" },
                        cell: row => 
                          <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)}>
                            <div style={{ fontWeight: "bold" }}>
                              {row.jam_ke}
                            </div>
                          </div>
                      },
                      {
                        name: 'Jam',
                        selector: 'jam',
                        sortable: true,
                        width: '160px',
                        style: { cursor: "pointer" },
                        cell: row => 
                          <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >
                            {waktu(row.jam_awal) +" - "+ waktu(row.jam_akhir)}
                          </div>
                      },
                      {
                        name: 'Mata Pelajaran',
                        selector: 'mapel + ' - ' + value.indeks_mapel',
                        sortable: true,
                        width: '200px',
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >{row.mapel +" - "+ row.indeks_mapel}</div>
                      },
                      {
                        name: 'Guru',
                        selector: 'guru.nama',
                        sortable: true,
                        width: '200px',
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >{row.guru.nama}</div>
                      },
                      {
                        name: 'Tempat',
                        selector: 'ruang',
                        width: '100px',
                        sortable: true,
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >{row.ruang}</div>
                      },
                      {
                        name: 'Rencana Topik',
                        selector: 'rencana_topik',
                        width: '200px',
                        sortable: true,
                        style: { cursor: "pointer" },
                        cell: row => 
                          this.props.typePage === "rencana_topik" ? (
                            <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)}>
                              {!isEmpty(row.rencana_topik) ? (
                                row.rencana_topik.length > 119 ? (
                                  row.rencana_topik.slice(0, 120) + "...."
                                ) : (
                                  row.rencana_topik
                                )
                              ) : (
                                "Belum terisi"
                              )}
                            </div>
                          ) : (
                            row.rencana_topik
                          )
                      },
                      {
                        name: 'Realisasi Topik',
                        selector: 'realisasi_topik',
                        width: '200px',
                        sortable: true,
                        omit: this.props.typePage === "realisasi_topik" ? false : true,
                        style: { cursor: "pointer" },
                        cell: row => 
                          this.props.typePage === "realisasi_topik" ? (
                            <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)}>
                              {!isEmpty(row.realisasi_topik) ? (
                                row.realisasi_topik.length > 119 ? (
                                  row.realisasi_topik.slice(0, 120) + "...."
                                ) : (
                                  row.realisasi_topik
                                )
                              ) : (
                                "Belum terisi"
                              )}
                            </div>
                          ) : (
                            row.realisasi_topik
                          )
                      },
                      {
                        name: 'Absen',
                        selector: null,
                        width: '200px',
                        sortable: true,
                        omit: this.props.typePage === "realisasi_topik" ? false : true,
                        style: { cursor: "pointer" },
                        cell: row => <div onClick={this.detailTopikModal.bind(this, row.id, this.props.typePage, true)} >{absenLabel[row.absen_per_orang.absen]} {!isEmpty(row.absen_per_orang.keterangan) ? " ("+row.absen_per_orang.keterangan+")" : ""} </div>
                      },
                    ]}
                  />
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            {!isEmpty(this.state.dataTopik) ? (
              <SMCalendar 
                data={this.state.dataTopik}
                detailJurnal={this.detailTopikModal}
                typePage={this.props.typePage}
                groupCode={this.props.dataUser.group_code}
              />
            ) : null} 
          </TabPane>
        </TabContent>
      </>
    )
  }

  formCreateTopik() {
    let self = this;
    let paginate = (self.state.activeTab === '1' ? true : false);
    self.setState({ detailTopikModal : false }, function() {
      self.getTopik(paginate);
    })
  }

  render() {
    return (
      <Card>
        <CardHeader className="p-4" style={{ overflow: "visible" }}>
          <h5 className="mb-2">Filter Table</h5>
          <Row>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="tahunAjaran"
                loading={this.state.loadingTahunAjaran}
                selectData={this.filterTahunAjaran}
                jenjang={this.props.dataUser.institusi.jenjang}
              />
            </Col>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="kelas"
                loading={this.state.loadingKelas}
                selectData={this.filterKelas}
                jenjang={this.props.dataUser.institusi.jenjang}
              />
            </Col>
            <Col sm="6" className="pt-2 pb-2">
              <Filter
                type="semester"
                loading={this.state.loadingSemester}
                selectData={this.filterSemester}
                jenjang={this.props.dataUser.institusi.jenjang}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              {/* <span className="text-dark mr-4">Select filter secara urut</span> */}
            </Col>
            <Col sm={6} className="text-right">
              <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
              <button onClick={this.setFilter} className="btn btn-pill btn-primary"><i className="fa fa-filter mr-2"></i> Filter</button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-4">
          {this.showTopik()}

          <Modal size="lg" isOpen={this.state.detailTopikModal} toggle={this.detailTopikModal.bind(this, false)}>
            <ModalBody className="py-0">
              <FormTopik 
                id={this.state.id}
                typePost={this.props.typePage}
                topik={this.state.topik}
                typeForm={'detail'}
                uuid={this.props.dataUser.group_code === "OW" ? this.props.dataUser.userOrangtua?.anak?.uuid : this.props.dataUser.uuid}
                groupCode={this.props.dataUser.group_code}
              />
            </ModalBody>
          </Modal>
        </CardBody>
      </Card>
    );
  }
}

