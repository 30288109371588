import React, { Fragment } from 'react';
import { 
  Row, 
  Col, 
  Card, 
  CardBody, 
  CardHeader,  
  Media,
} from 'reactstrap';
import axios from 'axios';
import SMDataTable from '../../common/SMDataTable/SMDataTable';
import { isEmpty } from 'lodash';
import Filter from '../../common/filter/Filter';
import { tglId } from "./../../../services/timeDate";

const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;
const api = process.env.REACT_APP_UM_API
class DataLainnya extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      //for Filter Selected
      tahunAjaranSelect: null,
      penugasanSelect: null,
      // for filter Loading
      loadingTahunAjaran: false,
      loadingPenugasan: false,
      // for get data
      dataLainnya: [],
      dataProfile: {},
      dataInstitusi: {},
    }
    this.getDataLainnya = this.getDataLainnya.bind(this);
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterPenugasan = this.filterPenugasan.bind(this);
    this.loadingFilter = this.loadingFilter.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setProfile = this.setProfile.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
  }
  componentDidMount() {
    this.setProfile();
    this.getDataLainnya()
  }
  
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getDataLainnya();
  }

  setProfile() {
    let data = null
    data = this.props.dataUser.user_institusi;
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;

    this.setState({ dataInstitusi : data });
  }

  getDataLainnya(){
    let self = this;
    let requestData = '';
    requestData += '?npsn='+self.props.dataUser.npsn;
    requestData += '&paging='+false;
    requestData += '&tahun_ajaran='+(isEmpty(self.state.tahunAjaranSelect) ? '' : self.state.tahunAjaranSelect);
    requestData += '&penugasan='+(isEmpty(self.state.penugasanSelect) ? '' : self.state.penugasanSelect);

    axios.get(api + `ja/penugasan-lainnya`+requestData)
    .then(function (res) {(
      self.setState({dataLainnya: res.data})
    )})
  }

  filterTahunAjaran(e) {
    if (!isEmpty(e) && e !== this.state.tahunAjaranSelect) {
      this.setState({
        tahunAjaranSelect: e,
      });
    } else {
      this.setState({
        tahunAjaranSelect: null,
      });
    }
  }

  filterPenugasan(e) {
    if (!isEmpty(e) && e !== this.state.penugasanSelect) {
      this.setState({
        penugasanSelect: e,
      })
    } else {
      this.setState({
        penugasanSelect: null,
      });
    }
  }
  
  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran: status[0],
      loadingPenugasan: status[1],
    })
  }

  setFilter(e) {
    e.preventDefault();
    this.getDataLainnya();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.loadingFilter([true, true])
    this.setState({
      tahunAjaranSelect: null,
      penugasanSelect: null,
    }, function () {
      this.getDataLainnya()
      this.loadingFilter([false, false])
    })
  }

  render() {
    return (
      <Card>
        <CardHeader style={{ overflow: "visible" }}>
          <h5 className="mb-2">Filter Table Table</h5>
          {!isEmpty(this.state.dataInstitusi) ? (
            <Row>
              <Col sm="6" className="pt-2 pb-2">
                <Filter
                  type="tahunAjaran"
                  loading={this.state.loadingTahunAjaran}
                  selectData={this.filterTahunAjaran}
                  jenjang={this.state.dataInstitusi.jenjang}
                />
              </Col>
              <Col sm="6" className="pt-2 pb-2">
                <Filter
                  type="penugasan"
                  loading={this.state.loadingPenugasan}
                  selectData={this.filterPenugasan}
                  jenjang={this.state.dataInstitusi.jenjang}
                  requestData={{
                    typePenugasan: false,
                  }}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col sm={6}>
              <span className="text-dark mr-4">Select filter secara urut</span>
            </Col>
            <Col sm={6} className="text-right">
              <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
              <button onClick={this.setFilter} className="btn btn-pill btn-primary"><i className="fa fa-filter mr-2"></i> Filter</button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-2">
          <SMDataTable
            title="Table Lainnya"
            data={this.state.dataLainnya}
            columns={[
              {
                name: 'Tahun Ajaran',
                selector: 'tahun_ajaran',
                sortable: false,
              },
              {
                name: 'Kode Pendaftaran',
                selector: 'kode_pendaftaran',
                sortable: false,
              },
              {
                name: 'Nama',
                selector: 'nama',
                sortable: true,
                cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: "bold" }}>{row.nama}</div></div>
              },
              {
                name: 'Jenis Kelamin',
                selector: 'jenis_kelamin',
                sortable: true,
                cell: row => 
                  <div data-tag="allowRowEvents">
                    <div style={{ fontWeight: "bold" }}>
                      {row.jenis_kelamin !== null 
                        ? row.jenis_kelamin == 0 ? "Perempuan" : "Laki - laki"
                        : "-"
                      }
                    </div>
                  </div>
              },
              {
                name: 'Tempat Lahir',
                selector: 'tempat_lahir',
                sortable: true,
              },
              {
                name: 'Tanggal Lahir',
                selector: 'tanggal_lahir',
                sortable: true,
                cell: row => tglId(row.tanggal_lahir)
              },
              {
                name: 'Foto Profil',
                selector: 'foto_profil',
                sortable: true,
                cell: row => 
                  <div className="py-2" data-tag="allowRowEvents">
                    {isEmpty(row.foto_profil) 
                      ? <p>-</p>
                      : <div>
                          <Media
                            body
                            alt=""
                            style={{
                              height: "60px",
                              width: "60px",
                              backgroundPosition: 'center',
                              objectFit: 'cover',
                              borderRadius: '100px',
                            }}
                            src={`${urlSosmed}LA/photo/${row.foto_profil}`}
                            data-intro="This is Profile image"
                          />
                        </div>
                    }
                  </div>
              },
              {
                name: 'Nama Penugasan',
                selector: 'nama_penugasan',
                sortable: false,
                right: false,
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
}

export default DataLainnya;