import React, { Fragment } from 'react';
import ProfileCard from './component/ProfileCard';
import { 
  Container, 
  Row, 
  Col,
} from 'reactstrap';
import * as getUser from "../../redux/actions/UserActions";
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

class Dashboard extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      user: {}
    }
  }
  componentDidMount(){
    this.getUser()
  }
  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }
  getUser(){
    let { dispatch } = this.props;
    let action = getUser.getUser()
    dispatch(action)
  }
  render() {
    return (
      <Fragment>
        <Container fluid={true}>
          <div className="user-profile">
            <Row>
              <Col sm="12" className=" mt-4">
                {!isEmpty(this.state.user) ? (
                  <ProfileCard
                    dataUser={this.state.user}
                  />
                ) : null} 
              </Col>
            </Row>
          </div>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps)(Dashboard);