import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import { MENUITEMSNI, MENUITEMSGU, MENUITEMSSI } from "./menu";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fotoProfil, nama } from "./../../../services/profile";
import {
  Button,
  Collapse,
  Media,
  Card, 
  CardBody
} from "reactstrap";
import {
  Home,
} from "react-feather";

const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;
const jenjangLabel = ['', 'TK', 'SD', 'SMP', 'SMA', 'SMK'];

const useWindowSize = (wrapper) => {
  const [size, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  if (wrapper === "horizontal_sidebar") {
    if (size[0] > 100 && size[0] < 991) {
      document.querySelector(".page-wrapper").className =
        "page-wrapper default";
      document.querySelector(".page-body-wrapper").className =
        "page-body-wrapper default";
    } else {
      document.querySelector(".page-wrapper").className =
        "page-wrapper horizontal_sidebar";
      document.querySelector(".page-body-wrapper").className =
        "page-body-wrapper horizontal_sidebar";
    }
  }

  return size;
};

const Sidebar = (props) => {
  const [margin, setMargin] = useState(0);
  let ActiveGroupCode = null;
  switch(props.groupCode) {
    case "NI":
      ActiveGroupCode = MENUITEMSNI;
      break;
    case "GU":
      ActiveGroupCode = MENUITEMSGU;
      break;
    case "SI":
      ActiveGroupCode = MENUITEMSSI;
      break;
    case "OW":
      ActiveGroupCode = MENUITEMSSI;
      break;
    default:
      ActiveGroupCode = MENUITEMSSI;
  }
  const [mainmenu, setMainMenu] = useState(ActiveGroupCode);
  const [path, setPath] = useState('/referensi/dashboard');
  const configDB = useSelector((content) => content.Customizer.customizer);
  const sidebar_type = configDB.settings.sidebar.wrapper;
  const layout_type = useState(configDB.settings.layout_type);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideLeftArrowRTL, setHideLeftArrowRTL] = useState(true);
  const [hideRightArrowRTL, setHideRightArrowRTL] = useState(true);
  // eslint-disable-next-line
  const [width, height] = useWindowSize(sidebar_type);

  useEffect(() => {
    const currentUrl = window.location.pathname;

    mainmenu.filter((items) => {
      if (items.path === currentUrl) setNavActive(items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === currentUrl) setNavActive(subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === currentUrl) {
            setNavActive(subSubItems);
            return true;
          } else {
            return false;
          }
        });
        return subItems;
      });
      return items;
    });
    // eslint-disable-next-line
  }, []);

  const setNavActive = (item) => {
    ActiveGroupCode.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
            return true;
          } else {
            return false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: ActiveGroupCode });
  };

  const toggletNavActive = (item) => {
    // if (!item.active) {
    //   ActiveGroupCode.forEach((a) => {
    //     if (ActiveGroupCode.includes(item)) a.active = false;
    //     if (!a.children) return false;
    //     a.children.forEach((b) => {
    //       if (a.children.includes(item)) {
    //         b.active = false;
    //       }
    //       if (!b.children) return false;
    //       b.children.forEach((c) => {
    //         if (b.children.includes(item)) {
    //           c.active = false;
    //         }
    //       });
    //     });
    //   });
    // }
    // item.active = !item.active;
    // setMainMenu({ mainmenu: ActiveGroupCode });

    document.querySelectorAll('.sidebar-link').forEach((el) => {
      el.classList.remove("active");
    });

    // console.log(item.path)
    
    if (item.path === "/referensi/dashboard") {
      document.querySelector(`.dashboard`).classList.add('active');
    } else {
      document.querySelector(`[href="${item.path}"]`).classList.add('active');
    }
    // console.log(document.querySelector(`[href="${item.path}"]`));


  };

  const scrollToRight = () => {
    const elmnt = document.getElementById("myDIV");
    const menuWidth = elmnt.offsetWidth;
    const temp = menuWidth + margin;
    // Checking condition for remaing margin
    if (temp < menuWidth) {
      setMargin(-temp);
      setHideRightArrow(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideLeftArrow(false);
    }
  };

  const scrollToLeft = () => {
    // If Margin is reach between screen resolution
    if (margin >= -width) {
      setMargin(0);
      setHideLeftArrow(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrow(false);
    }
  };

  const scrollToLeftRTL = () => {
    if (margin <= -width) {
      setMargin((margin) => (margin += -width));
      setHideLeftArrowRTL(true);
    } else {
      setMargin((margin) => (margin += -width));
      setHideRightArrowRTL(false);
    }
  };

  const scrollToRightRTL = () => {
    const temp = width + margin;
    // Checking condition for remaing margin
    if (temp === 0) {
      setMargin(temp);
      setHideRightArrowRTL(true);
    } else {
      setMargin((margin) => (margin += width));
      setHideRightArrowRTL(false);
      setHideLeftArrowRTL(false);
    }
  };

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);
  const [expanded1, setexpanded1] = useState(true);
  const [expanded2, setexpanded2] = useState(false);

  const Advance = () => {
    setexpanded2(false);
    if (isOpen1 === true) {
      setIsOpen1(false);
      setexpanded1(!expanded1);
    } else {
      setIsOpen1(true);
      setIsOpen2(false);
      setexpanded1(!expanded1);
    }
  };
  
  const Tables = () => {
    setexpanded1(false);
    if (isOpen2 === true) {
      setIsOpen2(false);
      setexpanded2(!expanded2);
    } else {
      setIsOpen1(false);
      setIsOpen2(true);
      setexpanded2(!expanded2);
    }
  };

  return (
    <div className="iconsidebar-menu">
      <div className="sidebar custom-scrollbar">
        <ul
          className="navs-icon default-according style-1 iconMenu-bar custom-scrollbar"
          id="accordionoc"
        >
          <li className="mt-3 mb-2 mr-3"> 
            <Card className="gradient-primary o-hidden">
              <CardBody className="b-r-4 p-4">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center">
                    <div className="avatar">
                      <Media
                        body
                        className="rounded-circle ml-2 p-0"
                        style={{
                          height: "50px",
                          width: "50px",
                          backgroundPosition: 'center',
                          objectFit: 'cover',
                          borderWidth : '3px',
                          borderStyle : 'solid',
                          borderColor: '#ffffff'
                        }}
                        src={
                          props.userData.group_code === "NI" ?
                            props.userData.user_institusi.foto_profil 
                              ? `${urlSosmed + "NI"}/photo/${props.userData.user_institusi.foto_profil}`
                              : require("../../../assets/images/user/10.jpg")
                          :
                            props.userData.institusi?.foto_profil 
                              ? `${urlSosmed + "NI"}/photo/${props.userData.institusi.foto_profil}`
                              : require("../../../assets/images/user/10.jpg")
                        }
                        alt="#"
                      />
                    </div>
                  </div>
                  <div className="media-body pl-3">
                    <div className="text-left mt-1">
                      <h6 className="text-light mb-0">
                        <b>
                          { 
                            props.groupCode === "NI" 
                              ? props.userData.user_institusi.nama 
                              : props.userData.institusi?.nama ?? "Institusi belum Aktif"
                          }
                        </b>
                      </h6>
                      {/* <p className="text-light">{jenjangLabel[props.userData.jenjang]}</p> */}
                    </div>
                    <Home className="icon-bg" />
                  </div>
                </div>
              </CardBody>
            </Card>
          </li>
          {ActiveGroupCode.map((menuItem, i) => (
            <li key={i}>
              <Button
                className="btn btn-link m-0 text-muted active"
                color="default"
                id="advance"
                // onClick={Advance}
                // aria-expanded={expanded1}
              >
                <span className="icon-menu-custom">{menuItem.icon}</span>
                <span className="ml-3"> {menuItem.title}</span>
              </Button>
              <Collapse 
                isOpen={isOpen1} 
                target="advance"
              >
                <ul className="pt-0">
                  {menuItem.children.map((childrenItem, index) => (
                    <li key={index} className="pl-navs-inline">
                      <Link
                        className={ window.location.pathname === childrenItem.path? childrenItem.classCss + " active" : childrenItem.classCss}
                        to={childrenItem.path}
                        onClick={() => toggletNavActive(childrenItem)}
                      >
                        <i className="fa fa-circle-thin mr-3"></i>{childrenItem.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Collapse>
            </li>
          ))}
          
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
