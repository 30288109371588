import React, { useEffect,useState } from "react"
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from "react-select"
import axios from "axios";

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: provided => ({ ...provided, zIndex: "999999999" })
};

const ModalAddPeserta = (props) => {

  // Initial data
  const [tahunAjaran, setTahunAjaran] = React.useState(null)
  const [semester, setSemester] = React.useState([
    { value: 'I', label: 'I' },
    { value: 'II', label: 'II' },
  ])

  // State
  const [dataKelas, setDataKelas] = useState(null);
  const [dataPeminatan, setDataPeminatan] = useState(null);
  const [dataRombel, setDataRombel] = useState(null);

  const [kelas, setKelas] = useState(null);
  const [peminatan, setpeminatan] = useState(null);
  const [rombel, setrombel] = useState(null);

  // Stuff
  const api = process.env.REACT_APP_API
  const apiUm = process.env.REACT_APP_UM_API

  useEffect(() => {
    getTahunAjaran()
    getKelas()
  }, [])

  const getTahunAjaran = () => {
    let now = new Date().getFullYear()
    let ta = [];
    let u = now + 1;
    let tahun_sekarang = now + 1;

    for (let i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ "value": u + ' / ' + i, "label": u + ' / ' + i });
    }
    setTahunAjaran(ta)
  }

  const create = () => {
    let data = {
      ujian_id : props.ujianId,
      nama_rombel : rombel,
      npsn: props.dataUser.npsn,
      kelas: kelas,
      peminatan: peminatan,
      rombel: rombel,
    }

    axios.post(api + "ujian-peserta/post-peserta", data).then(function (res) {
      props.onSuccess()
    })
  }
  const renderTitleType = () => {
    switch (props.type) {
      case "exam":
        return "Ujian"
        break;
      case "remidial":
        return "Remidial"
        break;

      default:
        break;
    }

    return null
  }
  const getKelas = () => {
    let kelas = []
    if (props.jenjang === '2') {
      kelas = [
        { value: 'I', label: 'I' },
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'V', label: 'V' },
        { value: 'VI', label: 'VI' },
      ];
    } else if (props.jenjang === '3') {
      kelas = [
        { value: 'VII', label: 'VII' },
        { value: 'VIII', label: 'VIII' },
        { value: 'IX', label: 'IX' },
      ];
    } else if (props.jenjang === '4') {
      kelas = [
        { value: 'X', label: 'X' },
        { value: 'XI', label: 'XI' },
        { value: 'XII', label: 'XII' },
      ];
    } else if (props.jenjang === '5') {
      kelas = [
        { value: 'X', label: 'X' },
        { value: 'XI', label: 'XI' },
        { value: 'XII', label: 'XII' },
        { value: 'XIII', label: 'XIII' },
      ];
    }

    setDataKelas(kelas)
  }

  const getPeminatan = () => {
    let requestData = ''
    requestData += '?m_struktur_jenjang_id=' + props.jenjang
    axios.get(apiUm + 'referensi/spektrum' + requestData)
      .then(res => {
        setDataPeminatan(res.data)
      })
  }

  const getRombel = (_peminatan) => {
    let tahunAjaran = "2020 / 2021";
    let _kelas = kelas;
    let spektrum = _peminatan;
    let npsn = props.dataUser.npsn;

    let requestData = '';
    requestData += '?tahun_ajaran=' + tahunAjaran;
    requestData += '&kelas=' + _kelas;
    requestData += '&spektrum=' + spektrum;
    requestData += '&npsn=' + npsn;

    axios.get(apiUm + 'referensi/rombel' + requestData)
      .then(res => {
        setDataRombel(res.data)
      })
  }
  
  const selectKelas = (e) => {
    setKelas(e)
    getPeminatan()
  }
  const selectPeminatan = (e) => {
    setpeminatan(e)
    getRombel(e)
  }
  const selectRombel = (e) => {
    setrombel(e)
  }
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Tambah {props.type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Kelas</Form.Label>
            <Select
              options={dataKelas}
              placeholder={"Pilih Kelas"}
              styles={colourStyles}
              onChange={(e) => selectKelas(e.value)}
              isClearable
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Peminatan</Form.Label>
            <Select
              placeholder={"Pilih Peminatan"}
              getOptionLabel={option => option.text}
              getOptionValue={option => option.id}
              options={dataPeminatan}
              onChange={(e) => selectPeminatan(e.id)}
              isClearable
            />
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Rombel</Form.Label>
            <Select
              options={dataRombel}
              placeholder={`Pilih Rombel`}
              onChange={(e) => selectRombel(e.value)}
              isClearable
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-pill btn-primary"
          onClick={() => create()}
        >Submit</button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddPeserta;


