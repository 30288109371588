import React, {Fragment} from 'react';
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import DataSarpras from './component/DataSarpras';
import { connect } from 'react-redux';

class ListRoomBuilding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user : {},
    }
  }

  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Referensi" title="Daftar Ruangan & Gedung"/>
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {!isEmpty(this.state.user) ? (
                <DataSarpras 
                  npsn={this.state.user?.npsn}
                  jenjang={this.state.user?.jenjang}
                />
              ) : ("")}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps, null)(ListRoomBuilding);
