import React, { Component } from "react";
import { isEmpty } from 'lodash';
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/id';
import {
  Col
} from 'reactstrap';

const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

export default class SMCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data : [],
      id : null,
      typePage : null,
    };
    this.detailJurnal = this.detailJurnal.bind(this);
  }

  
  detailJurnal(e) {
    this.props.detailJurnal(e.id, this.state.typePage, true);
  }

  componentDidMount() {
    this.fetchData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.fetchData();
  }

  fetchData() {
    var data = this.props.data;
    data.forEach((topik, index) => { 
      if (this.props.groupCode === "GU") {
        topik.title = topik.rombel.nama_rombel+" ("+topik.indeks_mapel +" - "+ topik.mapel +")"; 
      } else {
        topik.title = topik.guru.nama +" ("+topik.indeks_mapel +" - "+ topik.mapel +")"; 
      }

      let tgl = new Date(topik.tanggal);
      let tglDate = tgl.getDate();
      let tglMonth = tgl.getMonth();
      let tglYear = tgl.getFullYear();

      let sPukul = new Date(topik.tanggal+" "+topik.jam_awal);
      let sHrs = sPukul.getHours();
      let sMnt = sPukul.getMinutes();
      let sScnd = sPukul.getSeconds();

      let ePukul = new Date(topik.tanggal+" "+topik.jam_akhir);
      let eHrs = ePukul.getHours();
      let eMnt = ePukul.getMinutes();
      let eScnd = ePukul.getSeconds();

      topik.start = new Date(tglYear, tglMonth, tglDate, sHrs, sMnt, sScnd); 
      topik.end = new Date(tglYear, tglMonth, tglDate, eHrs, eMnt, eScnd); 
    });
    this.setState({ data : data, typePage : this.props.typePage });
  }

  render() {
    return(
      <>
        {!isEmpty(this.state.data) ? (
          <Calendar
            localizer={localizer}
            format={"DD/MM/YYYY HH:mm"} 
            scrollToTime={new Date(2005, 1, 1, 6)}
            defaultDate={new Date()}
            onSelectEvent={this.detailJurnal}
            views={allViews}
            events={this.state.data}
            eventOverlap
            dragRevertDuration={500}
            dragScroll
            droppable={true}
            showMultiDayTimes
            step={60}
            startAccessor="start"
            endAccessor="end"
          />    
        ) : (
          <Col sm="12" className="text-center">
            <p>Belum ada data sama sekali</p>
          </Col>
        )}
      </>
    )
  }
}