import React from 'react';
import { 
  Form, 
  Label, 
  FormGroup, 
  Input
} from 'reactstrap';
import axios from 'axios';
import SweetAlert from "sweetalert2";
import { isEmpty } from 'lodash';
import CKEditors from "react-ckeditor-component";

const apiJa = process.env.REACT_APP_API;

export default class FormTataTertib extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keterangan: null,
      loading : true,
    };

    this.createTataTertib = this.createTataTertib.bind(this);
    this.inputKeterangan = this.inputKeterangan.bind(this);
  }

  componentDidMount() {
    if (!isEmpty(this.props.tataTertib)) {
      var tataTertib = this.props.tataTertib;
      this.setState({
        keterangan : tataTertib,
        loading : false,
      }, function() {
        console.log(this.props.tataTertib)
      })
    } else {
      this.setState({
        loading : false,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.tataTertib)) {
      var tataTertib = nextProps.tataTertib;
      this.setState({
        keterangan : tataTertib,
        loading : false,
      })
    } else {
      this.setState({
        loading : false,
      })
    }
  }

  inputKeterangan(e) {
    this.setState({ keterangan : e.editor.getData() });
  }

  createTataTertib(e) {
    e.preventDefault();
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    var keterangan = this.state.keterangan;
    let requestData = {
      keterangan : keterangan,
    }

    axios.post(apiJa + `tata-tertib/tambah-tata-tertib`, requestData, config)
      .then(res => {
        SweetAlert.fire({
          title: "Berhasil",
          text: "Berhasil Menambahkan Tata Tertib",
          icon: "success",
        });
        self.props.closeModal(false);
      })
      .catch(err => {
        if (err.response) {
          SweetAlert.fire({
            title: "Error",
            text: "Terjadi Kesalahan",
            icon: "error",
          });
        }
      })

  }

  render() {
    return (
      this.state.loading === false ? (
        <Form onSubmit={this.createTataTertib}>
          <FormGroup>
            <Label>Tata Tertib</Label>
            <CKEditors
              activeclassName="p10"
              content={this.state.keterangan}
              events={{
                change: this.inputKeterangan,
              }}
            />
          </FormGroup>
          <hr/>
          <Input 
            className={
              !isEmpty(this.props.tataTertib)
                ? "btn btn-warning" 
                : "btn btn-primary"
            } 
            type="submit" 
            value={ 
              !isEmpty(this.props.tataTertib)
                ? "Edit" 
                : "Simpan"
            } 
          />
        </Form>
      ) : ("loading")
    );
  }
}
