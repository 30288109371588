import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SMDataTable from "../common/SMDataTable/SMDataTable";
import { isEmpty } from "lodash";
import Filter from "../common/filter/Filter";
import axios from 'axios';
import {Link} from "react-router-dom"
import ModalCreate from "./Components/ModalCreate";

const apiJa = process.env.REACT_APP_API;

class RencanaRemidialBersama extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jenjangSelect: "4",
      // For Filter Selected
      tahunAjaranSelect: null,
      semesterSelect: null,
      namaUjianSelect: null,
      // For Loading Filter
      loadingTahunAjaran: true,
      loadingSemester: true,
      loadingNamaUjian: true,
      modalCreateShow: false,
      data: [],
    };

    // Filters Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.filterNamaUjian = this.filterNamaUjian.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    // Loading Function
    this.loadingFilter = this.loadingFilter.bind(this);
    this.getUjian = this.getUjian.bind(this);

    this.modalCreateOnHide = this.modalCreateOnHide.bind(this)

    this.onSuccessAdd = this.onSuccessAdd.bind(this)
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran: false });
    this.getUjian();
  }

  getUjian() {
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken")
    let requestData = {
      type_ujian: 1,
      tahun_ajaran : this.state.tahunAjaranSelect,
      semester : this.state.semesterSelect,
      nama_ujian: this.state.namaUjianSelect,
    }
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    axios.post(apiJa + `ujian/get-ujian`, requestData, config)
      .then(function (res) {
        if (!isEmpty(res.data.data.items)) {
          self.setState({
            data : res.data.data.items,
          })
        } else {
          self.setState({
            data : [],
          })
        }
      })
  }

  filterTahunAjaran(e) {
    if (!isEmpty(e) && e !== this.state.tahunAjaranSelect) {
      this.setState({
        tahunAjaranSelect: e,
        semesterSelect: null,
        namaUjianSelect: null,
      }, function () {
        this.loadingFilter([false, false, true]);
      });
    } else {
      this.setState({
        tahunAjaranSelect: null,
        semesterSelect: null,
        namaUjianSelect: null,
      }, function () {
        this.loadingFilter([false, true, true]);
      });
    }
  }

  filterSemester(e) {
    if (!isEmpty(e) && e !== this.state.semesterSelect) {
      this.setState({
        semesterSelect: e,
        namaUjianSelect: null,
      }, function () {
        this.loadingFilter([false, false, false]);
      });
    } else {
      this.setState({
        semesterSelect: null,
        namaUjianSelect: null,
      }, function () {
        this.loadingFilter([false, false, true]);
      });
    }
  }

  filterNamaUjian(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.namaUjianSelect) {
        this.setState({
          namaUjianSelect: e,
        });
      }
    } else {
      this.setState({
        namaUjianSelect: null,
      });
    }
  }

  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran: status[0],
      loadingSemester: status[1],
      loadingNamaUjian: status[2],
    });
  }

  setFilter(e) {
    e.preventDefault();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.loadingFilter([true, true, true, true, true]);
    this.setState({
      tahunAjaranSelect: null,
      semesterSelect: null,
      namaUjianSelect: null,
    }, function () {
      this.setState({ loadingTahunAjaran: false });
    });
  }

  modalCreateOnHide = () => {
    this.setState({ modalCreateShow: !this.state.modalCreateShow })
  }

  onSuccessAdd = () => {
    this.getUjian()
    this.setState({ modalCreateShow: !this.state.modalCreateShow })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          parent="Ujian dan Remidial"
          title="Rencana Remidial Bersama"
        />
        <Container fluid={true}>
          <ModalCreate
            type="remidial"
            show={this.state.modalCreateShow}
            onHide={this.modalCreateOnHide}
            onSuccess={this.onSuccessAdd}
          />
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader style={{ overflow: "visible" }}>
                  <h5 className="mb-2">Filter Table Table Rencana Remidial Bersama</h5>
                  <span>
                    lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </span>
                  <Row>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="tahunAjaran"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="semester"
                        loading={this.state.loadingSemester}
                        selectData={this.filterSemester}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="namaUjian"
                        loading={this.state.loadingNamaUjian}
                        selectData={this.filterNamaUjian}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <span className="text-dark mr-4">Select filter secara urut</span>
                    </Col>
                    <Col sm={6} className="text-right">
                      <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
                      {this.state.loadingSemester === false ? (
                          <button onClick={this.setFilter} className="btn btn-pill btn-primary"><i className="fa fa-filter mr-2"></i> Filter</button>
                      ) : ("")}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody clasName="card-body btn-showcase">
                <button onClick={() => { this.setState({ modalCreateShow: !this.modalCreateShow }) }} className="btn btn-pill btn-primary">Buat Rencana Remidial</button>
                  <SMDataTable
                    title="Tabel Rencana Remidial Bersama"
                    data={this.state.data}
                    columns={[
                      {
                        name: "Tahun Ajaran",
                        selector: "tahun_ajaran",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Semester",
                        selector: "semester",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Nama Ujian",
                        selector: "nama",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Metode Ujian",
                        selector: "metode",
                        sortable: true,
                        center: true,
                        cell: row =>
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                              {row.jenis_kelamin !== null
                                ? row.metode == 0 ? "Offline" : "Online"
                                : "-"
                              }
                            </div>
                          </div>
                      },
                      {
                        name: "Keterangan Ujian",
                        selector: "keterangan",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Aksi",
                        selector: "id",
                        sortable: true,
                        center: true,
                        cell: row =>
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                              <Link to={{pathname: "/ujian-&-remidial/rencana-remidial-bersama/lihat-peserta", state: {ujian_id: row.id}}}>
                                <button className="btn btn-pill btn-primary">Lihat Peserta Ujian </button>
                              </Link>
                            </div>
                          </div>
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default RencanaRemidialBersama;
