import React, { Fragment,  } from 'react';
import { 
  Row, 
  Col, 
  Form, 
  Label, 
  Input,
  FormGroup,
  Modal, 
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Media,
} from 'reactstrap';
import { isEmpty } from "lodash";
import axios from 'axios';
import SweetAlert from "sweetalert2";
import { tglId, waktu } from "./../../../../services/timeDate";

const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;
const apiJa = process.env.REACT_APP_API;
const jenisKelamin = ['Laki - laki', 'Perempuan'];
const absenLabel = ['Belum diabsen', 'Hadir', 'Sakit', 'Izin', 'Alpha'];

export default class FormTopik extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topik : null,
      dataTopik : [],
      id : null,
      typePost : null,
      loadingTopik : false,
      loadingAbsen : false,
      absensiTopikModal : false,
      dataAbsensi : [],
    };

    this.createTopik = this.createTopik.bind(this);
    this.absensiTopikModal = this.absensiTopikModal.bind(this);
    this.showAbsensi = this.showAbsensi.bind(this);
    this.saveAbsensi = this.saveAbsensi.bind(this);
    this.inputTopik = this.inputTopik.bind(this);
  }

  componentDidMount() {
    this.setState({ 
      dataTopik : this.props.topik,
      topik : (this.props.typePost === "rencana_topik" ? this.props.topik.rencana_topik : this.props.topik.realisasi_topik),
      id : this.props.id,
      typePost : this.props.typePost
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    //
  }

  inputTopik(e) {
    e.preventDefault();
    this.setState({ topik : e.target.value })
  }

  createTopik(e) {
    e.preventDefault();
    let self = this;
    self.setState({ loadingTopik : true });
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    var topik = self.state.topik;
    var id = self.state.id;
    var type = self.state.typePost;
    
    if (!isEmpty(topik)) {
      let requestData = {
        topik : topik,
        id : id,
        type : type,
      }
      axios.post(apiJa + `jurnal-akademik/update-topik`, requestData, config)
        .then(function (res) {
          self.setState({ 
            loadingTopik : false,
          });
          SweetAlert.fire({
            title: "Berhasil",
            text: "Berhasil Menambahkan Topik",
            icon: "success",
          });
          self.props.formCreateTopik();
        })
        .catch(function (err) {
          self.setState({ 
            loadingTopik : false,
          });
          if (err.response?.data?.code === 107) {
            SweetAlert.fire({
              title: "Error",
              text: err.response.data.message,
              icon: "error",
            });
          }
        })

    } else {
      SweetAlert.fire({
        title: "Error",
        text: "Isi form dengan benar",
        icon: "error",
      });
    }
  }

  absensiTopikModal(id, status) {
    let self = this;
    if (status === true) {
      let jwtToken = localStorage.getItem("jwtToken")
      let config = {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      }

      let requestData = {
        jurnal_id : id,
      }

      axios.post(apiJa + `absen/get-absen`, requestData, config)
        .then(function (res) {
          if (!isEmpty(res.data.data?.items)) {
            let data = res.data.data.items;
            if (!isEmpty(data)) {
              data.forEach((absen, index) => { absen.num = index + 1; });
            } 
            self.setState({ 
              absensiTopikModal : true,
              dataAbsensi : data,
            })
          } else {
            self.setState({ 
              dataAbsensi : []
            }, function() {
              SweetAlert.fire({
                title: "Peringatan",
                text: "Belum ada siswa dari rombel tersebut yang login",
                icon: "warning",
              });
            })
          }
        })
    } else {
      self.setState({ 
        absensiTopikModal : false,
        dataAbsensi : [],
      })
    }
  }

  showAbsensi() {
    if (!isEmpty(this.state.dataAbsensi)) {
      return (
        <div className="table-responsive">
          <table className="table table-styling table-striped table-hover table-border-vertical table">
            <thead>
              <tr>
                <th>#</th>
                <th>Nama</th>
                <th>NISN</th>
                <th>Jenis Kelamin</th>
                <th>Foto</th>
                <th>Absen</th>
                <th>Keterangan</th>
              </tr>
            </thead>
            <tbody>
              {this.state.dataAbsensi.map((value, key) => (
                <tr key={key}>
                  <td>{value.siswa.num}</td>
                  <td>{value.siswa.nama}</td>
                  <td>{value.siswa.nisn}</td>
                  <td>{jenisKelamin[value.siswa.jenis_kelamin]}</td>
                  <td>
                    {!isEmpty(value.siswa.foto_profil) ? (
                      <Media
                        body
                        alt=""
                        style={{
                          height: "60px",
                          width: "60px",
                          backgroundPosition: 'center',
                          objectFit: 'cover',
                          borderRadius: '100px',
                        }}
                        src={`${urlSosmed}SI/photo/${value.siswa.foto_profil}`}
                        data-intro="This is Profile image"
                      />
                    ) : "Belum Memiliki Foto"}
                  </td>
                  <td>
                    {this.props.typeForm === "form" ? (
                      <Input
                        type="select"
                        name="absensi[]"
                        className="form-control digits"
                        data-id={value.siswa.id}
                      >
                        <option disabled>Pilih Absensi</option>
                        <option selected={value.absen === 1 ? true : false} value="1">Hadir</option>
                        <option selected={value.absen === 2 ? true : false} value="2">Sakit</option>
                        <option selected={value.absen === 3 ? true : false} value="3">Izin</option>
                        <option selected={value.absen === 4 ? true : false} value="4">Alpha</option>
                      </Input>
                    ) : (
                      <p>{absenLabel[value.absen]}</p>
                    )}
                  </td>
                  <td>
                    {this.props.typeForm === "form" ? (
                      <Input
                        className="form-control"
                        type="text"
                        name="keterangan[]"
                        placeholder="Keterangan"
                        defaultValue={value.keterangan}
                      />
                    ) : (
                      <p>{value.keterangan}</p>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }
  }

  saveAbsensi(e){
    e.preventDefault();
    let self = this;
    self.setState({ loadingAbsen : true });
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }

    var absenInput = document.getElementsByName('absensi[]');
    var keteranganInput = document.getElementsByName('keterangan[]');

    var idSiswa = [];
    var absen = [];
    var ket = [];
    var requestData = [];
    
    for (var i=0, n=absenInput.length;i<n;i++){
      absen.push(absenInput[i].value);
      idSiswa.push(absenInput[i].getAttribute('data-id'));
      ket.push(keteranganInput[i].value);
    }

    requestData = {
      absen : absen,
      keterangan : ket,
      siswa_id : idSiswa,
      jurnal_id : self.state.id,
    }
    
    axios.post(apiJa + `absen/post-absen`, requestData, config)
      .then(res => {
        this.setState({ 
          absensiTopikModal : false,
          loadingAbsen : false,
        });
        if (res.data.code === 107) {
          SweetAlert.fire({
            title : "Error",
            text : res.data.message,
            icon : "error",
          });
        } else {
          SweetAlert.fire({
            title : "Berhasil",
            text : "Berhasil Menambahkan Absen",
            icon : "success",
          });
        }
      })
      .catch(function (err) {
        
      })
  }

  render() {
    return (
      <Row>
        <Col sm="12">
          {this.state.id !== null ? (
            this.props.typeForm === "form" ? (
              <>
                <Form className="form theme-form">
                  {this.state.typePost === "realisasi_topik" ? (
                      <FormGroup className="p-2" style={{ backgroundColor: '#f4f4f4' }}>
                        <h5>Rencana Topik</h5>
                        <p>{this.state.dataTopik.rencana_topik}</p>
                      </FormGroup>
                    ) : (null)
                  }
                  <FormGroup>
                    <Label className="col-form-label">
                      {this.state.typePost === "rencana_topik" 
                        ? "Rencana Topik" 
                        : "Realisasi Topik" 
                      }
                    </Label>
                    <textarea
                      className="form-control digits"
                      type="date"
                      onChange={this.inputTopik}
                      placeholder={this.state.typePost === "rencana_topik" 
                        ? "Isi Rencana Topik" 
                        : "Isi Realisasi Topik" 
                      }
                      defaultValue={this.state.typePost === "rencana_topik" 
                        ? this.state.dataTopik.rencana_topik 
                        : this.state.dataTopik.realisasi_topik
                      }
                    >

                    </textarea>
                  </FormGroup>
                </Form>
                <Col sm="12" className="text-right px-0">
                  {this.state.typePost === "realisasi_topik" ? (
                    <button className="btn btn-info mr-2" onClick={this.absensiTopikModal.bind(this, this.state.id, true)}>
                      <i className="fa fa-users mr-2"></i>
                      Absensi
                    </button>
                  ) : null}
                  <button className="btn btn-primary" onClick={this.createTopik}>
                    <i className="fa fa-save mr-2"></i>
                    Simpan
                  </button>
                </Col> 
              </>
            ) : (
              <Row>
                <Col sm="12" md="12" lg="6" xl="6" className="p-4" style={{ backgroundColor: '#f4f4f4' }}>
                  <h5>Rencana Topik</h5>
                  <p>{!isEmpty(this.state.dataTopik.rencana_topik) ? this.state.dataTopik.rencana_topik : 'Rencana belum diisi'}</p>
                  {this.props.typePost === "realisasi_topik" ? (
                    <>
                      <hr/>
                      <h5>Realisasi Topik</h5>
                      <p>{this.state.dataTopik.realisasi_topik}</p>
                      <hr/>
                      {this.props.groupCode === "SI" || this.props.groupCode === "OW" ? ( 
                        <>
                          <h5>Absen</h5>
                          <p>
                            {absenLabel[this.state.dataTopik.absen_per_orang.absen] ?? null} 
                            {!isEmpty(this.state.dataTopik.absen_per_orang.keterangan) ? (
                              " (" + this.state.dataTopik.absen_per_orang.keterangan + ") "
                            ) : ""}
                          </p>
                        </>
                      ) :null}
                    </>
                  ) : null}
                </Col>
                <Col sm="12" md="12" lg="6" xl="6" className="p-3">
                  <h4>{this.state.dataTopik.nama_guru}</h4>
                  <p className="text-primary">{this.state.dataTopik.indeks_mapel} - {this.state.dataTopik.mapel}</p>
                  <hr/>
                  <p className="mb-0">Hari/Tanggal : <b>{this.state.dataTopik.hari}</b> / <b>{tglId(this.state.dataTopik.tanggal)}</b></p>
                  <p className="mb-0">Jam ke : <b>{this.state.dataTopik.jam_ke}</b>, Pukul : <b>{waktu(this.state.dataTopik.jam_awal)}</b> s/d <b>{waktu(this.state.dataTopik.jam_akhir)}</b></p>
                  <p className="mb-0">Rombel : <b>{this.state.dataTopik.rombel.nama_rombel}</b></p>
                  <p>Tempat : <b>{this.state.dataTopik.ruang}</b></p>
                  {this.state.typePost === "realisasi_topik" ? (
                    <button className="btn btn-info ml-2" onClick={this.absensiTopikModal.bind(this, this.state.id, true)}>Absensi</button>
                  ) : null}
                </Col>
              </Row>
            )
          ) : null}
        </Col>

        <Modal size="xl" isOpen={this.state.absensiTopikModal} toggle={this.absensiTopikModal.bind(this, null, false)}>
          <ModalHeader toggle={this.absensiTopikModal.bind(this, null, false)}>
            <p className="mb-1">Absensi Siswa Kelas : </p>
            <h5 className="mb-0"><b>2021 / 2020 - X MIPA 1</b></h5>
          </ModalHeader>
          <ModalBody className="p-0">
            {this.showAbsensi()}
          </ModalBody>
          {this.props.typeForm === "form" ? (
            <ModalFooter>
              <Button color="primary" onClick={this.saveAbsensi}>
                <i className="fa fa-save mr-2"></i>
                Simpan
              </Button>
            </ModalFooter>
          ) : null}
        </Modal>
      </Row>
    );
  }
}
