import React from "react"
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SMDataTable from "../common/SMDataTable/SMDataTable";
import ModalAddSchedule from "./Components/ModalAddSchedule";

class LihatJadwalUjian extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      modalCreateShow: false,
    }

    this.modalCreateOnHide = this.modalCreateOnHide.bind(this)
  }
  componentDidMount(){

  }

  modalCreateOnHide(){
    let self = this
    self.setState({
      modalCreateShow: !this.state.modalCreateShow
    })
  }
  render(){
    return(
      <>
      <ModalAddSchedule
        type="jadwal"
        show={this.state.modalCreateShow}
        onHide={this.modalCreateOnHide}
        onSuccess={this.onSuccessAdd}
      />
      <Breadcrumb parent="Ujian dan Remidial" title="Rencana Ujian Bersama" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5 className="mb-2">Filter Table Table Peserta Ujian Bersama</h5>
                  <span>
                    lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </span>
                </CardHeader>
                <CardBody clasName="card-body btn-showcase">
                  <button onClick={() => { this.setState({ modalCreateShow: !this.state.modalCreateShow }) }} className="btn btn-pill btn-primary">Buat Ujian Baru</button>
                  <SMDataTable
                    title="Tabel Rencana Ujian Bersama"
                    data={this.state.dataExam}
                    columns={[
                      {
                        name: "Tahun Ajaran",
                        selector: "tahun_ajaran",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Semester",
                        selector: "semester",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Nama Ujian",
                        selector: "nama",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Metode Ujian",
                        selector: "metode",
                        sortable: true,
                        center: true,
                        cell: row => 
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                              {row.jenis_kelamin !== null 
                                ? row.metode == 0 ? "Offline" : "Online"
                                : "-"
                              }
                            </div>
                          </div>
                      },
                      {
                        name: "Keterangan Ujian",
                        selector: "keterangan",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Aksi",
                        selector: "aksi",
                        sortable: true,
                        center: true,
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default LihatJadwalUjian