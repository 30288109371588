import React, { Fragment,  } from 'react';
import { 
  Row, 
  Col, 
  Form, 
  Label, 
  Input,
  FormGroup,
} from 'reactstrap';
import { isEmpty } from "lodash";
import axios from 'axios';
import SweetAlert from "sweetalert2";
import Select from "react-select";

const apiJa = process.env.REACT_APP_API;
const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
    };
  },
  menu: provided => ({ ...provided, zIndex: "999999999" })
};

export default class FormJurnal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tanggalTopik : null,
      tahunAjaran : null,
      semester : null,
      loading : false,
      dataSemester : null,
      dataTahunAjaran : null,
      tahunSekarang: new Date().getFullYear(),
    };

    this.createTopik = this.createTopik.bind(this);
    this.inputTanggalTopik = this.inputTanggalTopik.bind(this);
    this.inputTahunAjaran = this.inputTahunAjaran.bind(this);
    this.inputSemester = this.inputSemester.bind(this);
    this.getSemester = this.getSemester.bind(this);
    this.getTahunAjaran = this.getTahunAjaran.bind(this);
  }

  componentDidMount() {
    this.inputTanggalTopik();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.inputTanggalTopik();
  }

  
  inputTanggalTopik() {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    
    var inputDate = [];
    var u = 0;
    for (var i = first; i <= last; i++) {
      var date = new Date(curr.setDate(i)).getDate();
      var month = new Date(curr.setDate(i)).getMonth();
      var year = new Date(curr.setDate(i)).getFullYear();

      month = month + 1;

      if (date < 10) {
        date = "0"+date;
      }
      
      if(month < 10) {
        month = "0"+month;
      }

      inputDate[u] = year + "-" + month + "-" + date;
      u = u + 1;
    }
    
    this.setState({ tanggalTopik : inputDate }, function() {
      this.getSemester();
      this.getTahunAjaran();
    })
  }

  getTahunAjaran() {
    var ta = [];
    var u = this.state.tahunSekarang + 1;
    var tahun_sekarang = this.state.tahunSekarang + 1;

    for (var i = tahun_sekarang; i >= 2005; i--) {
      u = u - 1;
      ta.push({ "value": u + ' / ' + i, "label": u + ' / ' + i });
    }
    this.setState({ dataTahunAjaran: ta });
  }
  
  getSemester() {
    var semester = [];
    semester = [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
    ];
    this.setState({ dataSemester: semester });
  }

  inputSemester(e) {
    if (!isEmpty(e.value)) {
      this.setState({ semester : e.value })
    } else {
      this.setState({ semester : null })
    }
  }

  inputTahunAjaran(e) {
    if (!isEmpty(e.value)) {
      this.setState({ tahunAjaran : e.value })
    } else {
      this.setState({ tahunAjaran : null })
    }
  }

  createTopik(e) {
    e.preventDefault();
    let self = this;
    let jwtToken = localStorage.getItem("jwtToken")
    let config = {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
    this.setState({ loading : true });
    var tanggal = self.state.tanggalTopik;
    var tahunAjaran = self.state.tahunAjaran;
    var semester = self.state.semester;
    
    if (!isEmpty(tanggal) && !isEmpty(tahunAjaran) && !isEmpty(semester)) {

      let requestData = {
        tanggal : tanggal,
        tahun_ajaran : tahunAjaran,
        semester : semester,
        npsn : self.props.npsn,
        uuid : self.props.uuid,
        guru_id : self.props.guru_id,
      }
      axios.post(apiJa + `jurnal-akademik/tambah-topik`, requestData, config)
        .then((res) => {
          this.setState({ loading: false });
          SweetAlert.fire({
            title: "Berhasil",
            text: res.data?.message,
            icon: "success",
          });
          self.props.formCreateJurnal();
        })
        .catch((err) => {
          SweetAlert.fire({
            title: "Error",
            text: "Terjadi Kesalahan",
            icon: "error",
          });
        })

    } else {
      SweetAlert.fire({
        title: "Error",
        text: "Isi form dengan benar",
        icon: "error",
      });
    }
  }

  render() {
    return (
      <Row>
        <Col sm="12">
          <Form className="form theme-form">
            <FormGroup>
              <Label className="col-form-label">Tahun Ajaran</Label>
              <Select
                placeholder={"Pilih Tahun Ajaran"}
                options={this.state.dataTahunAjaran}
                onChange={this.inputTahunAjaran}
                isClearable
                styles={colourStyles}
              />
            </FormGroup>
            <FormGroup>
              <Label className="col-form-label">Semester</Label>
              <Select
                placeholder={"Pilih Semester"}
                options={this.state.dataSemester}
                onChange={this.inputSemester}
                isClearable
                styles={colourStyles}
              />
            </FormGroup>
          </Form>
          <Col sm="12" className="px-0 text-right">
            {this.state.loading === true ? (
              <button className="btn btn-primary" disabled={true}>
                <i className="fa fa-save mr-2"></i>
                Harap Tunggu
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.createTopik}>
                <i className="fa fa-save mr-2"></i>
                Buat Jurnal
              </button>
            )}
          </Col>
        </Col>
      </Row>
    );
  }
}
