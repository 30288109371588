import React, { Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb/breadcrumb'
import { 
  Container, 
  Row, 
  Col, 
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import DataLainnya from './component/DataLainnya';

const api = process.env.REACT_APP_UM_API
class ListOther extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user : {},
    }
  }
  componentDidMount() {

  }

  static getDerivedStateFromProps(props, state){
    if(props.UserReducer.user !== state.user){
      return ({
        user: props.UserReducer.user
      })
    }
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb parent="Referensi" title="Daftar Lainnya" />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              {!isEmpty(this.state.user) ? (
                <DataLainnya 
                  dataUser={this.state.user}
                />
              ) : ("")}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    UserReducer: state.UserReducer
  }
}

export default connect(mapStateToProps, null)(ListOther);