import React, { Fragment } from "react";
import { Row, Col, Card, CardHeader, CardBody, Media } from "reactstrap";
import axios from "axios";
import { isEmpty } from "lodash";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import { tglId } from "./../../../services/timeDate";
import FilterGeneral from "../../common/filter/FilterGeneral";

const api = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class DataStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //for Filter Selected
      rombelSelect: null,
      dataProfile: {},
      dataInstitusi: {},
      // for get data
      dataSiswa: [],
      resetFilter: false,
    };

    this.getDataSiswa = this.getDataSiswa.bind(this);

    //filters Function
    this.setFilter = this.setFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);
    this.setProfile = this.setProfile.bind(this);
  }
  componentDidMount() {
    this.setState({ loadingTahunAjaran: false });
    this.setProfile();
    this.getDataSiswa();
  }

  setProfile() {
    let data = null;
    data = this.props.dataUser.user_institusi;
    if (this.props.dataUser.group_code != "NI")
      data = this.props.dataUser.institusi;

    this.setState({ dataInstitusi: data });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getDataSiswa();
  }

  getDataSiswa() {
    let self = this;
    let requestData = "";
    requestData += "?npsn=" + self.props.dataUser.npsn;
    if (!isEmpty(self.state.rombelSelect))
      requestData += "&rombel=" + self.state.rombelSelect;
    axios.get(api + `ja/rombel-siswa` + requestData)
      .then(function (res) {
        let data = res.data;
        if (!isEmpty(data)) {
          data.forEach((row, index) => {
            row.num = index + 1;
          });
        }
        self.setState({ dataSiswa: res.data });
      });
  }

  setFilter(e) {
    e.preventDefault();
    this.getDataSiswa();
  }

  setResetFilter(e) {
    e.preventDefault();
    this.setState({resetFilter : true }, function() {
      this.setState({ resetFilter : false })
      this.getDataSiswa();
    })
  }

  render() {
    return (
      <Card>
        <CardHeader className="p-4" style={{ overflow: "visible" }}>
          <h5 className="mb-2">Filter Table Table</h5>
          {!isEmpty(this.state.dataInstitusi) && this.state.resetFilter === false ? (
            <FilterGeneral
              npsn= {this.props.dataUser.npsn}
              jenjang={this.state.dataInstitusi.jenjang}
              sendDataRombel={(e) => this.setState({ rombelSelect : e })}
              withSemester={false}
            />
          ) : null}
          <Row>
            <Col sm={6} className="text-left">
              <span>Select filter secara urut</span>
            </Col>
            <Col sm={6} className="text-right">
              <button
                onClick={this.setResetFilter}
                className="btn btn-pill btn-warning mr-2"
              >
                <i className="fa fa-refresh mr-2"></i> Reset
              </button>
              {this.state.semesterSelect !== null ? (
                <button
                  onClick={this.setFilter}
                  className="btn btn-pill btn-primary"
                >
                  <i className="fa fa-filter mr-2"></i> Filter
                </button>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="p-2">
          <SMDataTable
            title="Table Siswa"
            data={this.state.dataSiswa}
            columns={[
              {
                name: "#",
                selector: "num",
                sortable: false,
                width: "60px",
              },
              {
                name: "Tahun Ajaran",
                selector: "tahun_ajaran",
                sortable: false,
              },
              {
                name: "Kode Pendaftaran",
                selector: "kode_pendaftaran",
                sortable: false,
                width: "200px",
              },
              {
                name: "NISN",
                selector: "nisn",
                sortable: false,
                width: "160px",
              },
              {
                name: "Nama Lengkap",
                selector: "nama",
                sortable: true,
                width: "200px",
                cell: (row) => (
                  <div data-tag="allowRowEvents">
                    <div style={{ fontWeight: "bold" }}>{row.nama}</div>
                  </div>
                ),
              },
              {
                name: "Jenis Kelamin",
                selector: "jenis_kelamin",
                sortable: true,
                cell: (row) => (
                  <div data-tag="allowRowEvents">
                    <div style={{ fontWeight: "bold" }}>
                      {row.jenis_kelamin !== null
                        ? row.jenis_kelamin == 0
                          ? "Perempuan"
                          : "Laki - laki"
                        : "-"}
                    </div>
                  </div>
                ),
              },
              {
                name: "Tempat Lahir",
                selector: "tempat_lahir",
                sortable: true,
              },
              {
                name: "Tanggal Lahir",
                selector: "tanggal_lahir",
                sortable: true,
                width: "160px",
                cell: (row) => tglId(row.tanggal_lahir),
              },
              {
                name: "Foto Profil",
                selector: "foto_profil",
                sortable: true,
                cell: (row) => (
                  <div className="py-2" data-tag="allowRowEvents">
                    {isEmpty(row.foto_profil) ? (
                      <p>-</p>
                    ) : (
                      <div>
                        <Media
                          body
                          alt=""
                          style={{
                            height: "60px",
                            width: "60px",
                            backgroundPosition: "center",
                            objectFit: "cover",
                            borderRadius: "100px",
                          }}
                          src={`${urlSosmed}SI/photo/${row.foto_profil}`}
                          data-intro="This is Profile image"
                        />
                      </div>
                    )}
                  </div>
                ),
              },
              {
                name: "Kelas",
                selector: "kelas",
                sortable: true,
                width: "60px",
              },
              {
                name: "Rombel",
                selector: "nama_rombel",
                sortable: true,
                width: "200px",
              },
            ]}
          />
        </CardBody>
      </Card>
    );
  }
}

export default DataStudent;
