import { isEmpty } from 'lodash'; 
import man from "../../src/assets/images/user/10.jpg"
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

export function fotoProfil(data) {
  if (!isEmpty(data)) {
    var foto = null;
    if (data.group_code == "NI") 
      foto = data.user_institusi.foto_profil;
    if (data.group_code == "GU") 
      foto = data.user_guru.foto_profil;
    if (data.group_code == "SI") 
      foto = data.user_siswa.foto_profil;
    if (data.group_code == "OW") 
      foto = data.user_orangtua.foto_profil;
    
    if (!isEmpty(foto)) {
      return `${urlSosmed + data.group_code}/photo/${foto}`;
    } else {
      return man;
    }
  } else {
    return man
  }
}

export function nama(data) {
  if (!isEmpty(data)) {
    var nama = null;
    if (data.group_code == "NI") 
      nama = data.user_institusi.nama;
    if (data.group_code == "GU") 
      nama = data.user_guru.nama;
    if (data.group_code == "SI") 
      nama = data.user_siswa.nama;
    if (data.group_code == "OW") 
      nama = data.user_orangtua.nama;
    
    return nama;
  } else {
    return null
  }
}

export function fotoSampul(time) {
  if (!isEmpty(time)) {
    var d = time.substring(0, 5);
    return (d);
  } else {
    return null;
  }
}

