import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import {Container, Button} from "reactstrap";

class NotLogin extends React.Component {

  render() {
    return (
      <Fragment>
        <div className="page-wrapper bg-white">
          {/* <!-- Maintenance start--> */}
          <div className="error-wrapper">
            <Container>
              <div className="text-center">
                <h1 className="headline font-primary">Anda belum login</h1>
              </div>
              <h4 className="sub-content">
                Silakan login untuk melanjutkan ke aplikasi
              </h4>
              <div>
                <Link to="/auth">
                  <Button color="primary-gradien" size="lg" className="text-light">
                    LOGIN
                  </Button>
                </Link>
              </div>
            </Container>
          </div>
          {/* <!-- Maintenance end--> */}
        </div>
      </Fragment>
    )
  }
}

export default NotLogin;
