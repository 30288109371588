import React, { Fragment } from 'react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  Row, 
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  ListGroupItem,
  ListGroup,
  Media
} from 'reactstrap';
import Skeleton from 'react-loading-skeleton';

const apiUm = process.env.REACT_APP_UM_API;
const urlSosmed = process.env.REACT_APP_SM_STATIC_URI;

class ProfileNi extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: '1',
      tahunSekarang: new Date().getFullYear(),
      dataProfile : [],
      dataExtra : [],
      dataAddress : [],
    }
    this.toggle = this.toggle.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  componentDidMount() {
    this.fetchProfileAddress();
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      }, function() {
        switch (tab) {
          case '1' :
            break;
          case '2' :
            break;
          default:
            break;
        }
      })
    }
  }

  fetchProfileAddress() {
    var extra = [];
    var address = [];
    if (this.props.groupCode === "NI") {
      extra = [
        { index: 'npsn', text: 'NPSN' },
        { index: 'm_struktur_jenjang_id', text: 'Jenjang' },
        { index: 'jenis_institusi', text: 'Jenis Institusi' },
        { index: 'website', text: 'Website' },
        { index: 'email', text: 'Email' },
        { index: 'no_telp', text: 'No Telepon' },
      ];
    } else if (this.props.groupCode === "GU") {
      extra = [
        { index: 'jenis_kelamin', text: 'Jenis Kelamin' },
        { index: 'tempat_lahir', text: 'Tempat Lahir' },
        { index: 'tanggal_lahir', text: 'Tanggal Lahir' },
      ];
    } else if (this.props.groupCode === "SI") {
      extra = [
        { index: 'jenis_kelamin', text: 'Jenis Kelamin' },
        { index: 'tempat_lahir', text: 'Tempat Lahir' },
        { index: 'tanggal_lahir', text: 'Tanggal Lahir' },
        { index: 'nisn', text: 'NISN' },
        { index: 'alumni', text: 'Alumni' },
      ];
    } else if (this.props.groupCode === "OW") {
      extra = [
        { index: 'jenis_kelamin', text: 'Jenis Kelamin' },
        { index: 'tempat_lahir', text: 'Tempat Lahir' },
        { index: 'tanggal_lahir', text: 'Tanggal Lahir' },
        { index: 'alumni', text: 'Alumni' },
        { index: 'pekerjaan', text: 'Pekerjaan' },
      ];
    }
    
    address = [
      { index: 'negara_nama', text: 'Negara' },
      { index: 'provinsi_nama', text: 'Provinsi' },
      { index: 'kabupaten_nama', text: 'Kabupaten / Kota' },
      { index: 'kecamatan_nama', text: 'Kecamatan' },
      { index: 'desa_nama', text: 'Desa / Kelurahan' },
      { index: 'kode_pos', text: 'Kode Pos' },
      { index: 'rt', text: 'RT' },
      { index: 'rw', text: 'RW' },
    ];

    this.setState({ 
      dataExtra : extra,
      dataAddress : address
    }, function() {
      this.getProfile();
    })
  }

  getProfile() {
    let self = this;
    let requestData = '';

    requestData += '?group_code='+self.props.groupCode;
    if(self.props.groupCode === "NI") {
      requestData += '&npsn='+self.props.npsn;
    } else {
      requestData += '&uuid='+self.props.uuid;
    }
    axios.get(apiUm + 'ja/profile-user' + requestData)
      .then(function (res) {
        self.setState({ dataProfile : res.data });
      })
  }

  cek(data) {
    if (!isEmpty(data)) {
      return(data);
    } else {
      return("-")
    }
  }

  showProfile() {
    let data = this.state.dataExtra;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataProfile = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataProfile.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {data.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center text-dark">
                {value.text}
                <span className="badge badge-primary counter digits">
                  {this.state.dataProfile[value.index] !== undefined ? this.state.dataProfile[value.index] : '-' }
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataProfile}</Row>) 
  }

  showAddress() {
    let data = this.state.dataAddress;
    let totalData = data.length;
    let pembagi = 2;
    let hasilBagi = Math.ceil(totalData / pembagi);
    let dataProfile = [];

    for(var i = 0; i < pembagi; i++) {
      let start = i * hasilBagi;
      let finish = (i + 1) * hasilBagi;

      dataProfile.push(
        <Col sm="12" md="6" lg="6" xl="6">
          <ListGroup className="profile-dashboard">
            {data.slice(start, finish).map((value, key) => (
              <ListGroupItem key={key} className="d-flex justify-content-between align-items-center text-dark">
                {value.text}
                <span className="badge badge-primary counter digits">
                  {this.state.dataProfile[value.index] !== undefined ? this.state.dataProfile[value.index] : '-' }
                </span>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Col>
      )
    }

    return(<Row>{dataProfile}</Row>) 
  }

  render() {
    return (
      <>
        <Col sm="12">
          <div className="w-100" style={
            this.state.dataProfile.group_code === "NI" 
              ?
                !isEmpty(this.state.dataProfile.cover) 
                  ? { 
                      backgroundImage: `url(${urlSosmed + this.props.groupCode}/cover/${this.state.dataProfile.cover})`,
                      height: "180px",
                      backgroundPosition: 'center',
                      objectFit: 'cover'
                    } 
                  : { 
                      backgroundColor: `#eeeeee`,
                      height: "180px",
                    } 
              :
                !isEmpty(this.state.dataProfile.foto_sampul) 
                ? { 
                    backgroundImage: `url(${urlSosmed + this.props.groupCode}/cover/${this.state.dataProfile.foto_sampul})`,
                    height: "180px",
                    backgroundPosition: 'center',
                    objectFit: 'cover'
                  } 
                : { 
                    backgroundColor: `#eeeeee`,
                    height: "180px",
                  } 
          }>
        
          </div>
        </Col>
        <Row>
          <Col sm="12" xl="3" className="text-left" style={{ marginTop: "-50px" }}>
            <div className="avatar">
              <Media
                body
                className="img-100 rounded-circle ml-5"
                style={{
                  height: "100px",
                  width: "100px",
                  backgroundPosition: 'center',
                  objectFit: 'cover',
                  borderWidth : '3px',
                  borderStyle : 'solid',
                  borderColor: '#ffffff'
                }}
                src={
                  this.state.dataProfile.group_code === "NI" ?
                    this.state.dataProfile.logo 
                      ? `${urlSosmed + this.props.groupCode}/photo/${this.state.dataProfile.logo}`
                      : require("../../../assets/images/user/10.jpg")
                  :
                    this.state.dataProfile.foto_profil 
                      ? `${urlSosmed + this.props.groupCode}/photo/${this.state.dataProfile.foto_profil}`
                      : require("../../../assets/images/user/10.jpg")
                }
                alt="#"
              />
            </div>
          </Col>
          <Col sm="12" xl="6" className="text-left profile-mini-name">
            <h5 className="p-2 b-r-10">{this.props.groupCode === "NI" ? this.state.dataProfile.nama_institusi : this.state.dataProfile.nama}</h5>
            <p className="p-2">{this.props.groupCode === "NI" ? this.state.dataProfile.tagline : this.state.dataProfile.motto}</p>
          </Col>
        </Row>
        <div className="text-center p-3 col-12 justify-center">
          <Nav className="nav-pills nav-primary">
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => this.toggle("1")}
              >
                <i className="icofont icofont-bag-alt"></i> Profile Institusi
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "2" ? "active" : ""}
                onClick={() => this.toggle("2")}
              >
                <i className="icofont icofont-location-pin"></i> Alamat Institusi
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <ListGroup className="profile-dashboard">
              {!isEmpty(this.state.dataExtra) ? this.showProfile() : null}
            </ListGroup>
          </TabPane>
          <TabPane tabId="2">
            <ListGroup className="profile-dashboard">
              {!isEmpty(this.state.dataExtra) ? this.showAddress() : null}
            </ListGroup>
          </TabPane>
        </TabContent>
      </>
    )
  }
}

export default ProfileNi;