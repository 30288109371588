import React from "react";
import LoaderPeraturan from "../../common/loader/LoaderPeraturan";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { isEmpty } from "lodash";
import axios from "axios";
import FormTataTertib from "./form/FormTataTertib";
import DataPoinTataTertib from "./DataPoinTataTertib";
import SMDataTable from "../../common/SMDataTable/SMDataTable";
import Swal from "sweetalert2";

const apiJa = process.env.REACT_APP_API;

export default class ListPoinTatatertib extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
      poinPelanggaran: [],
      poinPrestasi: [],
      loadingPoinPelanggaran: true,
      loadingPoinPrestasi: true,
    };
  }

  componentDidMount() {}

  UNSAFE_componentWillReceiveProps(nextProps) {}

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    switch (tab) {
      case "1":
        // this.getTataTertib();
        break;
      case "2":
        // this.getPoinPelanggaran();
        break;
      case "3":
        // this.getPoinPrestasi();
        break;
      default:
        // this.getTataTertib();
        break;
    }
  }

  render() {
    return (
      <Col sm="12">
        <Card>
          <CardBody className="px-2 py-4">
            <Nav className="border-tab-primary" tabs>
              <NavItem>
                <NavLink
                  href="#javascript"
                  className={this.state.activeTab === "1" ? "active" : ""}
                  onClick={() => this.toggle("1")}
                >
                  <i className="icofont icofont-address-book" /> Poin
                  Pelanggaran
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#javascript"
                  className={this.state.activeTab === "2" ? "active" : ""}
                  onClick={() => this.toggle("2")}
                >
                  <i className="icofont icofont-law-book" /> Poin Reward
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {this.state.activeTab === "1" ? (
                  <DataPoinTataTertib
                    dataUser={this.props.dataUser}
                    type={"Pelanggaran"}
                  />
                ) : null}
              </TabPane>
              <TabPane tabId="2">
                {this.state.activeTab === "2" ? (
                  <DataPoinTataTertib
                    dataUser={this.props.dataUser}
                    type={"Reward"}
                  />
                ) : null}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
