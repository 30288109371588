// starter kit
import Samplepage from "../component/starterkits/samplepage";

// Referensi
import ListOther from "../component/referensi/ListOther";
import ListRoomBuilding from "../component/referensi/ListRoomBuilding";
import ListStudent from "../component/referensi/ListStudent";
import ListTeacher from "../component/referensi/ListTeacher";
import TataTertib from "../component/referensi/TataTertib";

// Jadwal Pelajaran
import JadwalSiswa from "../component/jadwal/JadwalSiswa";
import JadwalGuru from "../component/jadwal/JadwalGuru";
import JadwalMengajar from "../component/jadwal/JadwalMengajar";
import PoinTatatertib from "../component/referensi/pointatatertib";

// Jurnal AKademk
import RencanaTopikPembelajaran from "../component/jurnalAkademik/RencanaTopikPembelajaran";
import RealisasiTopikPembelajaran from "../component/jurnalAkademik/RealisasiTopikPembelajaran";
import Perizinan from "../component/jurnalAkademik/Perizinan";

// Ujian dan Remidial
import RencanaUjianBersama from "../component/ujianDanRemidial/RencanaUjianBersama";
import HasilUjianBersama from "../component/ujianDanRemidial/HasilUjianBersama";
import RencanaRemidialBersama from "../component/ujianDanRemidial/RencanaRemidialBersama";
import HasilRemidialBersama from "../component/ujianDanRemidial/HasilRemidialBersama";
import RencanaUlanganHarian from "../component/ujianDanRemidial/RencanaUlanganHarian";
import HasilUlanganHarian from "../component/ujianDanRemidial/HasilUlanganHarian";

// point siswa
import RekapPointSiswa from "../component/pointSiswa/RekapPointSiswa";

// Bimbingan Konseling
import KonsultasiSiswa from "../component/bimbinganKonseling/KonsultasiSiswa";
import KonsultasiOrangTua from "../component/bimbinganKonseling/KonsultasiOrangTua";
import CatatanSiswa from "../component/bimbinganKonseling/CatatanSiswa";

import Maintenance from "../component/common/page/Maintenance";
import ComingSoon from "../component/common/page/ComingSoon";

import Dashboard from "../component/dashboard/Dashboard";
import NotLogin from "../component/common/page/NotLogin";
import LihatPesertaUjian from "../component/ujianDanRemidial/LihatPesertaUjian";
import LihatPesertaRemidial from "../component/ujianDanRemidial/LihatPesertaRemidial";
import LihatJadwalUjian from "../component/ujianDanRemidial/LihatJadwalUjian";
import DaftarPelanggaranSiswa from "../component/pointSiswa/DaftarPelanggaranSiswa";
import FormPembayaran from "../component/formPembayaran/formPembayaran";

import PrivacyPolicy from "../component/privacyPolicy/Privacy";

export const routes = [
  // Referensi
  { path: "/referensi/dashboard", Component: Dashboard },
  { path: "/referensi/daftar-siswa", Component: ListStudent },
  { path: "/referensi/daftar-guru", Component: ListTeacher },
  { path: "/referensi/daftar-lainnya", Component: ListOther },
  { path: "/referensi/daftar-ruangan&gedung", Component: ListRoomBuilding },
  { path: "/referensi/tata-tertib", Component: TataTertib },
  { path: "/referensi/poin-tata-tertib", Component: PoinTatatertib },

  // Jadwal
  { path: "/jadwal/jadwal-siswa", Component: JadwalSiswa },
  { path: "/jadwal/jadwal-guru", Component: JadwalGuru },
  { path: "/jadwal/jadwal-mengajar", Component: JadwalMengajar },

  // Jurnal Akademik
  {
    path: "/jurnal-akademik/rencana-topik-pembelajaran",
    Component: RencanaTopikPembelajaran,
  },
  {
    path: "/jurnal-akademik/realisasi-topik-pembelajaran",
    Component: RealisasiTopikPembelajaran,
  },
  { path: "/jurnal-akademik/perizinan", Component: Samplepage },
  { path: "/jurnal-akademik/sample-page", Component: Samplepage },

  // Ujian dan Remidial
  // { path:"/ujian-&-remidial/rencana-ujian-bersama", Component: Samplepage },
  // sub
  //     { path:"/ujian-&-remidial/rencana-ujian-bersama/lihat-peserta", Component: Samplepage, Handler: Samplepage },
  //     { path:"/ujian-&-remidial/rencana-ujian-bersama/lihat-jadwal", Component: Samplepage },
  // { path:"/ujian-&-remidial/hasil-ujian-bersama", Component: Samplepage },
  // { path:"/ujian-&-remidial/rencana-remidial-bersama", Component: Samplepage },
  // sub
  //     { path:"/ujian-&-remidial/rencana-remidial-bersama/lihat-peserta", Component: Samplepage, Handler: Samplepage },
  // { path:"/ujian-&-remidial/hasil-remidial-bersama", Component: Samplepage },
  // { path:"/ujian-&-remidial/rencana-ulangan-harian", Component: Samplepage },
  // { path:"/ujian-&-remidial/hasil-ulangan-harian", Component: Samplepage },

  { path: "/starter-kit/sample-page", Component: Samplepage },
  { path: "/maintenance", Component: Maintenance },
  { path: "/coming-soon", Component: ComingSoon },
  { path: "/not-login", Component: NotLogin },

  { path: "/point-siswa/rekap-point", Component: RekapPointSiswa },
  { path: "/pembayaran/form-pembayaran", Component: FormPembayaran },
  {
    path: "/point-siswa/daftar-pelanggaran-siswa",
    Component: DaftarPelanggaranSiswa,
  },
  { path: "/privasi", Component: PrivacyPolicy },
  // { path:"/rpp/program-semester", Component: Samplepage },
  // { path:"/rpp/program-pembelajaran", Component: Samplepage },
  // { path:"/rpp/program-remedial", Component: Samplepage },
  // { path:"/rpp/program-pengayaan", Component: Samplepage },
  // { path:"/bimbingan-konseling/konsul-siswa", Component: Samplepage },
  // { path:"/bimbingan-konseling/konsul-ortu", Component: Samplepage },
  // { path:"/bimbingan-konseling/catatan-siswa", Component: Samplepage },
];
