import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import SMDataTable from "../common/SMDataTable/SMDataTable";
import { isEmpty } from "lodash";
import Filter from "../common/filter/Filter";
import ModalPreview from "./Components/ModalPreview";

class HasilRemidialBersama extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jenjangSelect: "4",
      // For Filter Selected
      tahunAjaranSelect: "2020 / 2021",
      kelasSelect: null,
      rombelSelect: null,
      semesterSelect: null,
      mapelSelect: null,
      // For Filter Loading
      loadingTahunAjaran: true,
      loadingKelas: true,
      loadingRombel: true,
      loadingSemester: true,
      loadingMapel: true,

      modalPreview: false,
      idPreview: null,
    };

    // Filters Function
    this.filterTahunAjaran = this.filterTahunAjaran.bind(this);
    this.filterKelas = this.filterKelas.bind(this);
    this.filterRombel = this.filterRombel.bind(this);
    this.filterSemester = this.filterSemester.bind(this);
    this.filterMapel = this.filterMapel.bind(this);
    this.loadingFilter = this.loadingFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);

    this.showPreview = this.showPreview.bind(this)
  }

  componentDidMount() {
    this.setState({ loadingTahunAjaran: false });
  }

  filterTahunAjaran(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.tahunAjaranSelect) {
        this.setState(
          {
            tahunAjaranSelect: e,
            kelasSelect: null,
            rombelSelect: null,
            semesterSelect: null,
            mapelSelect: null,
          },
          function () {
            this.loadingFilter([false, false, true, true, true]);
          }
        );
      }
    } else {
      this.setState(
        {
          tahunAjaranSelect: null,
          kelasSelect: null,
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
        },
        function () {
          this.loadingFilter([false, true, true, true, true]);
        }
      );
    }
  }

  filterKelas(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.kelasSelect) {
        this.setState(
          {
            kelasSelect: e,
            rombelSelect: null,
            semesterSelect: null,
            mapelSelect: null,
          },
          function () {
            this.loadingFilter([false, false, false, false, true, true]);
          }
        );
      }
    } else {
      this.setState(
        {
          kelasSelect: null,
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
        },
        function () {
          this.loadingFilter([false, false, true, true, true]);
        }
      );
    }
  }

  filterRombel(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.rombelSelect) {
        this.setState(
          {
            rombelSelect: e,
            semesterSelect: null,
            mapelSelect: null,
          },
          function () {
            this.loadingFilter([false, false, false, false, false]);
          }
        );
      }
    } else {
      this.setState(
        {
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
          loadingSemester: true,
        },
        function () {
          this.loadingFilter([false, false, false, false, true]);
        }
      );
    }
  }

  filterSemester(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.semesterSelect) {
        this.setState({
          semesterSelect: e,
          mapelSelect: null,
        });
      }
    } else {
      this.setState({
        semesterSelect: null,
        mapelSelect: null,
      });
    }
  }

  filterMapel(e) {
    if (!isEmpty(e)) {
      if (e !== this.state.mapelSelect) {
        this.setState({
          mapelSelect: e,
        });
      }
    } else {
      this.setState({
        mapelSelect: null,
      });
    }
  }

  loadingFilter(status) {
    this.setState({
      loadingTahunAjaran: status[0],
      loadingKelas: status[1],
      loadingRombel: status[2],
      loadingSemester: status[3],
      loadingMapel: status[4],
    });
  }

  setFilter(e) {
      e.preventDefault();
  }

  setResetFilter(e) {
      e.preventDefault();
      this.loadingFilter([true, true, true, true, true])
    this.setState(
      {
        tahunAjaranSelect: null,
          kelasSelect: null,
          rombelSelect: null,
          semesterSelect: null,
          mapelSelect: null,
      },
      function () {
        this.setState({ loadingTahunAjaran: false });
      }
    );
  }

  showPreview(id){
    this.setState({
      modalPreview: !this.state.modalPreview,
      idPreview: id
    })
  }

  render() {
    return (
      <Fragment>
        <Breadcrumb
          parent="Ujian dan Remidial"
          title="Hasil Remidial Bersama"
        />
        <ModalPreview
          show={this.state.modalPreview}
          onHide={() => this.showPreview(1)}
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5 className="mb-2">Filter Table Table Hasil Remidial Bersama</h5>
                  <span>
                    lorem ipsum dolor sit amet, consectetur adipisicing elit
                  </span>
                  <Row>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="tahunAjaran"
                        loading={this.state.loadingTahunAjaran}
                        selectData={this.filterTahunAjaran}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="kelas"
                        loading={this.state.loadingKelas}
                        selectData={this.filterKelas}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="rombel"
                        loading={this.state.loadingRombel}
                        selectData={this.filterRombel}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="semester"
                        loading={this.state.loadingSemster}
                        selectData={this.filterSemester}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                    <Col sm="6" className="pt-2 pb-2">
                      <Filter
                        type="mapel"
                        loading={this.state.loadingMapel}
                        selectData={this.filterMapel}
                        jenjang={this.state.jenjangSelect}
                      />
                    </Col>
                  </Row>
                  <Row>
                      <Col sm={6}>
                        <span className="text-dark mr-4">Select filter secara urut</span>
                      </Col>
                      <Col sm={6} className="text-right">
                        <button onClick={this.setResetFilter} className="btn btn-pill btn-warning mr-2"><i className="fa fa-refresh mr-2"></i> Reset</button>
                          {this.state.loadingSemester === false ? (
                              <button onClick={this.setFilter} className="btn btn-pill btn-primary"><i className="fa fa-filter mr-2"></i> Filter</button>
                          ) : ("")}
                      </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <SMDataTable
                    title="Tabel Hasil Remidial Bersama"
                    number={true}
                    data={[
                      {tahun_ajaran: "2021",},
                      {tahun_ajaran: "2021",},
                      {tahun_ajaran: "2021",},
                      {tahun_ajaran: "2021",},
                    ]}
                    columns={[
                      {
                        name: "Tahun Ajaran",
                        selector: "tahun_ajaran",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Kelas",
                        selector: "kelas",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Rombel",
                        selector: "rombel",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Semester",
                        selector: "semester",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Indeks Mapel",
                        selector: "indeks_mapel",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Mata Pelajaran",
                        selector: "mata_pelajaran",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Nama Ujian",
                        selector: "nama_ujian",
                        sortable: true,
                        center: true,
                      },
                      {
                        name: "Nilai Peserta",
                        selector: "nilai_peserta",
                        sortable: true,
                        center: true,
                        cell: row =>
                          <div data-tag="allowRowEvents">
                            <div style={{ fontWeight: "bold" }}>
                            <button 
                              style={{
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                backgroundColor: "rgb(16, 68, 156)",
                                color: "white",
                                borderRadius: "100px"
                              }}
                              onClick={() => this.showPreview(1)}
                            >Preview </button>
                            </div>
                          </div>
                      },
                    ]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default HasilRemidialBersama;
